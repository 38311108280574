import React, { useEffect, useState } from "react";
import { SIDEBAR_DATA, SIDEBAR_DATA_END, SIDEBAR_DATA_SUB } from "../../utils/constants";
import "./sidebar.scss";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
const Sidebar = () => {
  const [selectedId, setSelectedId] = useState(() => {
    const savedId = localStorage.getItem("selectedSidebarId");
    return savedId ? parseInt(savedId, 10) : 1;
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [roleData, setRoleData] = useState(1);
  // Check if user exists and has a role
  // const role = user ? user.role : "2";
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      const user = JSON.parse(localStorage.getItem("access_token"));
      setRoleData(user.role);
    }
  }, [roleData]);
  // console.log("LOKK",location)
  const handleSidebarClick = (itm) => {
    setSelectedId(itm.id);
    // console.log(">>>>>>>>", localStorage.getItem("group"), itm);
    // if (itm.path === "/sub/report") {
    //   navigate({
    //     pathname: "/sub/report",
    //     search: `?${createSearchParams({ id: localStorage.getItem("group") })}`,
    //   });
    // }

    // localStorage.setItem('selectedSidebarId', itm.id);
    navigate(itm.path);
  };
  // useEffect(() => {
  //    // Navigate to the saved path on reload
  //    const savedPath = SIDEBAR_DATA.find(item => item.id === selectedId)?.path;
  //    if (savedPath) navigate(savedPath);
  // }, [selectedId, navigate]);
  return (
    <div className='sidebarContainer'>
      <div className='sidebarHead'>{roleData === 1 ? "Hi Super Admin" : roleData === 2 ? `Hi ${localStorage.getItem("name")}` : `Hi ${localStorage.getItem("name")}`}</div>
      {roleData == 1
        ? SIDEBAR_DATA?.map((itm, ind) => {
            return (
              <div
                key={ind}
                className={`sidebarContent ${location.pathname.includes(itm.path) ? "active" : ""}`}
                onClick={() => {
                  handleSidebarClick(itm);
                }}>
                <div className='sidebarList'>
                  <div>
                    <itm.icon />
                  </div>
                  <div className='sidebarTxt'>{itm.text}</div>
                </div>
                <div className={`sidebarRight ${location.pathname.includes(itm.path) ? "active" : ""}`}></div>
              </div>
            );
          })
        : roleData == 2
        ? SIDEBAR_DATA_SUB?.map((itm, ind) => {
            return (
              <div
                key={ind}
                className={`sidebarContent ${location.pathname.includes(itm.path) ? "active" : ""}`}
                onClick={() => {
                  handleSidebarClick(itm);
                }}>
                <div className='sidebarList'>
                  <div>
                    <itm.icon />
                  </div>
                  <div className='sidebarTxt'>{itm.text}</div>
                </div>
                <div className={`sidebarRight ${location.pathname.includes(itm.path) ? "active" : ""}`}></div>
              </div>
            );
          })
        : SIDEBAR_DATA_END?.map((itm, ind) => {
            return (
              <div
                key={ind}
                className={`sidebarContent ${location.pathname.includes(itm.path) ? "active" : ""}`}
                onClick={() => {
                  handleSidebarClick(itm);
                }}>
                <div className='sidebarList'>
                  <div>
                    <itm.icon />
                  </div>
                  <div className='sidebarTxt'>{itm.text}</div>
                </div>
                <div className={`sidebarRight ${location.pathname.includes(itm.path) ? "active" : ""}`}></div>
              </div>
            );
          })}
    </div>
  );
};
export default Sidebar;
