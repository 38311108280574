import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { EmailIcon, KeyboardArrowDownIcon, NotificationsIcon, PersonIcon, LogoutIcon } from "../../utils/icons";
import CommonModal from "../../Components/Modals/index";
import "./profile.scss";
import { useNavigate } from "react-router-dom";

const HeaderProfile = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const user = JSON.parse(localStorage.getItem("access_token"));

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dropdownItems = [
    { name: "My Profile",
      icon: <PersonIcon />, 
      path: (() => {
    switch (user.role) {
      case 1:
        return "/super/profile";
      case 2:
        return "/sub/profile";
      default:
        return "/end/profile";
    }
  })()  },
    { name: "Log Out", icon: <LogoutIcon />, path: "/login" },
  ];

  const handleNav = (path) => {
    if (path === "/login") {
      setModal(!modal);
      // localStorage.removeItem('access_token');
    } else {
      navigate(path);
    }
    setAnchorEl(null);
  };

  const logoutConfirmation = ()=>{
          localStorage.removeItem('access_token');
          localStorage.removeItem('logo');
          localStorage.removeItem('group');
          localStorage.removeItem('id');
          localStorage.removeItem('name');
          localStorage.removeItem('userid');
          localStorage.removeItem("groupName");
          navigate("/login")
  }
  return (
    <div className='headerContainer1'>
      <div className='rightProfile'>
        <div className='profile_dropdown'>
          <img src={user.role === 1 ? '/assets/images/ecg_logo.svg' : `${localStorage.getItem('logo')}`} onClick={handleClick} alt='profile' style={{ cursor: "pointer",height:"28px",width:"28px",borderRadius:"50%" }}/>
          <div className='textSection' onClick={handleClick} style={{ cursor: "pointer",marginTop:"0px" }}>
            {user.role === 1 ? "Super Admin":`${localStorage.getItem('name')}`}
          </div>
          <div className='padLft'>
            <KeyboardArrowDownIcon onClick={handleClick} style={{ color: "#4D4D4D", cursor: "pointer" }} />
          </div>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            autoFocus={false}
            sx={{
              "& .MuiPaper-root": {
                minWidth: "11.042vw",
                borderRadius: "0.417vw",
                border: "1px solid #CCCCCC",
                marginTop: "15px",
              },
              "& .MuiList-root": {
                padding: "0px",
              },
            }}>
            {dropdownItems.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleNav(item.path);
                }}
                style={{
                  border: "1px solid #E6E6E6",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
                sx={{
                  padding: "16px",
                }}>
                {item.icon}
                {item.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
      {modal && (
        <CommonModal
          setIsOpenPopUp={setModal}
          open={modal}
          // isLoading={isLoading}
          handleDelete={logoutConfirmation}
          item={{
            title: "Logout",
            description: "Are you sure you want to logout?",
            icon: false,
          }}
        />
      )}
    </div>
  );
};

export default HeaderProfile;
