export const tableHeaders = [
  { label: "" },
  { label: "2023 Total Procurement" },
  { label: "MBE" },
  { label: "WBE" },
  { label: "DVBE" },
  { label: "LGBTBE" },
  { label: "PDBE" },
  { label: "Other 8(a)*" },
  { label: "Total Supplier Diversity" },

  // createData("", "2023 Total Procurement", "MBE", "WBE", "DVBE", "LGBTBE", "PDBE", "Other 8(a)*", "Total Supplier Diversity")
];

export const rows = [
  {
    emptyCell: "AT&T",
    totalProcurement: "",
    MBE: "",
    WBE: "",
    DVBE: "",
    LGBTBE: "",
    PDBE: "",
    other: "",
    totalSupplierDiversity: "",
  },
  {
    emptyCell: "Direct",
    totalProcurement: "",
    MBE: "$683,160,089",
    WBE: "$379,271,964",
    DVBE: "$47,744,275",
    LGBTBE: "$270,083",
    PDBE: "$0",
    other: "",
    totalSupplierDiversity: "$1,110,446,411",
  },
  {
    emptyCell: "",
    totalProcurement: "",
    MBE: "9.11%",
    WBE: "7.41%",
    DVBE: "0.66%",
    LGBTBE: "0.01%",
    PDBE: "0.01%",
    other: "0.00%",
    totalSupplierDiversity: "14.81%",
  },
  {
    emptyCell: "Subcontracting",
    totalProcurement: "",
    MBE: "$171,355,134",
    WBE: "$176,405,673",
    DVBE: "$1,622,876",
    LGBTBE: "$544,859",
    PDBE: "$934,263",
    other: "$0",
    totalSupplierDiversity: "$350,862,805",
  },
  {
    emptyCell: "",
    totalProcurement: "",
    MBE: "2.28%",
    WBE: "2.35%",
    DVBE: "0.02%",
    LGBTBE: "0.007%",
    PDBE: "0.012%",
    other: "0.00%",
    totalSupplierDiversity: "4.68%",
  },
  {
    emptyCell: "Combined",
    totalProcurement: "$7,500,087,461",
    MBE: "$854,515,223",
    WBE: "$555,677,637",
    DVBE: "$49,367,151",
    LGBTBE: "$814,942",
    PDBE: "$934,263",
    other: "$0",
    totalSupplierDiversity: "$1,461,309,216",
  },
  {
    emptyCell: "",
    totalProcurement: "100.00%",
    MBE: "11.39%",
    WBE: "7.41%",
    DVBE: "0.66%",
    LGBTBE: "0.01%",
    PDBE: "0.01%",
    other: "0.00%",
    totalSupplierDiversity: "19.48%",
  },
];
