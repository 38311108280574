import React, { useState } from 'react';
import "./dropdown.scss"
import InputError from '../InputError';

const Dropdown = ({ label, options, value, onChange, placeholder,isError=false,errorMessage="",isInput = false,isDisabled=false,name="" }) => {
  const [isOther, setIsOther] = useState(value === "other"); // Tracks if "other" is selected
  const [textValue, setTextValue] = useState("");

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "other") {
      setIsOther(true);
      onChange(""); // Reset the value for "other"
    } else {
      setIsOther(false);
      onChange(selectedValue);
    }
  };

  const handleTextChange = (e) => {
    const newValue = e.target.value;
    setTextValue(newValue);
    onChange(newValue); // Return the value of the textbox
  };
  return (
    <div className={isInput ? "input_group_input": "input_group"}>
      {label && <label>{label}</label>}
      {!isOther ? (
        <select
          className={`select_value ${isError ? "error" : ""}`}
          value={value}
          name={name}
          disabled={isDisabled}
          onChange={handleChange}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {options?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <input
          type="text"
          className={`input-styling ${isError ? "error" : ""}`}
          value={textValue}
          placeholder="Enter your value"
          onChange={handleTextChange}
        />
      )}
      {/* <select 
        className={`select_value ${isError? "error" : ""}`} 
        value={value} 
        name={name}
        disabled = {isDisabled}
        onChange={handleChange}
        // onChange={(e) => onChange(e.target.value)}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options?.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select> */}
     {isError? <InputError text={errorMessage}/> : <div></div>}
    </div>
  );
};

export default Dropdown;
