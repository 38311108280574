import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import UnprotectedRoute from "../Router/UnprotectedRoute";
import Login from "../screens/Authentication/Login";
import EmailSent from "../screens/Authentication/EmailSent";
import ResetPassword from "../screens/Authentication/ResetPassword";
// import AddUser from "./../screens/UserManagement/AddUser/AddUser";
import Successful from "../screens/Authentication/Successful";
import UpdatePassword from "../screens/Authentication/UpdatePassword";
import ProtectedRoute from "../Router/ProtectedRoute";
// import Dashboard from "../screens/Dashboard";
import UserManagement from "../screens/UserManagement";
// import InputData from "../screens/SuperAdmin/InputData";
// import Report from "../screens/SuperAdmin/Report";
import { ToastProvider } from "../screens/ToastProvider";
// import GroupManagement from "../screens/GroupManagement";
// import GroupTable from "../screens/GroupManagement/GroupTable";
// import AddGroup from "../screens/GroupManagement/AddGroup/index";
// import Profile from "../screens/UserProfile/index";
import AccessDenied from "../screens/Authentication/AccessDenied/index";
import Dashboard from "../screens/SuperAdmin/Dashboard";
import InputData from "../screens/SuperAdmin/InputData";
import Report from "../screens/SuperAdmin/Report";
import GroupManagement from "../screens/SuperAdmin/GroupManagement";
import GroupTable from "../screens/SuperAdmin/GroupManagement/GroupTable";
import AddGroup from "../screens/SuperAdmin/GroupManagement/AddGroup/index";
import PageNotFound from "../screens/NoPageFound";
import AddUser from "../screens/SuperAdmin/GroupManagement/AddUser/AddUser";
import DashboardSub from "../screens/SubAdmin/Dashboard";
import InputSub from "../screens/SubAdmin/InputDataManagement";
import CompanyManagement from "../screens/SubAdmin/CompanyManagement";
import ReportSub from "../screens/SubAdmin/ReportAccess";
import DashboardEnd from "../screens/EndUser/Dashboard";
import DataManagement from "../screens/EndUser/DataManagement";
import ChangePassword from "../screens/Authentication/ChangePassword";
import AddData from "../screens/EndUser/DataManagement/AddData";
import ReportTable from "../screens/SuperAdmin/Report/ReportTable/GroupTable";
import SubAdminReport from "../screens/SubAdmin/ReportAccess";
import SubAdminReportTable from "../screens/SubAdmin/ReportAccess/ReportTable/GroupTable";
import SuperProfile from "../screens/SuperAdmin/UserProfile";
import SubProfile from "../screens/SubAdmin/UserProfile";
import EndProfile from "../screens/EndUser/UserProfile";
import AddCompany from "../screens/SubAdmin/CompanyManagement/AddCompany/AddCompany";
// import Report1 from "../screens/SuperAdmin/Report/ReportTable/Report1";

function Container() {
  const [roleData, setRoleData] = useState(1);

  // Check if user exists and has a role
  // const role = user ? user.role : "2";

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      const user = JSON.parse(localStorage.getItem("access_token"));
      setRoleData(user.role);
    }
  }, [roleData]);

  console.log("ROLE", roleData);

  const isAuthenticated = () => {
    if (localStorage.getItem("access_token")) {
      return true;
    } else {
      return false;
    }
  };

  const getPathByRole = (role) => {
    switch (role) {
      case 1:
        return "/super/profile";
      case 2:
        return "/sub/profile";
      case 3: // Assuming 3 is the role for end-users
        return "/end/profile";
      default:
        return "/unauthorized"; // Fallback or unauthorized route
    }
  };

  return (
    <ToastProvider>
      <Router>
        <Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              Loading...
            </div>
          }>
          <Routes>
            {/*Root Route */}
            <Route
              path='/'
              element={
                isAuthenticated() ? (
                  <Navigate
                    to={
                      JSON.parse(localStorage.getItem("access_token")).role === 1
                        ? "/super/dashboard"
                        : JSON.parse(localStorage.getItem("access_token")).role === 2
                        ? "/sub/dashboard"
                        : "/end/dashboard"
                    }
                  />
                ) : (
                  <Navigate to='/login' />
                )
              }
            />
            {/* Public Routes */}
            <Route
              path='/login'
              element={
                <UnprotectedRoute>
                  <Login />
                </UnprotectedRoute>
              }
            />
            <Route
              path='/page'
              element={
                <UnprotectedRoute>
                  <PageNotFound />
                </UnprotectedRoute>
              }
            />

            <Route
              path='/email-sent'
              element={
                <UnprotectedRoute>
                  <EmailSent />
                </UnprotectedRoute>
              }
            />
            <Route
              path='/reset-password'
              element={
                <UnprotectedRoute>
                  <ResetPassword />
                </UnprotectedRoute>
              }
            />
            <Route
              path='/successful'
              element={
                <UnprotectedRoute>
                  <Successful />
                </UnprotectedRoute>
              }
            />

            <Route
              path='/access-denied'
              element={
                <UnprotectedRoute>
                  <AccessDenied />
                </UnprotectedRoute>
              }
            />
            <Route
              path='/update-pass'
              element={
                <UnprotectedRoute>
                  <UpdatePassword />
                </UnprotectedRoute>
              }
            />
            <Route
              path='/change-pass'
              element={
                <UnprotectedRoute>
                  <ChangePassword />
                </UnprotectedRoute>
              }
            />

            {/* Protected Route */}
            {/* Protected Route  Super-Admin*/}
            <Route
              path='/super/dashboard'
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path='/super/profile'
              element={
                <ProtectedRoute>
                  <SuperProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path='/super/input'
              element={
                <ProtectedRoute>
                  <InputData />
                </ProtectedRoute>
              }
            />
            <Route
              path='/super/report'
              element={
                <ProtectedRoute>
                  {" "}
                  <Report />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path='/super/group'
              element={
                <ProtectedRoute>
                  {" "}
                  <GroupManagement />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path='/super/report/list'
              element={
                <ProtectedRoute>
                  {" "}
                  <ReportTable />{" "}
                </ProtectedRoute>
              }
            />
            {/* <Route
              path='/super/report/list/generate'
              element={
                <ProtectedRoute>
                  {" "}
                  <Report1 />{" "}
                </ProtectedRoute>
              }
            /> */}
            <Route
              path='/super/group/add-user'
              element={
                <ProtectedRoute>
                  <AddUser />
                </ProtectedRoute>
              }
            />
            <Route
              path='/super/group/add-group'
              element={
                <ProtectedRoute>
                  <AddGroup />
                </ProtectedRoute>
              }
            />






            {/* SubAdminRoute */}
            <Route
              path='/sub/dashboard'
              element={
                <ProtectedRoute>
                  <DashboardSub />
                </ProtectedRoute>
              }
            />
            <Route
              path='/sub/profile'
              element={
                <ProtectedRoute>
                  <SubProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path='/sub/input'
              element={
                <ProtectedRoute>
                  <InputSub />
                </ProtectedRoute>
              }
            />
            <Route
              path='/sub/company'
              element={
                <ProtectedRoute>
                  <CompanyManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path='/sub/company/add-company'
              element={
                <ProtectedRoute>
                  <AddCompany />
                </ProtectedRoute>
              }
            />
            {/* <Route
              path='/sub/report'
              element={
                <ProtectedRoute>
                  <ReportSub />
                </ProtectedRoute>
              }
            /> */}
            <Route
              path='/sub/report'
              element={
                <ProtectedRoute>
                  <SubAdminReportTable />
                </ProtectedRoute>
              }
            />

            {/* EndUserRoute */}
            <Route
              path='/end/dashboard'
              element={
                <ProtectedRoute>
                  <DashboardEnd />
                </ProtectedRoute>
              }
            />
            <Route
              path='/end/profile'
              element={
                <ProtectedRoute>
                  <EndProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path='/end/data'
              element={
                <ProtectedRoute>
                  <DataManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path='/end/data/add-data'
              element={
                <ProtectedRoute>
                  <AddData />
                </ProtectedRoute>
              }
            />

            {/* <Route
              path={getPathByRole(JSON.parse(localStorage.getItem("access_token")).role)}
              // path={roleData === 1 ? "/super/profile" : roleData === 2 ? "/sub/profile" : "/end/profile"}
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            /> */}
            <Route path='*' element={<Navigate to={isAuthenticated() ? "/page" : "/login"} />} />
          </Routes>
        </Suspense>
      </Router>
    </ToastProvider>
  );
}

export default Container;
