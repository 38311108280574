import React, { useEffect, useState } from "react";
import "./AddCompany.scss";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import Dropdown from "../../../../Components/Dropdown";
import { BASE_DOMAIN, categories, CompanySize, Country, Status, Utilities } from "../../../../utils/constants";
import Header from "../../../../Components/Header";
import { useCompidQuery, useCreatecompanyMutation, useFetchcompanyQuery, useUpdateCompanyMutation } from "../../../../features/Group/groupSlice";
import { emailValidation, nameValidation, phoneValidation, zipValidation } from "../../../../utils/validations";
import InputError from "../../../../Components/InputError";
import { useToast } from "../../../ToastProvider";

const AddCompany = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const { data: randomData, refetch } = useCompidQuery();
  const navigate = useNavigate();
  const [companyInfo, setCompanyInfo] = useState({
    id: "",
    name: "",
    category: "",
    size: "",
    utility: "",
  });
  const [basic, setBasic] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    status: "",
  });
  const [address, setAddress] = useState({
    address: "",
    city: "",
    state: "",
    zipcode: "",
    country: "United States",
  });
  const [category, setCategory] = useState("");
  const { showSuccess, showError } = useToast();
  const dynamicUrl = `${BASE_DOMAIN}companies/${JSON.parse(localStorage.getItem("group"))}`;
  const { data: listData = { data: [] }, refetch: refetchData } = useFetchcompanyQuery({
    url: dynamicUrl, // Replace with the base endpoint URL
    filters: { page: 1, limit: 100 },
  });
  const [updateCompany, { isLoading: uploading }] = useUpdateCompanyMutation();

  const [validation, setValidation] = useState({
    name: "",
    category: "",
    size: "",
    utility: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    status: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
  });
  const [createcompany, { isLoading, isError, isSuccess, data }] = useCreatecompanyMutation();

  console.log("loc", location.pathname);

  useEffect(() => {
    refetch();
    refetchData();
  }, [location.pathname]);
  useEffect(() => {
    if (queryParams.get("isupdate")) {
      const result = listData?.data.find((item) => item.id == queryParams.get("compid"));
      setCompanyInfo({
        id: result?.companyId,
        name: result?.name,
        category: result?.category,
        size: result?.companySize,
        utility: result?.utilityType,
      });
      setBasic({
        firstname: result?.firstName,
        lastname: result?.lastName,
        email: result?.email,
        phone: result?.phoneNumber,
        status: result?.status,
      });
      setAddress({
        address: result?.address,
        city: result?.city,
        state: result?.state,
        zipcode: result?.zipCode,
        country: result?.country,
      });

      console.log("REDDD", result);
    } else {
    }
  }, [listData]);

  const handleCreateUser = () => {
    navigate("/user");
  };

  const handleChange = (event, val) => {
    // console.log("EV",event,val)
    if (val === "category") {
      setCompanyInfo({ ...companyInfo, category: event });
    }
    if (val === "size") {
      setCompanyInfo({ ...companyInfo, size: event });
    }
    if (val === "utility") {
      setCompanyInfo({ ...companyInfo, utility: event });
    }
    if (val === "basic") {
      setBasic({ ...basic, status: event });
    }
    // if (val === "country") { setAddress({ ...address, country: event }) }
  };

  const handleCreateCompany = async () => {
    console.log(companyInfo, "COMP");

    const companyNameValidation = nameValidation(companyInfo.name, "Company name ");
    console.log("COM", companyNameValidation);
    const categoryValidation = nameValidation(companyInfo.category, "Category");
    const sizeValidation = nameValidation(companyInfo.size, "Size");
    const utilityValidation = nameValidation(companyInfo.utility, "Utility");
    const firstnameValidation = nameValidation(basic.firstname, "First name");
    const lastnameValidation = nameValidation(basic.lastname, "Last name");
    const emailNameValidation = emailValidation(basic.email);
    const phoneDataValidation = phoneValidation(basic.phone);
    const statusNameValidation = nameValidation(basic.status, "Status");
    const addressValidation = nameValidation(address.address, "Address");
    const cityValidation = nameValidation(address.city, "City");
    const stateValidation = nameValidation(address.state, "State");
    const zipDataValidation = zipValidation(address.zipcode);
    const countryValidation = nameValidation(address.country, "Country");

    if (
      companyNameValidation.isValid &&
      categoryValidation.isValid &&
      sizeValidation.isValid &&
      utilityValidation.isValid &&
      firstnameValidation.isValid &&
      lastnameValidation.isValid &&
      emailNameValidation.isValid &&
      phoneDataValidation.isValid &&
      statusNameValidation.isValid &&
      addressValidation.isValid &&
      cityValidation.isValid &&
      stateValidation.isValid &&
      zipDataValidation.isValid &&
      countryValidation.isValid
    ) {
      setValidation({
        name: "",
        category: "",
        size: "",
        utility: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        status: "",
        address: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
      });

      try {
        const payload = {
          name: companyInfo.name,
          email: basic.email,
          companyId: randomData?.companyId,
          groupId: id,
          status: basic.status,
          category: companyInfo.category,
          utilityType: companyInfo.utility,
          companySize: companyInfo.size,
          address: address.address,
          city: address.city,
          state: address.state,
          zipCode: address.zipcode,
          country: address.country,
          phoneNumber: basic.phone,
          firstName: basic.firstname,
          lastName: basic.lastname,
        };

        if (queryParams.get("isupdate")) {
          console.log("PY", payload);
          const result = await updateCompany({ id: queryParams.get("compid"), body: payload }).unwrap();
          console.log("Company updated successfully!", result);
          showSuccess({
            body: "Company updated successfully",
          });
          navigate({
            pathname: "/sub/company",
            search: `?${createSearchParams({ id: localStorage.getItem("group") })}`,
          });
        } else {
          const result = await createcompany(payload).unwrap();
          showSuccess({
            body: "Company created successfully",
          });
          navigate({
            pathname: "/sub/company",
            search: `?${createSearchParams({ id: localStorage.getItem("group") })}`,
          });
        }
      } catch (err) {
        showError({
          body: err?.data?.message || err?.status || "something went wrong",
        });
      }
    } else {
      setValidation({
        name: companyNameValidation.message,
        category: categoryValidation.message,
        size: sizeValidation.message,
        utility: utilityValidation.message,
        firstname: firstnameValidation.message,
        lastname: lastnameValidation.message,
        email: `${emailNameValidation.message}`,
        phone: phoneDataValidation.message,
        status: statusNameValidation.message,
        address: addressValidation.message,
        city: cityValidation.message,
        state: stateValidation.message,
        zipcode: zipDataValidation.message,
        country: countryValidation.message,
      });
    }
  };

  const handleCancelClick = () => {
    navigate({
      pathname: "/sub/company",
      search: `?${createSearchParams({ id: id })}`,
    });
  };

  const handleCompanyChange = (e) => {
    const { name, value } = e.target; // Destructure name and value from the input
    setCompanyInfo((prevData) => ({
      ...prevData,
      [name]: value, // Update the specific field in state
    }));
  };
  const handleBasicChange = (e) => {
    const { name, value } = e.target; // Destructure name and value from the input
    setBasic((prevData) => ({
      ...prevData,
      [name]: value, // Update the specific field in state
    }));
  };
  const handleAddressChange = (e) => {
    const { name, value } = e.target; // Destructure name and value from the input
    setAddress((prevData) => ({
      ...prevData,
      [name]: value, // Update the specific field in state
    }));
  };

  // console.log("COM",companyInfo)

  return (
    <>
      <Header
        title={queryParams.get("isupdate") ? "Update Company" : "Add Company"}
        description={queryParams.get("isupdate") ? "Fill the below details to update company." : "Fill the below details to add company."}
        isSearchField={false}
        isCancel={true}
        btnText={queryParams.get("isupdate") ? "Update" : "Add"}
        btnLoading={isLoading || uploading}
        // isIcon={false}
        onAction1Click={handleCreateCompany}
        handleCancel={handleCancelClick}
      />
      {/* <div className='head_container'>
        <div>
          <h2 className='title'>Add User</h2>
          <p className='subtitle'>Fill the below details to add users.</p>
        </div>

        <div className='form_actions'>
          <button type='button' className='btn_cancel'>
            Cancel
          </button>
          <button type='submit' className='btn_create' onClick={handleCreateUser}>
            Add
          </button>
        </div>
      </div> */}
      <div className='super_container_add_user' id='modal_body'>
        <div className='add_user_container'>
          <form className='form'>
            <div className='section'>
              <h3 className='section_title'>Company Information</h3>
              <div className='form_group'>
                <div className='input_group'>
                  <label>Company ID</label>
                  <input
                    className='dark_input'
                    type='text'
                    placeholder={queryParams.get("isupdate") ? companyInfo.id : randomData?.companyId || "Company ID"}
                    disabled
                  />
                </div>
                <div className='input_group'>
                  <label>Company Name</label>
                  <input
                    className={`input-styling ${validation.name.length ? "error" : ""}`}
                    type='text'
                    name='name'
                    placeholder='Enter Company Name'
                    value={companyInfo.name}
                    onChange={handleCompanyChange}
                  />
                  {validation.name.length ? <InputError text={validation.name} /> : <div></div>}
                </div>
                <Dropdown
                  label={"Category"}
                  options={categories}
                  value={companyInfo.category}
                  onChange={(e) => handleChange(e, "category")}
                  placeholder='Select Category'
                  isError={validation.category.length}
                  errorMessage={validation.category}
                />
                <Dropdown
                  label={"Company Size"}
                  options={CompanySize}
                  value={companyInfo.size}
                  onChange={(e) => handleChange(e, "size")}
                  placeholder='Select Size'
                  isError={validation.size.length}
                  errorMessage={validation.size}
                />
                <Dropdown
                  label={"Type Of Utility"}
                  options={Utilities}
                  value={companyInfo.utility}
                  onChange={(e) => handleChange(e, "utility")}
                  placeholder='Select Utility Type'
                  isError={validation.utility.length}
                  errorMessage={validation.utility}
                />
              </div>
            </div>

            <div className='section'>
              <h3 className='section_title'>User Information</h3>
              <div className='form_group'>
                <div className='input_group'>
                  <label>First Name</label>
                  <input
                    type='text'
                    className={`input-styling ${validation.firstname.length ? "error" : ""}`}
                    name='firstname'
                    placeholder='Enter First Name'
                    value={basic.firstname}
                    onChange={handleBasicChange}
                  />
                  {validation.firstname.length ? <InputError text={validation.firstname} /> : <div></div>}
                </div>
                <div className='input_group'>
                  <label>Last Name</label>
                  <input
                    type='text'
                    className={`input-styling ${validation.lastname.length ? "error" : ""}`}
                    name='lastname'
                    placeholder='Enter Last Name'
                    value={basic.lastname}
                    onChange={handleBasicChange}
                  />
                  {validation.lastname.length ? <InputError text={validation.lastname} /> : <div></div>}
                </div>
                <div className='input_group'>
                  <label>Email Address</label>
                  <input
                    type='email'
                    className={queryParams.get("isupdate") ? "dark_input" : `input-styling ${validation.email.length ? "error" : ""}`}
                    disabled={queryParams.get("isupdate")}
                    name='email'
                    placeholder='Enter Email Address'
                    value={basic.email}
                    onChange={handleBasicChange}
                  />
                  {validation.email.length ? <InputError text={validation.email} /> : <div></div>}
                </div>
                <div className='input_group'>
                  <label>Phone Number</label>
                  <input
                    type='number'
                    className={`input-styling ${validation.phone.length ? "error" : ""}`}
                    name='phone'
                    placeholder='Enter Phone Number'
                    value={basic.phone}
                    onChange={handleBasicChange}
                  />
                  {validation.phone.length ? <InputError text={validation.phone} /> : <div></div>}
                </div>
                <Dropdown
                  label={"Status"}
                  options={Status}
                  value={basic.status}
                  onChange={(e) => handleChange(e, "basic")}
                  placeholder='Select Status'
                  isError={validation.status.length}
                  errorMessage={validation.status}
                />
              </div>
            </div>

            <div className='section'>
              <h3 className='section_title'>Address</h3>
              <div className='form_group'>
                <div className='input_group'>
                  <label>Address</label>
                  <input
                    type='text'
                    className={`input-styling ${validation.address.length ? "error" : ""}`}
                    name='address'
                    placeholder='Enter Address'
                    value={address.address}
                    onChange={handleAddressChange}
                  />
                  {validation.address.length ? <InputError text={validation.address} /> : <div></div>}
                </div>
                <div className='input_group'>
                  <label>City</label>
                  <input
                    type='text'
                    className={`input-styling ${validation.city.length ? "error" : ""}`}
                    name='city'
                    placeholder='Enter City Name'
                    value={address.city}
                    onChange={handleAddressChange}
                  />
                  {validation.city.length ? <InputError text={validation.city} /> : <div></div>}
                </div>
                <div className='input_group'>
                  <label>State</label>
                  <input
                    type='text'
                    className={`input-styling ${validation.state.length ? "error" : ""}`}
                    name='state'
                    placeholder='Enter State'
                    value={address.state}
                    onChange={handleAddressChange}
                  />
                  {validation.state.length ? <InputError text={validation.state} /> : <div></div>}
                </div>
                <div className='input_group'>
                  <label>Zip Code</label>
                  <input
                    type='number'
                    className={`input-styling ${validation.zipcode.length ? "error" : ""}`}
                    name='zipcode'
                    placeholder='Enter Zip Code'
                    value={address.zipcode}
                    onChange={handleAddressChange}
                  />
                  {validation.zipcode.length ? <InputError text={validation.zipcode} /> : <div></div>}
                </div>
                <div className='input_group'>
                  <label>Country</label>
                  <input className='dark_input' type='text' placeholder={"United States"} disabled />
                </div>
                {/* <Dropdown
                  label={"Country"}
                  options={Country}
                  value={address.country}
                  onChange={(e) => handleChange(e, "country")}
                  placeholder="Select Country"
                  isError={validation.country.length}
                  errorMessage = {validation.country}
                  
                /> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddCompany;
