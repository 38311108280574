import React, { useEffect, useState } from "react";
import "./_index.scss";
import FileInput from "./FileInput";
import CommonModal from "../../../../Components/Modals/index"
import { useCreateMutation, useFetchQuery, useRandomQuery, useUpdategroupMutation, useUploadMutation } from "../../../../features/Group/groupSlice";
import { useToast } from "../../../ToastProvider";
import { emailValidation, nameValidation, passwordValidation } from "../../../../utils/validations";
import { CloseIcon, DeleteOutlineOutlinedIcon } from "../../../../utils/icons";
import Header from "../../../../Components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import InputError from "../../../../Components/InputError";
import { BASE_DOMAIN, Color_Styling } from "../../../../utils/constants";
import CommonButton from "../../../../Components/Button";
// import CommonModal from "./../../../Components/Modals/index";
const Index = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const [file, setFile] = useState({
    preview: "",
    data: ""
  });
  const navigate = useNavigate()
  const [dragging, setDragging] = useState(false);
  const [isFileInputChange, setIsFileInputChange] = useState(false);
  const [openPopUp, setIsOpenPopUp] = useState(false);
  const [inputData, setInputData] = useState({
    name: "",
  })
  const [validation, setValidation] = useState({
    name: "",
    email: false,
    isFile: false
  })
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [items, setItems] = useState([]);
  // const [emails, setEmails] = useState([]); // State to store the list of emails
  const [inputValue, setInputValue] = useState(""); // State for the input field
  const { showSuccess, showError } = useToast();
  const { data = { data: [] }, refetch } = useFetchQuery({ page: 1, limit: 100 });
  const { data: randomData, refetch: refetchData } = useRandomQuery();
  // const [updateVal,setUpdateVal] = useState({})
  const [create, { isLoading, isError, isSuccess }] = useCreateMutation();
  const [upload, { data: uploadData, isLoading: uploading }] = useUploadMutation()
  const [updategroup, { data: updateData, isLoading: uploadLoading }] = useUpdategroupMutation()
  const [grID, setGrID] = useState(randomData?.groupId || "")

  console.log(randomData)

  useEffect(() => {
    refetchData()
  }, [location.pathname])

  useEffect(() => {
    if (id) {
      console.log("DT",data)
      const result = data.data.find((item) => item.id == id);
      // console.log("MAtch",result)
      if (result) {
        setInputData({
          name: result?.name
        })
        // setEmails(result.subAdminEmails)
        setFile({
          preview: result.logo,
          data: result.logo
        })
        setItems(result?.subAdminEmails)
        setGrID(result?.groupId)
        // console.log("Res", result); // You can do something with the result here, like setting state
        // setUpdateVal(result); // If you want to use the result for updating or setting the state
      } else {
        // setGrID(randomData.groupId)
      }
    }
  }, [id, data]);

  console.log("CODE IN")





  const handleAddImage = async (event) => {
    event.stopPropagation()
    setIsFileInputChange(true);
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const validTypes = ["image/jpeg", "image/png"];
      if (!validTypes.includes(selectedFile.type)) {
        setFile({
          preview: "",
          data: ""
        });
        showError({
          body: "Only JPG and PNG files are allowed."
        });
        return;
      }
      if (selectedFile.size < 5 * 1024 * 1024) {
        const formData = new FormData();
        formData.append("file", selectedFile)
        const result = await upload(formData).unwrap()
        setFile(
          {
            preview: URL.createObjectURL(selectedFile),
            data: result.url
          }
        );
      }
      else {
        setFile({
          preview: "",
          data: ""
        })
        console.log("ERR HEYYYY")
        showError({
          body: "Cannot exceend more than 5Mb"
        })
      }
    }
    event.target.value = "";
    setIsFileInputChange(false);
  };
  const handleDeleteImage = () => {
    setDragging(false)
    setIsFileInputChange(false)
    setIsOpenPopUp(false)
    setFile({
      preview: "",
      data: ""
    });
  };

  const handleCancelClick = () => {
    navigate("/super/group")
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  // const handleKeyDown = (e) => {
  //   if (e.key === "Enter" && inputValue.trim()) {
  //     setValidation({
  //       ...validation,
  //       email :false,
  //     })
  //     if (emails.includes(inputValue.trim())) {
  //       showError({
  //         body: "Email is already added.",
  //       });
  //       return;
  //     }
  //     if (emailValidation(inputValue).isValid) {
  //       setEmails([...emails, inputValue.trim()]); // Add email to the list
  //       setInputValue(""); // Clear the input field
  //     } else {
  //        showError({
  //         body:"Invalid email address"
  //        })
  //     }
  //   }
  // };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const selectedFile = e.dataTransfer.files[0];
    if (selectedFile) {
      const validTypes = ["image/jpeg", "image/png"];
      if (!validTypes.includes(selectedFile.type)) {
        setFile({
          preview: "",
          data: ""
        });
        showError({
          body: "Only JPG and PNG files are allowed."
        });
        return;
      }
      if (selectedFile.size < 5 * 1024 * 1024) {
        const formData = new FormData();
        formData.append("file", selectedFile)
        const result = await upload(formData).unwrap()
        setFile(
          {
            preview: URL.createObjectURL(selectedFile),
            data: result.url
          }
        );
      }
      else {
        setFile({
          preview: "",
          data: ""
        })
        // console.log("ERR HEYYYY")
        showError({
          body: "Cannot exceend more than 5Mb"
        })
        e.target.value = "";
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  // // Function to remove an email
  // const handleRemoveEmail = (index) => {

  //   const updatedEmails = emails.filter((_, i) => i !== index);
  //   // setEmails(updatedEmails); // Update the emails list
  // };

  const handleDeleteIconClick = (e) => {
    e.stopPropagation(); // This ensures that the click doesn't trigger onDrop again
    if (!dragging && !isFileInputChange) {  // Only trigger if not dragging
      setIsOpenPopUp(true);  // Open modal to confirm delete
    }
  };



  const handleAdd = () => {
    const trimmedEmail = email.trim();
  const trimmedName = name.trim();

  // Validate if email and name are non-empty
  if (!trimmedEmail || !trimmedName) {
    showError({
      body: "Both name and email are required.",
    });
    return;
  }

  // Check if the email already exists in the items array
  const emailExists = items.some((item) => item.email === trimmedEmail);
  if (emailExists) {
    showError({
      body: "Email is already added.",
    });
    return;
  }

  // Check if the email format is valid
  const emailValid = emailValidation(trimmedEmail);
  if (!emailValid.isValid) {
    showError({
      body: "Invalid email format.",
    });
    return;
  }

  // Add the new item to the items list
  setItems([...items, { email: trimmedEmail, name: trimmedName }]);
  setEmail('');
  setName('');
    // if (email.trim() && name.trim()) {
    //   if (items.includes(email.trim())) {
    //           showError({
    //             body: "Email is already added.",
    //           });
    //           return;
    //         }}
    //   const emailVald = emailValidation()
    //   setItems([...items, { email, name }]);
    //   setEmail('');
    //   setName('');
    }

  const handleDelete = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };



  const handleCreateGrpData = async () => {
    // console.log("FILE",file)
    const nameValidationData = nameValidation(inputData.name, "Group name")
    // const emailLength = emails.length
    // const isFile = file.data 
    // console.log(nameValidationData,inputData,emails,file,">>>>")
    if (nameValidationData.isValid && file.data && items.length > 0) {
      if (id) {
        try {
          const url = `${BASE_DOMAIN}groups/${id}`
          setValidation({
            name: "",
            email: false,
            isFile: false
          })

          const formData1 = {
            name:inputData.name,
            subAdminEmail:items,
            logo:file.data,
            // groupId:randomData?.groupId
          }
          // const formData1 = new FormData();
          // formData1.append('name', inputData.name);
          // // formData1.append('subAdminEmail', JSON.stringify(emails));
          // formData1.append('logo', file.data);

          // for (let [key, value] of formData1.entries()) {
          //   console.log(`${key}:`, value);
          // }

          // // console.log(formData1,">>>>",inputData.name,emails,file)
          // // formData.append('groupId', randomData?.groupId);
          const response = await updategroup({ url, formData1 }).unwrap()
          showSuccess({
            body: "Group Updated Successfully"
          })
          refetch()
          navigate("/super/group")

        }
        catch (err) {
          console.log("ERDRBAD", err)
          showError({
            body: err?.data?.message || err?.status || "something went wrong"
          })
        }
      }
      else {
        try {
          setValidation({
            name: "",
            email: false,
            isFile: false
          })
          const payload = {
            name:inputData.name,
            subAdminEmail:items,
            logo:file.data,
            groupId:randomData?.groupId
          }
          // const formData = new FormData();
          // formData.append('name', inputData.name);
          // // formData.append('subAdminEmail', JSON.stringify(emails));
          // formData.append('logo', file.data);
          // formData.append('groupId', randomData?.groupId);

          const result = await create(payload).unwrap();
          console.log("RDDD", result)
          showSuccess({
            body: "created successfully"
          })
          refetch()
          navigate("/super/group")

        } catch (err) {
          showError({
            body: err?.data?.message || err?.status || "something went wrong"
          })
        }
      }
    }
    else {
      
      setValidation({
        name: nameValidationData.message,
        email : items.length > 0 ? false : true,
        isFile: file.data ? false : true
      })
    }

  };
  return (
    <div style={{ position: "relative" }}>
      <div className='add_grp_sub_admin_container'>
        <Header
          // title={id? "Update Group" : 'Add Group' }
          title={"Fill the below details to add a Group"}
          // description='Fill the below details to add a Group.'
          isSearchField={false}
          isCancel={true}
          btnText={id ? "Update" : 'Create'}
          btnLoading={id ? uploadLoading : isLoading}
          isIcon={false}
          onAction1Click={handleCreateGrpData}
          handleCancel={handleCancelClick}
        />

        <div className='add_grp_container' id='modal_body'>
          <div className='inner_add_grp_container'>
            <div className='add_grp_heading_container'>
              <div className='grp_container_heading'>Upload Group Logo</div>
              <div className='grp_container_desc'>
                Upload group logo in PNG or JPEG format. Ensure the file size is no larger than 5MB for a smooth upload experience.This logo will help
                you to personalize the group.
              </div>
            </div>

            <div
              className={`logo_upload_container`}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {uploading ? <div className={`add_grp_upload_image_container upload`}>Uploading...</div> :
                !file.preview ? (
                  <>
                    <div className={`add_grp_upload_image_container  ${!validation.isFile ? "" : "error"}`}>
                      <div className='inner_upload_image'>
                        <div className='inner_upload_image_desc'>
                          <p className='upload_image_head'>Drag and drop logo file to Upload</p>
                          <p className='upload_image_desc'>Maximum file size is 5 MB</p>
                        </div>
                        <div className='add_image_btn_container'>
                          <FileInput handleAddImage={handleAddImage} />
                        </div>
                      </div>
                    </div>
                    {validation.isFile ? <span><InputError text="Image is required" /></span> : <div></div>}
                  </>
                ) : (
                  <div className='grp_icon_container'>
                    {file.preview && <img src={file.preview} alt='Selected' className='image_style' />}
                    <div style={{ position: "relative" }}>
                      <img
                        src='/assets/images/delete_without_fill.svg'
                        onClick={handleDeleteIconClick}// Open the modal when clicked
                        style={{
                          backgroundColor: "red",
                          position: "absolute",
                          color: "white",
                          borderRadius: "50%",
                          top: "-52px",
                          left: "51px",
                          fontSize: "20px",
                          padding: "5px",
                          width: "18px",
                          height: "18px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    {openPopUp && (
                      <CommonModal
                        setIsOpenPopUp={setIsOpenPopUp}
                        handleDelete={handleDeleteImage}
                        open={openPopUp}
                        item={{
                          title: "Delete Profile Image?",
                          description: "Are you sure you want to delete the profile picture? This action is permanent and cannot be undone.",
                          icon:true
                        }}

                      />
                    )}
                  </div>
                )}
            </div>

            <div className='group_details_container'>
              <div className='inner_group_details_container'>
                <div className='input_group_details'>
                  <label>Group ID*</label>
                  <input className='dark_input_val' type='text' placeholder={id ? grID : randomData?.groupId} disabled={true} />
                </div>
                <div className='input_group_details'>
                  <label>Group Name*</label>
                  <input className={`input_grp_val ${validation.name.length ? "error" : ""}`} type='text' name="name" placeholder='Enter Group Name' value={inputData.name}
                    onChange={handleInputChange} />
                  {validation.name.length ? <InputError text={validation.name} /> : <div></div>}
                </div>

              </div>
            </div>

            <div className='sub_admin_head_container'>
              <div className='sub_admin_heading'>Add Sub-Admin</div>
              <div className='sub_admin_desc'>Add the details below to create a Sub-Admin for this group.</div>
            </div>
            <div className="sub-footr">
              
              <div className="sub-lbl">
                <label>Name</label>
                <input
                  className='input_grp_val'
                  type='text'
                  placeholder={"Enter Name"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="sub-lbl">
                <label>Email</label>
                <input
                  className='input_grp_val'
                  type='text'
                  placeholder={"Enter Email"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {/* <div className="sub-lbl">
                <label style={{ opacity: 0 }}>label</label> */}
                <div className="btn-adv" onClick={handleAdd}>
                  Add
                </div>
              {/* </div> */}

            </div>
            <div style={{marginTop:"4px"}}>
            {validation.email ? <InputError text={"Please add atleast one email & one name"} /> : <div></div>}
            </div>
            {items.length > 0 &&
              <div className="item-list">
                <div className="table-header">
                  <div className="header-cell">Name</div>
                  <div className="header-cell">Email</div>
                  <div className="header-cell"></div>
                </div>
                {items.map((item, index) => (
                  <div
                    key={index}
                    className={`table-row ${index === items.length - 1 ? "nobr" : ""}`}
                  >
                    <div className="row-cell">{item.name}</div>
                    <div className="row-cell">{item.email}</div>
                    <div
                      className="row-cell action-cell"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDelete(index)}
                    >
                        <DeleteOutlineOutlinedIcon style={{height:"18px",width:"18px"}}/>
                      {/* <img src="/assets/images/delRe.svg" alt="Delete Icon" /> */}
                    </div>
                  </div>
                ))}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
