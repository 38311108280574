import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice'
import { loginSlice } from '../features/Authentication/loginSlice';
import { groupSlice } from '../features/Group/groupSlice';
import { inputSlice } from '../features/Input/inputSlice';
import { reportSlice } from '../features/Report/reportSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [loginSlice.reducerPath]:loginSlice.reducer,
    [groupSlice.reducerPath]:groupSlice.reducer,
    [inputSlice.reducerPath]:inputSlice.reducer,
    [reportSlice.reducerPath]:reportSlice.reducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(loginSlice.middleware)
      .concat(groupSlice.middleware)
      .concat(inputSlice.middleware)
      .concat(reportSlice.middleware)
      ,
});
