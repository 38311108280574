import { ARRAY_FIELDS } from "../constants";


export function isValidUrl(url) {
    // Regular expression for a simple URL validation
    const urlPattern =
      /^(https?:\/\/)?([\w-]+(\.[\w-]+)+\/?)|localhost(\:\d+)?(\/[\w-]+)*\/?(\?[\w%&=]+)?(#\w+)?$/;
    // Test the provided string against the regular expression
    return urlPattern.test(url);
  }

 export  const convertObjectFormat = (inputObject) => {
    const result = {};
  
    // Iterate over each top-level key in the input object
    Object.keys(inputObject).forEach((sectionKey) => {
      const sectionData = inputObject[sectionKey];
  
      // Iterate over each key-value pair in the section
      Object.keys(sectionData).forEach((key) => {
        result[key] = sectionData[key];
      });
    });
  
    return result;
  };

 export function mergeArrayToObject(arr) {
    const mergedObject = {};
  
    arr.forEach(item => {
      Object.assign(mergedObject, item);
    });
  
    return mergedObject;
  }

  export const commonInputData = (arr,obj)=>{ 
    return arr.reduce((acc, key) => {
        if (key in obj) {
          acc[key] = obj[key];
        }
        return acc;
      }, {});
    }

 export  const transformData = (input, headerMapping) => {
    const output = {};
    for (const key in input) {
      const mappedKey = Object.keys(headerMapping).find(k => headerMapping[k] === key);
      if (mappedKey) {
        output[mappedKey] = input[key];
      }
    }
    return output;
  };

export const filterOutValidKeys = (array) => {
    return array.map(obj => {
      // Create a new object containing only keys not in ARRAY_FIELDS
      const filteredObj = {};
      for (const key in obj) {
        if (!ARRAY_FIELDS.includes(key)) {
          filteredObj[key] = obj[key];
        }
      }
      return filteredObj;
    });
  };

export const filterNumbers = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => typeof value === "number")
    );
  };

export const checkMissingKeys = (keysArray, obj) => {
    const missingKeys = keysArray.filter(key => !(key in obj));
    return missingKeys;
  };

  function categorizeObject(data) {
    const categories = {
        mbe: [],
        wmbe: [],
        pbdbe: [],
        lgbtbe: [],
        dvbe: [],
        other_8a: []
    };

    // Iterate through the keys of the object
    for (const key in data) {
        const categoryObject = {
            label: key,
            type: data[key]?.type || 0, // Assuming type is retrieved from data[key] or default to 0
            place: `Enter ${key.replace(/_/g, " ")}`,
            isClicked: false,
            isDropdown: false
        };

        if (key.startsWith("mbe")) {
            categories.mbe.push(categoryObject);
        } else if (key.startsWith("wmbe")) {
            categories.wmbe.push(categoryObject);
        } else if (key.startsWith("pbdbe")) {
            categories.pbdbe.push(categoryObject);
        } else if (key.startsWith("lgbtbe")) {
            categories.lgbtbe.push(categoryObject);
        } else if (key.startsWith("dvbe")) {
            categories.dvbe.push(categoryObject);
        } else if (key.startsWith("other_8a")) {
            categories.other_8a.push(categoryObject);
        }
    }

    return categories;
}

// Example Input Data
const inputData = {
    mbe_spend: { type: "Float", visible: false },
    mbe_percent: { type: "Float", visible: false },
    wmbe_spend: { type: "Float", visible: false },
    wmbe_number: { type: "Integer", visible: false },
    pbdbe_value: { type: "String", visible: true },
    lgbtbe_id: { type: "Integer", visible: false },
    dvbe_ratio: { type: "Float", visible: true },
    other_8a_details: { type: "Object", visible: true }
};

// Call the function
const categorizedData = categorizeObject(inputData);

// Output the categorized arrays
// console.log(categorizedData);



  