import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_DOMAIN } from '../../utils/constants';
import { delete_report, fetch_report, get_layout, set_layout, set_record, update_record } from './inputConstant';


export const inputSlice = createApi({
    reducerPath: 'inputApi', // Unique name for the slice
    baseQuery: fetchBaseQuery({ baseUrl: BASE_DOMAIN}),
  //   baseQuery: fetchBaseQuery({ baseUrl: BASE_DOMAIN }), // Replace with your API base URL
    endpoints: (builder) => ({
      setsave: builder.mutation({
        query: (credentials) => ({
          url: set_layout, // Replace with your login endpoint
          method: 'POST',
          body: credentials,
        }),
      }),
      fetchreport: builder.query({
            query: (id) => {
              
              // Include the filter as query parameters
              // const queryString = new URLSearchParams(filter).toString();
              return {
                url: `${fetch_report}/${id}`, // Append query params to the endpoint
                method: 'GET',
                keepUnusedDataFor: 0,
              };
            },
          }),

      deletereport: builder.mutation({
            query: (id) => ({
              url: `${delete_report}/${id}`, // Append the company ID to the endpoint
              method: 'DELETE', // Use the DELETE method
            }),
          }),
      getapi: builder.query({
            query: (id) => {
                console.log("ID",id)
              // Include the filter as query parameters
              return {
                url: `${get_layout}${id}`, // Append query params to the endpoint
                method: 'GET',
              };
            },
          }),
      setrecord: builder.mutation({
            query: (credentials) => ({
              url: set_record, // Replace with your login endpoint
              method: 'POST',
              body: credentials,
            }),
          }),
          updateRecord: builder.mutation({
            query: ({id,payload}) => ({
              url: `${update_record}/${id}`, // Replace with your login endpoint
              method: 'PUT',
              body: payload,
            }),
        
    }),
    })

});

export const { useSetsaveMutation,useGetapiQuery,useSetrecordMutation,useFetchreportQuery,useDeletereportMutation,useUpdateRecordMutation } = inputSlice