import React from "react";
import CompantManagementTable from "./CompanyManagementTable/CompantManagementTable";

const CompanyManagement = () => {
  return (
    <div className='company_management_table'>
      <CompantManagementTable />
    </div>
  );
};

export default CompanyManagement;
