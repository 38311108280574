import React from "react";
import Button from "@mui/material/Button";
import { Color_Styling } from "../../utils/constants";
import CircularProgress from "@mui/material/CircularProgress";

const CommonButton = ({
  text,
  variant = "contained",
  color = "primary",
  customTextColor = "#fff",
  size = "medium",
  onClick= ()=>{},
  disabled = false,
  startIcon,
  endIcon,
  isLoading = false,
  sx = {},
  ...props
}) => {
  console.log("PROPS", props);
  return (
    <Button
      variant={variant}
      fullWidth={props.fullWidth}
      sx={{
        backgroundColor: color || Color_Styling.basic,
        color: customTextColor || "#fff",
        borderRadius: "4px",
        // padding:"12px",
        fontSize: ".833vw",
        fontWeight: "500",
        fontFamily: "'Source Sans 3', sans-serif",
        textTransform: "none",
        position: "relative",
        padding: "0.833vw",
        height: "2.708vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        // height: "2.708vw", // Set fixed height
        '@media (max-width: 768px)': {
           fontSize: "12px", // Adjust font size for medium screens
           padding: "8px 10px", // Adjust padding
           height: "40px", // Adjust height
            },
        // width: "auto", // Preserve dynamic width unless specified in sx
        // '&:hover': {
        //   backgroundColor: customColor ? `${customColor}cc` : 'primary.dark',
        // },
        ...sx, // Merge additional styles
      }}
      //   color={color}
      size={size}
      onClick={onClick}
      disabled={disabled} // Disable button if loading
      startIcon={!isLoading && startIcon} // Hide startIcon when loading
      endIcon={!isLoading && endIcon} // Hide endIcon when loading
      {...props}>
        {isLoading ? (
        <CircularProgress
          size={16}
          sx={{
            color: customTextColor,
            // position: "absolute",
            
          }}
        />
      ) : (
        <span style={{ visibility: isLoading ? "hidden" : "visible" }}>{text}</span>
      )}
    </Button>
  );
};

export default CommonButton;
