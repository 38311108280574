import React from 'react'
import "./input-error.scss"
const InputError = (props) => {
  return (
    <div>
        <div className='err_styling'>{props.text}</div>
    </div>
  )
}

export default InputError