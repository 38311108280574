import React, { useEffect, useState } from "react";
import Header from "../../../../Components/Header";
import DynamicTable from "../../../../Components/Table";
import CommonModal from "../../../../Components/Modals/index";
import { BASE_DOMAIN, columnReport, columns, headerMappingData, rows, YEAR_LIST } from "../../../../utils/constants";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import { useDeleteCompanyMutation, useFetchcompanyQuery, useToggleCompanyMutation } from "../../../../features/Group/groupSlice";
import { Menu, Modal } from "@mui/material";
import "./GroupTable.scss";
// import ReportTableGeneration from "../../../../Components/ReportTable";
import { useGenerateReportMutation, useReportbackupMutation, useReportcompanyQuery, useUpdateStatusMutation } from "../../../../features/Report/reportSlice";
import { useToast } from "../../../ToastProvider";
import {
  DeleteOutlineOutlinedIcon,
  EditOutlinedIcon,
  ToggleOffOutlinedIcon,
  ToggleOnIcon,
  KeyboardArrowDownIcon,
  LockIcon,
  BackupIcon,
  LockOpenIcon,
  DownloadIcon
} from "../../../../utils/icons";
// import { Modal } from "@mui/material";
// import "./GroupTable.scss";
// import ReportTableGeneration from "../../../../Components/ReportTable";
// import { useReportcompanyQuery, useUpdateStatusMutation } from "../../../../features/Report/reportSlice";

// import { Modal } from "@mui/material";
// import "./GroupTable.scss";
// import ReportTableGeneration from "../../../../Components/ReportTable";
// import { useReportcompanyQuery, useUpdateStatusMutation } from "../../../../features/Report/reportSlice";
// >>>>>>> db679ea20309b82886f5819ae57c16f8e01454f1
import ReportHeader from "../../../../Components/ReportHeader";

const ReportTable = () => {
  const location = useLocation();
  const { showSuccess, showError } = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [reportDataList, setReportDataList] = useState({})
  const [modalData, setModalData] = useState({
    title: "",
    description: "",
    icon: "",
  });
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [dropdown, setDropdown] = useState([]);
  const [finalDwn,setFinalDwn] = useState([])
  const [filters, setFilters] = useState({ page: 1, limit: 10 });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [row, setRow] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [modal, setModal] = useState(false);
  const [updateStatus, { isLoading: statusLoad }] = useUpdateStatusMutation();
  // const [generateReport, { data: reportData, error, isLoading: reportLoading }] = useGenerateReportMutation();
  const [reportbackup, { data: reportBack, isLoading:backLoading }] = useReportbackupMutation();
  const currentYear = new Date().getFullYear(); // Get the current year
  const [selectedYear, setSelectedYear] = useState(currentYear); // Set default value as the current year

  const handleChange = (event) => {
    setSelectedYear(event.target.value); // Update the selected year
  };
  const [genModal, setGenModal] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [reportType, setReportType] = useState("");
  const [selectReportType, setSelectReportType] = useState("");
  // const [updateStatus, { isLoading: statusLoad }] = useUpdateStatusMutation();
  const dynamicUrl = `${BASE_DOMAIN}companies/${id}`;



  const { data = { data: [] }, error: comperr, isLoading, refetch } = useReportcompanyQuery(`${id}?page=${currentPage}&limit=${rowsPerPage}`);

  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedCategories, setSelectedCategories] = useState([...finalDwn]);

  // Handle "Select All" checkbox change
  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      setSelectedCategories(finalDwn); // Select all items
    } else {
      setSelectedCategories([]); // Deselect all items
    }
  };

  // Handle individual checkbox change
  const handleCategoryChange = (item) => {
    if (selectedCategories.includes(item)) {
      setSelectedCategories(selectedCategories.filter((category) => category !== item));
    } else {
      setSelectedCategories([...selectedCategories, item]);
    }
  };

  // Check if "Select All" should be checked
  const isSelectAllChecked = selectedCategories.length === finalDwn.length;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    console.log("ROW",row)
    const listD = Object.entries(row).filter(([key, value]) => value ) // Only include fields where `visible` is true
    .map(([key]) => key);
    const test =  listD.map((key) => headerMappingData[key]).filter((value) => value !== undefined); 
    setFinalDwn(test || [])
    console.log(listD,">>>>>>>>>",test)
  };

  const handleCloseDwn = () => {
    setAnchorEl(null);
  };

  console.log("DTF", data);
  const updatedData = data?.records?.map((obj) => ({
    ...obj,
    compId: obj?.company?.companyId,
  }));

  console.log("UPD", updatedData,);
  const navigate = useNavigate();
  const [deleteCompany, { isLoading: delLoading, isSuccess, error: anyError }] = useDeleteCompanyMutation();
  const [toggleCompany, { isLoading: toggleLoading, isSuccess: togSuccess, isError }] = useToggleCompanyMutation();

  const handlePageChange = (page) => {
    setFilters({
      ...filters,
      page: page,
    });
    setCurrentPage(page);
  };

  console.log("DATA", data, isLoading, queryParams.get("grp"), queryParams.get("grpname"), filters);

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setFilters({ ...filters, search: value });
  };

  const handleRowValue = (val) => {
    setRow(val);
    console.log(val, "VLLLLL");
    // if (val.status === "Inactive") {
    if (val.status === "Active") {
      setDropdown([
        {
          name: "Lock Report",
          icon: <LockIcon />,
          id: 1,
        },
        {
          name: "Manage Backup",
          icon: <BackupIcon />,
          id: 2,
        },
        {
          name: "Download Report",
          icon: <DownloadIcon />,
          id: 3,
        },
        // {
        //   name: "Make Group Active",
        //   icon: <ToggleOffOutlinedIcon />,
        //   id: 3,
        // },
      ]);
    } else {
      setDropdown([
        {
          name: "Unlock Report",
          icon: <LockOpenIcon />,
          id: 1,
        },
        {
          name: "Manage Backup",
          icon: <BackupIcon />,
          id: 2,
        },
        {
          name: "Download Report",
          icon: <DownloadIcon />,
          id: 3,
        },
        // {
        //   name: "Make Group Active",
        //   icon: <ToggleOffOutlinedIcon />,
        //   id: 3,
        // },
      ]);
    }


  };

  const handleChangeRowsPerPage = (event) => {
    console.log("vv", parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    const newLimit = parseInt(event.target.value, 10);
    setFilters((prevFilters) => ({
      ...prevFilters,
      limit: newLimit,
      page: 1,
    }));
    setCurrentPage(1);
  };

  const handleBackup = async()=>{
    try{
    const result = await reportbackup({ id: row.id}).unwrap();
    console.log("RGG",result)
    showSuccess({
      body: "Backup Successful"
    })
    refetch();
  }catch(err){
    showError({
      title: "Error!",
      body: err?.data?.message || err?.status || "something went wrong",
    });
  }
  }

  const handleMenuItem = (val) => {

    if (val.id === 1) {
      if (row.status === "Active") {
        setModalData({
          title: "Lock Report",
          description: "Are you sure you want to lock this Report?",
          icon: false,
        });
      }
      else {
        setModalData({
          title: "Unlock Report",
          description: "Are you sure you want to Unlock this Report?",
          icon: false,
        });
      }


      setModal(true);
      // navigate({
      //   pathname: "/super/group/add-user",
      //   search: `?${createSearchParams({ id: id, isupdate: true, compid: row.id })}`,
      // });
    }
    if (val.id === 2) {
       handleBackup()
    }
    if (val.id === 3) {
      setDownloadModal(true);
    }
  };

  const handleReset = () => {
    setFilters({
      page: 1,
      limit: 10,
    });
  };

  const handleFilter = (itm1 = [], itm2 = []) => {
    function replaceGasAndElectricIfPresent(inputArray) {
      console.log("INP", inputArray);
      if (inputArray.includes("Gas & electric")) {
        return inputArray.map((item) => (item === "Gas & electric" ? "GasandElectric" : item));
      }
      return inputArray;
    }

    const updatedEnergyTypes = replaceGasAndElectricIfPresent(itm2);
    console.log(updatedEnergyTypes, "UPD");

    if (itm1.length > 0 && updatedEnergyTypes.length > 0) {
      setFilters({
        ...filters,
        category: itm1,
        utilityType: itm2,
      });
    } else if (itm1.length > 0) {
      setFilters({
        ...filters,
        category: itm1,
      });
    } else if (updatedEnergyTypes.length > 0) {
      setFilters({
        ...filters,
        utilityType: updatedEnergyTypes,
      });
    } else {
      setFilters({
        page: 1,
      });
    }
  };


  useEffect(() => {
    console.log("Filters updated:", filters);
    refetch();
  }, [location.pathname, filters]);
  const handleSort = () => {
    setFilters({
      ...filters,
    });
  };

  const handleBack = () => {
    navigate("/super/report");
  };

  const handleConfirmAction = async () => {
    try {
      if (modalData.title === "Lock Report") {
        const result = await updateStatus({ id: row.id, payload: { status: "Locked" } }).unwrap();
        showSuccess({
          body: "Status Updated Successfully"
        })
        refetch();
      }
      else if (modalData.title === "Unlock Report") {
        const result = await updateStatus({ id: row.id, payload: { status: "Active" } }).unwrap();
        showSuccess({
          body: "Status Updated Successfully"
        })
        refetch();
      } else {
        const result = await toggleCompany(row.id).unwrap();
        refetch();
      }
    } catch (err) { }
    setModal(false);
  };

  // const years = Array.from({ length: 21 }, (_, i) => 2025 - i);
  // const [genModal, setGenModal] = useState(false);
  // const [reportType, setReportType] = useState("");
  // const [selectReportType, setSelectReportType] = useState("");
  // const [activeTable, setActiveTable] = useState("");

  const handleAction1Click = () => {
    setGenModal(true);
  }

  const handleClose = () => {
    setGenModal(false);
    setDownloadModal(false);
  }

  const handleGenerate = async () => {
    // console.log(selectedYear,selectReportType,"?????????")
    // if(selectReportType){
    //   if(selectReportType === "Report_1a_Large" || selectReportType === "Report_1a_Small" || selectReportType === "Report_1a_CCA"){
    //     navigate({
    //       pathname: "/super/report/list/generate",
    //       search: `?${createSearchParams({ year: selectedYear, reportType: selectReportType, groupId: id })}`,
    //     });
    //   }

      // setReportType(selectReportType);
      // try {
      //   const result = await generateReport({ year: selectedYear, reportType: selectReportType, groupId: id })
      //   setReportDataList(result?.data?.data)
      //   // console.log("RETTT", result)
      // } catch (err) {
  
      // }
      // console.log(selectedYear, selectReportType, ">>>>>>>>>>>")
    //   handleClose();
    // }
    // else{
    //   showError({
    //     body:"Please Select Report Type"
    //   })
    // }

  }

  const handleReportTypeChange = (evt) => {
    setSelectReportType(evt.target.value);
  };

  const handleDownload = () => {
    console.log("Download button clicked");
    setDownloadModal(false);
  };

  useEffect(() => {
    setSelectedCategories(finalDwn); // Automatically select all items initially
  }, [finalDwn]);

  return (
    <div>
        <>
          <Header
            title={queryParams.get("grpname") || "Group List"}
            description={`#${queryParams.get("grp")}` || "#ID0000"}
            // searchValue={searchValue}
            // isSearchDisable={true}
            btnText='Generate Report'
            isSearchField={false}
            // searchText='Search by Company Name'
            isIcon={false}
            isFilter={false}
            isback={true}
            // onFilterClick={handleFilter}
            onReset={handleReset}
            // onSearchChange={handleSearchChange}
            onAction1Click={handleAction1Click}
            handleBack={handleBack}
          />
          <DynamicTable
            columns={columnReport}
            rows={updatedData || []}
            isLoading={isLoading}
            onClick={handleSort}
            total={Math.ceil(data?.total / rowsPerPage)}
            dropMenuList={dropdown}
            currentPage={currentPage}
            handleAction={handleRowValue}
            handleMenu={handleMenuItem}
            // page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
        {/* <>
          <ReportHeader type={reportType} />
          <ReportTableGeneration reportType={reportType} dataList={reportDataList} year={selectedYear} />
        </> */}

      {/* {console.log(reportType, "reportType")} */}
      {modal && (
        <CommonModal
          setIsOpenPopUp={setModal}
          open={modal}
          isLoading={statusLoad}
          handleDelete={handleConfirmAction}
          item={{
            title: modalData.title,
            description: modalData.description,
            icon: modalData.icon,
          }}
        />
      )}
      {genModal && (
        <Modal open={genModal} onClose={handleClose} aria-labelledby='modal-title' aria-describedby='modal-description'>
          <div className='modal'>
            <div className='modal_container'>
              <div className='heading_container'>
                <p className='heading_values'>Select the Report type</p>
                <p className='heading_values_container'>
                  Choose the type of report you wish to generate from the options below. Each report type provides detailed insights tailored to your
                  needs.
                </p>
              </div>

              <div className='year_label'>Year</div>
              <div className='custom_select'>
                <select
                  className='year_selector'
                  value={selectedYear} // Set the current selected value
                  onChange={handleChange} // Handle changes
                >
                  <option value='' disabled>
                    Select Year
                  </option>
                  {YEAR_LIST.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <KeyboardArrowDownIcon className='dropdown_icon' />
              </div>

              <div className='year_label'>Report Type</div>
              <div className='custom_select'>
                <select className='year_selector' value={selectReportType} onChange={handleReportTypeChange}>
                  <option value='' disabled>
                    Select report type
                  </option>
                  {/* ['Report_1a_Large', 'Report_1a_Small', 'Report_1a_CCA', 'Report_2a_TotalSpend'] */}
                  <option value='Report_1a_Large'>Report_1a_Large</option>
                  <option value='Report_1a_Small'>Report_1a_Small</option>
                  <option value='Report_1a_CCA'>Report_1a_CCA</option>
                  <option value='Report_2a_TotalSpend'>Report_2a_TotalSpend</option>
                  {/* <option value='table5'>Table 5</option> */}
                </select>
                <KeyboardArrowDownIcon className='dropdown_icon' />
              </div>

              <div className='modal_buttons'>
                <button className='generate_btn' onClick={handleGenerate}>
                  Generate
                </button>
                <button className='cancel_btn' onClick={handleClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {downloadModal && (
        <Modal open={downloadModal} onClose={handleClose} aria-labelledby='modal-title' aria-describedby='modal-description'>
          <div className='modal'>
            <div className='modal_container'>
              <div className='heading_container'>
                <p className='heading_values'>Download Report</p>
                <p className='heading_values_container'>
                  By default, the data will contain all the data. To filter and customize it, type the name of the field in the textbox down below,
                  uncheck it and download your customized data.
                </p>
              </div>
              <div className='custom_select_data' onClick={handleMenuOpen}>
                {/* <select className='year_selector' value={selectReportType} onChange={handleReportTypeChange}>
                  <option value='' disabled>
                    Search or Select
                  </option>
                </select> */}
                <div style={{ padding: "16px 10px", color: "#B3B3B3" }}>Search or Select</div>
                <KeyboardArrowDownIcon className='dropdown_icon' />
              </div>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseDwn}
                sx={{}}
                PaperProps={{
                  sx: {
                    // marginTop: "10px",
                    // marginLeft: "-6px",
                    padding: "0 !important",
                    border: "1px solid #E6E6E6",
                    // borderTopLeftRadius: "8px",
                    // borderTopRightRadius: "8px",
                    // width:"100%"
                  },
                }}>
                <div style={{ width: "487px",maxHeight:"200px", padding: "16px"}} id="modal_body">
                  <div className='contentTextSection'>
                    <div>
                      <input
                        type='checkbox'
                        className='contentCheck'
                        checked={isSelectAllChecked}
                        onChange={(e) => handleSelectAll(e.target.checked)}
                      //  checked={selectedCategories.includes(itm)}
                      //  onChange={() => handleCategoryChange(itm)}
                      />
                    </div>
                    <div className='contentCheckLabel'>Select All</div>
                  </div>
                  {
                    finalDwn?.map((itm)=>{
                      return(
                        <div className='contentTextSection'>
                    <div>
                      <input
                        type='checkbox'
                        className='contentCheck'
                        checked={selectedCategories.includes(itm)}
                        onChange={() => handleCategoryChange(itm)}
                      //  checked={selectedCategories.includes(itm)}
                      //  onChange={() => handleCategoryChange(itm)}
                      />
                    </div>
                    <div className='contentCheckLabel'>{itm}</div>
                  </div>
                      )
                    })
                  }
                </div>
                {/* <div>Hello</div> */}
                {/* <Filter onClick={onFilterClick} onClose={handleClose} onReset={onReset} isYear={isYear} /> */}
                {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My Account</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem> */}
              </Menu>

              <div className='modal_buttons'>
                <button className='generate_btn' onClick={handleDownload}>
                  Download
                </button>
                <button className='cancel_btn' onClick={handleClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ReportTable;
