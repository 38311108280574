import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./_index.scss";
import AuthLayout from "../AuthLayout";
import { StyledLockIcon } from "../../../utils/icons";
import { matchPasswords, passwordValidation } from "../../../utils/validations";
import { useToast } from "../../ToastProvider";
import InputError from "../../../Components/InputError";
import CommonButton from "../../../Components/Button";
import { Color_Styling } from "../../../utils/constants";
import { useChangePasswordMutation } from "../../../features/Authentication/loginSlice";

const Index = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  console.log("TOKEN",token)
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changePassword, { isLoading, isError, isSuccess, data }] = useChangePasswordMutation();
  const [isMobile, setIsMobile] = useState(false);
  const { showSuccess, showError } = useToast();
  const [validation,setValidation] = useState({
    new:"",
    confirm:""
  })

  useEffect(() => {
    const checkMobileView = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => window.removeEventListener("resize", checkMobileView);
  }, []);

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleSetPassword = async(e) => {
    e.preventDefault();
        const newValidation = passwordValidation(values.password)
        const confirmValidation = matchPasswords(values.password,confirmPassword)
        if(newValidation.isValid && confirmValidation.isValid){
          setValidation({
            new:"",
            confirm:""
          })
          try{
            const payload = {
              token:token,
              newPassword:values.password,
              confirmPassword:confirmPassword
            }
            const result = await changePassword(payload).unwrap()
            console.log(result,"success")
            showSuccess({
              body:"Password updated successfully"
            })
            navigate("/successful")
          }catch(err){
            showError({
              body: err?.data?.message || err?.status   || "something went wrong"
            });
          }
        }
        else{
          setValidation({
            new:newValidation.message,
            confirm:confirmValidation.message
          })
        }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };

  return (
    <AuthLayout>
      <div className='contentContainerUpdatePass'>
        <div className='welcome_wrapper_update_pass'>
          <div className='heading_text_update_pass'>Create New Password</div>
          <div className='welcome_text_update_pass'>Hey, your password must be different from any of your previous passwords.</div>
        </div>
        <form>
          <div className='password_fields_wrapper'>
            <div className='value_fields_name_update01'>
              <label htmlFor='new_password'>New Password</label>
            </div>
            <div className='value_fields'>
              <div style={{ position: "relative", width: "100%" }}>
                <input
                  type={values.showPassword ? "text" : "password"}
                  onChange={handlePasswordChange("password")}
                  value={values.password}
                  className={`form_control_update_pass ${validation.new.length ? "error" : ""}`}
                  id='new_password'
                  placeholder={isMobile ? "New Password" : "|  Enter Password"}
                  style={{
                    paddingLeft: values.password ? "0.521vw" : "1.667vw",
                    width: "100%",
                    boxSizing: "border-box",
                    color: values.password ? "#000000" : "#B3B3B3",
                  }}
                />
                {values.password.length > 0 && (
                  <div className='show_button' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {values.showPassword ? "Hide" : "Show"}
                  </div>
                )}
                <StyledLockIcon passwordVisible={values.password} />
              </div>
              {validation.new.length ? <InputError text={validation.new}/> : <div></div>}
            </div>
            <div className='value_fields_name_update02'>
              <label htmlFor='confirm_password'>Confirm Password</label>
            </div>
            <div className='value_fields'>
              <div style={{ position: "relative", width: "100%" }}>
                <input
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                  type='password'
                  className={`form_control_update_pass ${validation.confirm.length ? "error" : ""}`}
                  id='new_password'
                  placeholder={isMobile ? "Confirm Password" : "|  Re-Enter Password"}
                  style={{
                    paddingLeft: confirmPassword ? "0.521vw" : "1.667vw",
                    width: "100%",
                    boxSizing: "border-box",
                    color: confirmPassword ? "#000000" : "#B3B3B3",
                  }}
                />
                <StyledLockIcon passwordVisible={confirmPassword} />
              </div>
              {validation.confirm.length ? <InputError text={validation.confirm}/> : <div></div>}
            </div>
          </div>
          <div className='forgot_pass_update_pass'>
            {/* <button
              type='submit'
              className='reset_pass_update_pass'
              onClick={handleSetPassword} // Function to handle form submission
            >
              Reset Password
            </button> */}
            <CommonButton
              text={"Reset Password"}
              color={Color_Styling.basic}
              customTextColor={Color_Styling.white}
              fullWidth
              inputProps={{ style: { padding: "0.833vw 0.521vw" } }}
              onClick={handleSetPassword}
              isLoading={isLoading}
              sx={{padding: "0.833vw 0.521vw"}}
            />
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default Index;
