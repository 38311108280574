import React, { useState } from 'react'
import "./common.scss"
import { AddIcon } from '../../../../utils/icons';
import CommonModal from "../../../../Components/Modals/index";
import Dropdown from '../../../../Components/Dropdown';

const CommonField = ({ rows,
  handleAddClick = () => { },
  hanldeInputAddition = () => { },
  handleConfirmAction = () => { },
  modal = false,
  setModalData,
  handleOpen = () => { },
  contClick = () => { } }) => {

  const renderField = (itm) => {
    switch (itm.fieldType) {
      case "alphabet":
        return (
          <div>
            <input type="text" placeholder={itm.place} disabled />
          </div>
        );
      case "numeric":
        return (
          <div>
            <input type="number" placeholder={itm.place} disabled />
          </div>
        );
      case "date":
        return (
          <div>
            <input type='text' placeholder='mm-dd-yyyy' disabled/>
          </div>
        );
      case "dropdown":
        return (
          <div>
            <Dropdown
              // label={"Field Type"}
              options={["1", "2"]}
              // value={input}
              // onChange={(e) => handleChange(e)}
              placeholder={itm.place}
              isInput={true}
              isDisabled={true}
            //  isError={validation.status.length}
            //  errorMessage = {validation.status}
            />
          </div>
        );
      case "boolean":
        return (
          <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
            <label style={{display:"flex",alignItems:"center",gap:"12px",color:"#ccc"}}>
              <input type="radio" id="yes" name="booleanField" value="yes" />
              Yes
            </label>
            <label style={{display:"flex",alignItems:"center",gap:"12px",color:"#ccc"}}>
              <input type="radio" id="no" name="booleanField" value="no" />
              No
            </label>
          </div>
        );
      default:
        return null; // Or some fallback UI if needed
    }
  };


  return (
    <div className='inpCommFieldSection'>
      <div className='contentForm'>
        {rows.map((itm, ind) => {
          console.log("ITM", itm)
          if (itm?.isClicked && !itm?.isRequired) {
            return (
              itm.visible &&
              <div key={ind} className="contentAfterClick" onClick={() => handleAddClick(itm, ind)}>
                <div className="contentClick">
                  <div>{itm.label}</div>
                  <div>
                    <input type="text" placeholder={itm.place} disabled />
                  </div>
                </div>
                <div className="delEdit" >
                  {/* <div style={{cursor:"pointer"}} onClick={()=>{contClick(itm,ind)}}>
                    <img
                      src="/assets/images/edit_group.svg"
                      alt="Edit icon"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </div> */}
                  <div
                    style={{
                      // borderTop: "1px solid #CCCCCC",
                      paddingTop: "4px",
                      color: "#EC221F",
                      cursor: "pointer"
                    }}
                    onClick={() => handleOpen(itm, ind)}
                  >
                    <img
                      src="/assets/images/red.svg"
                      alt="Delete icon"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              itm.visible &&
              <div
                key={ind}
                className="contentSection"
                onClick={() => handleAddClick(itm, ind)}
              >
                <div>{itm.label}</div>
                {renderField(itm)}
                {/* {
                  itm.fieldType === "alphabet" ? 
                  <div>
                  <input type="text" placeholder={itm.place} disabled />
                </div> : (itm.fieldType === "numeric" ? <div></div>)
                } */}
                {/* <LocalizationProvider dateAdapter={AdapterDayjs} sx={{}}>
      <div style={{border:"3px solid red"}}>
        <DatePicker
          label="Select Date"
          sx={{width:"100%"}}
          renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            width: '100%', // Ensures the input field takes up full width
            '.MuiInputLabel-root': {
              fontSize: '16px', // Adjust label font size
              color: '#003065', // Customize label color
              marginTop: '-8px', // Adjust the label position
              fontWeight: 'bold', // Add font weight for emphasis
            },
            '.MuiInputBase-root': {
              paddingTop: '12px', // Adjust the padding inside the input field
              paddingLeft: '10px', // Adjust the padding on the left of the input
              fontSize: '14px', // Set the font size inside the input
              borderRadius: '8px', // Make the input field corners rounded
              borderColor: '#003065', // Customize the border color
              '&:hover': {
                borderColor: '#003065', // Change border color on hover
              },
            },
            '.MuiFormLabel-asterisk': {
              color: '#EC221F', // Customize the asterisk color (for required fields)
            },
          }}
          variant="outlined"
        />
      )}
          // value={selectedDate}
          // onChange={(newValue) => setSelectedDate(newValue)}
          // renderInput={(params) => (
          //   // <TextField
          //   //   {...params}
          //   //   sx={{
          //   //     width: '100%', // Ensures the input field takes up full width
          //   //     '.MuiInputLabel-root': {
          //   //       fontSize: '16px', // Adjust label font size
          //   //       color: '#003065', // Customize label color
          //   //       marginTop: '-8px', // Adjust the label position
          //   //     },
          //   //     '.MuiInputBase-root': {
          //   //       paddingTop: '12px', // Adjust the padding inside the input field
          //   //       paddingLeft: '10px', // Adjust the padding on the left of the input
          //   //       fontSize: '14px', // Set the font size inside the input
          //   //       borderRadius: '8px', // Make the input field corners rounded
          //   //       borderColor: '#003065', // Customize the border color
          //   //       '&:hover': {
          //   //         borderColor: '#003065', // Change border color on hover
          //   //       },
          //   //     },
          //   //     '.MuiFormLabel-asterisk': {
          //   //       color: '#EC221F', // Customize the asterisk color (for required fields)
          //   //     },
          //   //   }}
          //   //   variant="outlined"
          //   // />
          // )}
        />
      </div>
                </LocalizationProvider> */}
                {/* <input 
                   type="date" 
                   id="date-picker" 
                   name="date-picker"
                   disabled
                   style={{padding:"8px",boxSizing:"border-box",border:"1px solid #ccc",borderRadius:"4px",color:"#ccc"}} 
                 /> */}

              </div>
            );
          }
        })}
      </div>
      <div className='addInpt' onClick={hanldeInputAddition}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><AddIcon sx={{ color: "#003065" }} /></div>
        <div style={{ color: "#003065" }}>Add Input Fields</div>
      </div>

      {modal && (
        <CommonModal
          setIsOpenPopUp={setModalData}
          open={modal}
          // isLoading={isLoading}
          handleDelete={handleConfirmAction}
          item={{
            title: "Delete input Field?",
            description: "Are you sure you want to delete this input field? This action is permanent and cannot be undone.",
            icon: true
          }}
        />
      )}

    </div>
  )
}

export default CommonField