import React, { useState } from 'react'
import "./common.scss"
import { AddIcon } from '../../../../utils/icons';
import CommonModal from "../../../../Components/Modals/index";
import Dropdown from '../../../../Components/Dropdown';
import InputError from '../../../../Components/InputError';

const CommonField = ({ rows,
  inputData = {}, setInputData, handleInputChange = () => { },isDisable = false }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [dateValue, setDateValue] = useState("");

  console.log("ROWS", rows)


  // const handleInputChange = (e,id) => {
  //   console.log(e,id,"IDD")
  //   const { name, value } = e.target;
  //   if(id === 1){
  //     setInputData({ 
  //       ...inputData,
  //       companyInformation:{...inputData.companyInformation,[name]: value}
  //        })
  //   }
  //   if(id===2){
  //     setInputData({ 
  //       ...inputData,
  //       mbeInformation:{...inputData.mbeInformation,[name]: value}
  //        })
  //   }
  //   // setInputData((prev) => ({
  //   //   ...prev,
  //   //   [name]: value,
  //   // }));

  // };

  const getValue = (name, id) => {
    switch (id) {
      case 1:
        return inputData.companyInformation[name] || "";
      case 2:
        return inputData.mbeInformation[name] || "";
      case 3:
        return inputData.diverseInformation[name] || "";
      case 4:
        return inputData.subcontractInformation[name] || "";
      case 5:
        return inputData.revenueInformation[name] || "";
      case 6:
        return inputData.workforceInformation[name] || "";
      // Add cases for other IDs as needed
      default:
        return "";
    }
  };


  console.log("SETINP", inputData)
  const renderField = (itm) => {
    switch (itm.fieldType) {
      case "alphabet":
        return (
          <div>
            <input
              type="text"
              placeholder={itm.place}
              style={{ border: itm.validation ? "1px solid red" : "" }}
              name={itm.name}
              disabled={isDisable}
              value={getValue(itm.name, itm.type)}
              onChange={(e) => handleInputChange(e, itm.type, itm)} // Use input handler
            />
            {itm.validation ? <InputError text={itm.validation} /> : <div></div>}
          </div>
        );
      case "numeric":
        return (
          <div>
            <input
              type="number"
              placeholder={itm.place}
              name={itm.name}
              disabled={isDisable}
              style={{ border: itm.validation ? "1px solid red" : "" }}
              value={getValue(itm.name, itm.type)}
              // value={inputData[itm.name] || ''}
              onChange={(e) => handleInputChange(e, itm.type, itm)} // Use input handler  
            />
            {itm.validation ? <InputError text={itm.validation} /> : <div></div>}
          </div>
        );
      case "date":
        return (
          <div style={{ position: "relative" }}>
            {/* <input type="date" placeholder={itm.place} disabled /> */}
            <input
              type="date"
              id="date-picker"
              name={itm.name}
              disabled={isDisable}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              value={getValue(itm.name, itm.type)}
              // value={inputData[itm.name] || ''}
              onChange={(e) => handleInputChange(e, itm.type, itm)} // Use input handler 
              style={{
                padding: "6px 8px", boxSizing: "border-box", border: itm.validation ? "1px solid red" : "", borderRadius: "4px", color: dateValue ? "#333" : "transparent", position: "relative",
                zIndex: 1,
                backgroundColor: "transparent",
              }}
            />
            {!dateValue && !isFocused && (
              <span
                style={{
                  position: "absolute",
                  left: "8px",
                  width: "100%",
                  boxSizing: "border-box",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "#ccc",
                  pointerEvents: "none",
                  fontSize: "14px",
                }}
              >
                mm-dd-yyyy
              </span>
            )}
          </div>
        );
      case "dropdown":
        return (
          <div>
            <Dropdown
              // label={"Field Type"}
              options={itm?.options}
              value={getValue(itm.name, itm.type)}
              name={itm.name}
              isDisabled={isDisable}
              // value={input}
              onChange={(e) => handleInputChange(e, itm.type, itm, true, itm.name)}
              placeholder={itm.place}
              isInput={true}
              isError={itm.validation}
              errorMessage={itm.validation}
            />
          </div>
        );
      case "boolean":
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "10px", padding: "4px 0" }}>
            <label style={{ display: "flex", alignItems: "center", gap: "12px", color: "#ccc" }}>
              <input type="radio" id="yes" name={itm.name} value="yes" onChange={(e) => handleInputChange(e, itm.type, itm)} />
              Yes
            </label>
            <label style={{ display: "flex", alignItems: "center", gap: "12px", color: "#ccc" }}>
              <input type="radio" id="no" name={itm.name} value="no" onChange={(e) => handleInputChange(e, itm.type, itm)} />
              No
            </label>
          </div>
        );
      default:
        return null; // Or some fallback UI if needed
    }
  };


  return (
    <div className='inpCommFieldSection23'>
      <div className='contentForm'>
        {rows.map((itm, ind) => {
          return (
            itm.visible &&
            <div
              key={ind}
              className="contentSection"
            // onClick={() => handleAddClick(itm, ind)}
            >
              <div>{itm.label}</div>
              {renderField(itm)}
            </div>
          );
        }
        )}
      </div>

    </div>
  )
}

export default CommonField