import React, { useState } from "react";
import "./_index.scss";
import { AddIcon, FilterListIcon, KeyboardBackspaceIcon, SearchIcon } from "../../utils/icons";
import { useNavigate } from "react-router-dom";
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, Menu, MenuItem } from "@mui/material"; // MUI Checkbox
import Filter from "../Filter";

const Header = ({
  title,
  description,
  searchValue,
  onSearchChange = () => {},
  onAction1Click = () => {},
  handleCancel = () => {},
  onFilterClick = () => {},
  onReset = () => {},
  btnText,
  searchText = "Search..",
  isIcon = true,
  isYear = false,
  isFilter = false,
  isSearchField = true,
  isSearchDisable = false,
  isCancel = false,
  btnLoading = false,
  isback = false,
  handleBack = () => {},
}) => {
  const navigate = useNavigate();
  const [isFilterVisible, setIsFilterVisible] = useState(false); // state for filter visibility
  const [selectedFilters, setSelectedFilters] = useState([]); // state for selected filters
  const [anchorEl, setAnchorEl] = useState(null);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setSelectedFilters((prevState) => (prevState.includes(value) ? prevState.filter((filter) => filter !== value) : [...prevState, value]));
  };

  return (
    <div className='headerContainer'>
      <div className='headerBack'>
        {isback && (
          <div onClick={handleBack} style={{ cursor: "pointer" }}>
            <KeyboardBackspaceIcon />
          </div>
        )}
        <div className='headerLeft'>
          <div className='headerTitle'>{title}</div>
          {description && <div className='headerDescription'>{description}</div>}
        </div>
      </div>
      <div className='headerRight'>
        {isSearchField && (
          <div className='headerSearch'>
            <div className='headerFlexCenter'>
              <SearchIcon style={{ height: "18px", width: "18px", color: "#CCCCCC" }} />
            </div>
            <div>
              <input
                type='text'
                placeholder={` | ${searchText}`}
                value={searchValue}
                className='headerInput'
                onChange={(e) => onSearchChange(e.target.value)}
                disabled={isSearchDisable}
              />
            </div>
          </div>
        )}
        {isFilter && (
          <div className='headerFilter'>
            <div className='headerFlexCenter'>
              <div onClick={handleFilterClick} style={{ padding: 0, display: "flex", flexDirection: "row" }}>
                <FilterListIcon
                  style={{
                    height: "18px",
                    width: "18px",
                    color: "#333333",
                    marginRight: "5px",
                    marginTop: "2px",
                  }}
                />
                <div className='headerFilterText'>Filter</div>
              </div>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{}}
                PaperProps={{
                  sx: {
                    marginTop: "10px",
                    marginLeft: "-6px",
                    padding: "0 !important",
                    border: "1px solid #E6E6E6",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  },
                }}>
                {/* <div>Hello</div> */}
                <Filter onClick={onFilterClick} onClose={handleClose} onReset={onReset} isYear={isYear} />
                {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My Account</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem> */}
              </Menu>
            </div>

            {isFilterVisible && (
              <div className='filterDropdown'>
                <FormGroup>
                  <div className='filter_option'>Filter Option</div>
                  <div className='sub_category_checklist'>Category</div>
                  <FormControlLabel
                    className='form_styles'
                    control={<Checkbox checked={selectedFilters.includes("filter1")} onChange={handleCheckboxChange} value='filter1' size='small' />}
                    label='Filter 1'
                  />
                  <FormControlLabel
                    className='form_styles'
                    control={<Checkbox checked={selectedFilters.includes("filter2")} onChange={handleCheckboxChange} value='filter2' size='small' />}
                    label='Filter 2'
                  />
                  <FormControlLabel
                    className='form_styles'
                    control={<Checkbox checked={selectedFilters.includes("filter3")} onChange={handleCheckboxChange} value='filter3' size='small' />}
                    label='Filter 3'
                  />
                  <hr className='hz_rule' />
                  <div className='sub_category_checklist'>Utility</div>
                  <FormControlLabel
                    className='form_styles'
                    control={<Checkbox checked={selectedFilters.includes("filter4")} onChange={handleCheckboxChange} value='filter4' size='small' />}
                    label='Filter 4'
                  />
                  <FormControlLabel
                    className='form_styles'
                    control={<Checkbox checked={selectedFilters.includes("filter5")} onChange={handleCheckboxChange} value='filter5' size='small' />}
                    label='Filter 5'
                  />
                  <FormControlLabel
                    className='form_styles'
                    control={<Checkbox checked={selectedFilters.includes("filter6")} onChange={handleCheckboxChange} value='filter6' size='small' />}
                    label='Filter 6'
                  />
                  <FormControlLabel
                    className='form_styles'
                    control={<Checkbox checked={selectedFilters.includes("filter7")} onChange={handleCheckboxChange} value='filter7' size='small' />}
                    label='Filter 7'
                  />
                  <FormControlLabel
                    className='form_styles'
                    control={<Checkbox checked={selectedFilters.includes("filter8")} onChange={handleCheckboxChange} value='filter8' size='small' />}
                    label='Filter 8'
                  />
                  <FormControlLabel
                    className='form_styles'
                    control={<Checkbox checked={selectedFilters.includes("filter9")} onChange={handleCheckboxChange} value='filter9' size='small' />}
                    label='Filter 9'
                  />
                  <div className='filter_apply_reset_area'>
                    <button className='filter_reset_btn'>Reset</button>
                    <button className='filter_apply_btn'>Apply</button>
                  </div>
                </FormGroup>
              </div>
            )}
          </div>
        )}
        {isCancel && (
          <div className='cancelClass' onClick={handleCancel}>
            <div className='cancelText'>Cancel</div>
          </div>
        )}
        {!btnLoading ? (
          <div className={title !== "Report Access" ? "headerButton" : ""} onClick={onAction1Click}>
            {isIcon && (
              <div className='headerFlexCenter'>
                <AddIcon style={{ height: "18px", width: "18px" }} />
              </div>
            )}
            <div>{btnText}</div>
          </div>
        ) : (
          <div className='headerButtonData'>
            <CircularProgress size={14} sx={{ color: "#ffffff" }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
