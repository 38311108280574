import React, { useState, useEffect } from "react";
import "./_index.scss";
import { useNavigate,createSearchParams } from "react-router-dom";
import AuthLayout from "../AuthLayout";
import { StyledMailIcon } from "../../../utils/icons";
import { Color_Styling } from "../../../utils/constants";
import CommonButton from "../../../Components/Button";
import { useForgotPasswordMutation } from "../../../features/Authentication/loginSlice";
import { emailValidation } from "../../../utils/validations";
import InputError from "../../../Components/InputError";
import { useToast } from "../../ToastProvider";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const { showSuccess, showError } = useToast();
  const [forgotPassword, { isLoading, isError, isSuccess, data }] = useForgotPasswordMutation();
  const [validation, setValidation] = useState("")
  useEffect(() => {
    const checkMobileView = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => window.removeEventListener("resize", checkMobileView);
  }, []);
  const navigate = useNavigate();

  const isEmailValid = () => {
    if (validation.length) {
      const emailData = emailValidation(email)
      if (emailData.isValid) {
        setValidation("")
      }
    }
  }
  const handleSendEmail = async () => {
    const emailData = emailValidation(email)
    if (emailData.isValid) {
      try {
        const result = await forgotPassword(email).unwrap();
        showSuccess({
          body: result.message
        })
        navigate({
          pathname: '/email-sent',
          search: `?${createSearchParams({ email:email })}`
        })

      } catch (err) {
        if (err?.data?.message === "Unauthorized: SUPER_ADMIN cannot reset password") {
          navigate("/access-denied")
        }
        else{
          showError({
            title: 'Error!',
            body: err?.data?.message || err?.status   || "something went wrong"
          });
        }
      }
    }
    else {
      setValidation(emailData.message)
    }
    // navigate("/email-sent");
  };
  const handleBackToLogin = () => {
    navigate("/login");
  };

  return (
    <AuthLayout>
      <div className='contentContainerResetPass'>
        <div className='reset_pass_welcome_conatiner'>
          <div className='heading_text_reset_pass'>Reset Password</div>
          <div className='welcome_text_reset_pass'>
            Hello! Please enter your registered email address.
            <br /> We’ll send you a link to reset your password.
          </div>
        </div>
        <form onSubmit={handleSendEmail} className='reset_form'>
          <div className='reset_field_wrapper'>
            <div className='reset_fields_name'>
              <label htmlFor='email'>Email Address</label>
            </div>
            <div className='reset_fields_value'>
              <div style={{ position: "relative", width: "100%" }}>
                <input
                  onChange={(e) => {
                    isEmailValid()
                    setEmail(e.target.value);
                  }}
                  value={email}
                  type='email'
                  // className='form_control_for_reset_values'
                  className={`form_control_for_reset_values ${validation.length ? "error" : ""}`}
                  id='email'
                  placeholder={isMobile ? "Email Address" : "|  Email Address"}
                  style={{
                    paddingLeft: email ? "0.521vw" : "1.667vw",
                    width: "100%",
                    boxSizing: "border-box",
                    color: email ? "#000000" : "#B3B3B3",
                  }}
                />
                <StyledMailIcon emailVisible={email} />
              </div>
              {validation.length ? <InputError text={validation} /> : <div></div>}
            </div>
          </div>

          <div className='reset_button'>
            {/* <button type='submit' className='reset_pass_button'>
              Send Email
            </button> */}
            <CommonButton
              text={"Send Email"}
              color={Color_Styling.basic}
              customTextColor={Color_Styling.white}
              fullWidth
              // inputProps={{ style: { padding: "0.833vw 0.521vw" } }}
              onClick={handleSendEmail}
              isLoading={isLoading}
            // sx={{padding: "0.833vw 0.521vw"}}
            />
          </div>
        </form>

        <div className='back_to_login'>
          <a className='logo_page_nav' onClick={handleBackToLogin}>
            Back to login
          </a>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
