import React from 'react'
import "./adddata.scss"
import InputTemplate from '../InputTemplate'

const AddData = () => {
  return (
    <div className='addContainer'>
       
       <div>
        <InputTemplate/>
       </div>
    </div>
  )
}

export default AddData