import React, { useState } from "react";
import "./_index.scss";

// ---------------------Modal import---------------------------------
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, CircularProgress, TextField } from "@mui/material";
import { useOldpaswordUpdateMutation, useProfileUpdateMutation } from "../../../features/Authentication/loginSlice";
import { useToast } from "../../ToastProvider";
import { matchPasswords, nameValidation, passwordValidation } from "../../../utils/validations";
import InputError from "../../../Components/InputError";
// ------------------------------------------------------------------
const EndProfile = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const { showSuccess, showError } = useToast();
  const user = JSON.parse(localStorage.getItem('access_token'))
  const nameList = localStorage.getItem('name').split(" ")
  const dataID= `${localStorage.getItem('userid')}`
  const [profileUpdate, { isLoading, isSuccess, isError }] = useProfileUpdateMutation();
  const [oldpaswordUpdate, { isLoading : fetchLoading }] = useOldpaswordUpdateMutation();
  const [validation,setValidation] = useState({
      old:"",
      new:"",
      confirm:""
   })
  const [validate,setValidate] = useState({
    firstName:"",
    lastName:""
  })
  console.log("M", nameList)
  const [data, setData] = useState({
    firstName: nameList[0] || "",
    lastName: nameList[1] || "",
    email: user.email,
    name:""
  })
  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const handleClose = () => setOpen(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const firstVal = nameValidation(data.firstName,"Firstname")
    const lastVal = nameValidation(data.lastName,"Lastname")
    if(firstVal.isValid && lastVal.isValid){
      setValidate({
        firstName:"",
        lastName:""
      })
      try {
        const payload = {
          firstName:data.firstName,
          lastName:data.lastName,
          name:data.name
        }
        const result = await profileUpdate({id:dataID,payload:payload}); // Call mutation
        console.log(data.firstName, data.lastName,">>>>>>>");
        localStorage.setItem('name',`${data.firstName} ${data.lastName}`)
        showSuccess({
          body:"Profile Updated"
        })
        window.location.reload()
        console.log('Profile updated:', result);
      } catch (err) {
        showError({
          body: err?.data?.message || err?.status   || "something went wrong"
        });
      }
    }
    else{
      setValidate({
        firstName:firstVal.message,
        lastName:lastVal.message
      })
    }

  };

  const handleChangePassword = async (e)=>{
    e.preventDefault()
    const oldValidation = nameValidation(passwords.oldPassword,"Old Password")
    const newValidation = passwordValidation(passwords.newPassword)
    const confirmValidation = matchPasswords(passwords.newPassword,passwords.confirmNewPassword)
    if(oldValidation.isValid && newValidation.isValid && confirmValidation.isValid){
      setValidation({
        old:"",
        new:"",
        confirm:""
      })
      try{
        const payload = {
          oldPasswrod:passwords.oldPassword,
          newPassword:passwords.newPassword,
          // confirmPassword:confirmPassword
        }
        const result = await oldpaswordUpdate({id : localStorage.getItem("userid"),payload}).unwrap()
        console.log(result,"success")
        showSuccess({
          body:"Password updated successfully"
        })
        // navigate("/successful")
      }catch(err){
        showError({
          body: err?.data?.message || err?.status   || "something went wrong"
        });
      }finally{
        setOpen(false)
      }
    }
    else{
      setValidation({
        old:oldValidation.message,
        new:newValidation.message,
        confirm:confirmValidation.message
      })
    }

  }
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 498,
    // height: "22.708vw",
    BorderBottom: "8px solid #FDB81E",
    bgcolor: "background.paper",
    borderBottom: "8px solid #FDB81E", // Border bottom style
    borderRadius: "8px",
    p: 3,
  };

  return (
    <div className='user_profile'>
      <div className='head_container_01'>
        <div>
          <h2 className='title_01'>Profile</h2>
          <p className='subtitle_01'>Here you can manage the profile details </p>
        </div>

        <div className='form_actions_01' style={{display:"flex"}}>
          <button type='button_01' className='btn_change_pass' onClick={handleOpen}>
            Change Password
          </button>
          <Modal open={open} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
            <Box sx={style}>
              <div>
                <p className='heading_change_pass' style={{marginTop:"4px"}}>Enter old password</p>
                <TextField
                  id='oldPassword'
                  placeholder='Enter old password'
                  type='text'
                  fullWidth
                  margin='normal'
                  sx={{ marginTop: "5px"}}
                  // border: validation?.old?.length ? "1px solid #FF4848" : "1px solid #ccc" 
                  value={passwords.oldPassword}
                  onChange={handleChange}
                />
               {validation.old.length ? <InputError text={validation.old}/> : <div></div>}
              </div>
              <div>
                <p className='heading_change_pass' style={{marginTop:"4px"}}>Enter new password</p>
                <TextField
                  id='newPassword'
                  placeholder='Enter new password'
                  type='text'
                  fullWidth
                  margin='normal'
                  sx={{ marginTop: "5px" }}
                  // ,border: validation?.new?.length ? "1px solid #FF4848" : "1px solid #ccc"
                  value={passwords.newPassword}
                  onChange={handleChange}
                />
               {validation.new.length ? <InputError text={validation.new}/> : <div></div>}
              </div>
              <div>
                <p className='heading_change_pass'  style={{marginTop:"4px"}}>Confirm new password</p>
                <TextField
                  id='confirmNewPassword'
                  placeholder='Confirm new password'
                  type='text'
                  fullWidth
                  margin='normal'
                  sx={{ marginTop: "5px"}}
                  // ,border: validation?.confirm?.length ? "1px solid #FF4848" : "1px solid #ccc" 
                  value={passwords.confirmNewPassword}
                  onChange={handleChange}
                />
                {validation.confirm.length ? <InputError text={validation.confirm}/> : <div></div>}
              </div>
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                  mt: 2,
                }}>
                {fetchLoading ? 
          <div className='headerButtonData'>
                          <CircularProgress size={14} sx={{ color: "#ffffff" }} />
                        </div> :
                <Button
                  variant='contained'
                  style={{
                    backgroundColor: "#003065",
                    textTransform: "none",
                  }}
                  onClick={handleChangePassword}
                  >
                  Change Password
                </Button>
                }
                <Button
                  variant='outlined'
                  style={{
                    backgroundColor: "white",
                    color: "#333333",
                    border: "1px solid #333333",
                    textTransform: "none",
                  }}
                  onClick={handleClose}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
          {isLoading ? 
          <div className='headerButtonData'>
                          <CircularProgress size={14} sx={{ color: "#ffffff" }} />
                        </div> :
          <button type='submit' className='btn_save' onClick={handleSubmit}>
            Save
          </button>
          }
        </div>
      </div>
      {/* 
      style={{
                height: "66.67px",
                width: "66.67px",
                color: "#003065",
              }} */}
      <div className='profile_container' id='modal_body'>

        <div className='pfp'>
          <div className='pfp_icon'>
            <img src={`${localStorage.getItem('logo')}`} alt='Uploaded' className='uploaded_image' />
          </div>
        </div>

        <div className='pfp_details_container'>
          <div className='inner_pfp_details_container'>
              <div className='input_pfp_details'>
                <label>First Name</label>
                <input className='input_grp_val' name="firstName" type='text' disabled={user.role === 1} placeholder='Enter First Name' value={data.firstName}
                  onChange={handleInputChange} />
                 {validate.firstName.length ? <InputError text={validate.firstName}/> : <div></div>}
              </div>
              <div className='input_pfp_details'>
                <label>Last Name</label>
                <input className='input_grp_val' name="lastName" type='text' disabled={user.role === 1} placeholder='Enter Last Name' value={data.lastName}
                  onChange={handleInputChange}
                />
            {validate.lastName.length ? <InputError text={validate.lastName}/> : <div></div>}
              </div>
          </div>
        </div>

        <div className='pfp_details_container'>
          <div className='inner_pfp_details_container_01'>
            <div className='input_pfp_details'>
              <label>Email Address</label>
              <input className='input_grp_val' type='text' disabled={true} placeholder='Enter email address' value={data.email} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndProfile;
