import React, { useState } from "react";
import { useFetchQuery } from "../../../features/Group/groupSlice";
import Header from "../../../Components/Header";
import DynamicTable from "../../../Components/Table";
import { HttpsIcon } from "./../../../utils/icons/index";
import ReportIcon from "../../Sidebar/ReportIcon";
import { createSearchParams, useNavigate } from "react-router-dom";

const Report = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState({search: searchValue, page: currentPage, limit: 10}); 
  const [rowData, setRowData] = useState({});
    const [rowsPerPage, setRowsPerPage] = useState(10);
  // const filter = { search: searchValue, page: currentPage, limit: 10 };
  const { data = { data: [] }, error, isLoading, refetch } = useFetchQuery(filters);
  console.log("DATA", data,rowsPerPage,currentPage);

  const navigate = useNavigate();

  const actionArr = [
    {
      name: "View Group",
      icon: <ReportIcon />,
      id: 1,
    },
    // {
    //   name: "Lock Report",
    //   icon: <HttpsIcon />,
    //   id: 2,
    // },
  ];

  const handleMenuOptions = (rowData) => {
    setRowData(rowData);
    console.log("handleMenuOptions is clicked!", rowData);
    // if(rowData?.groupID)
  };

  const handleMenuItem = (val) => {
    if (val.id === 1) {
      navigate({
        pathname: "/super/report/list",
        search: `?${createSearchParams({ id: rowData?.id, grp: rowData?.groupId, grpname: rowData?.name })}`,
      });
    }
    console.log("handleMenuItem is clicked!");
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("vv", parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    const newLimit = parseInt(event.target.value, 10);
    setFilters((prevFilters) => ({
      ...prevFilters,
      limit: newLimit,
      page: 1, // Reset to the first page whenever rows per page changes
    }));
    setCurrentPage(1); // Reset to the first page whenever rows per page changes
  };

  const handlePageChange = (page) => {
    setFilters({
      ...filters,
      page: page,
    });
    setCurrentPage(page);
  };

  const fields = [
    { id: "groupId", label: "Group ID", minWidth: 150, sortable: true },
    { id: "name", label: "Group Name", minWidth: 200, sortable: true },
    { id: "subAdminEmails", label: "Group Sub Admin", minWidth: 80, sortable: true },
    { id: "isActive", label: "Status", minWidth: 200, sortable: false },
    { id: "actions", label: "Actions", minWidth: 100, sortable: false },
  ];

  return (
    <div className='report_container'>
      <Header
        title={"Report Access"}
        description='Here, you can manage all your input data.'
        isSearchField={false}
        isCancel={false}
        // btnText={"Add Data"}
        // btnLoading={false}
        isIcon={false}
        // onAction1Click={handleAdd}
        // handleCancel={handleCancelClick}
      />
      <DynamicTable
        columns={fields}
        rows={data?.data || []}
        isLoading={isLoading}
        // onClick={handleSort}
        total={Math.ceil(data?.total / 10)}
        dropMenuList={actionArr}
        currentPage={currentPage}
        handleAction={handleMenuOptions}
        handleMenu={handleMenuItem}
        // // page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default Report;

