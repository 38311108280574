import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { tableHeaders } from "./Table1A";
import { tableHeaders2A } from "./Table2A";
import { tableHeaders3A } from "./Table3A";
import { tableHeaders4A } from "./Table4A";
import { tableHeaders5A } from "./Table5A";
import { rows } from "./Table1A";
import { rows2A } from "./Table2A";
import { rows3A } from "./Table3A";
import { rows4A } from "./Table4A";
import { rows5A } from "./Table5A";
import { current } from "@reduxjs/toolkit";

export default function ReportTable({ reportType,dataList={},year="" }) {
  const [data, setData] = React.useState({
    currentHeaders: [],
    currentRows: [],
  });

  const tableHeadReport1 = [
    { label: "" },
    { label: `${year} Total Procurement` },
    { label: "MBE" },
    { label: "WBE" },
    { label: "DVBE" },
    { label: "LGBTBE" },
    { label: "PDBE" },
    { label: "Other 8(a)*" },
    { label: "Total Supplier Diversity" },
  
    // createData("", "2023 Total Procurement", "MBE", "WBE", "DVBE", "LGBTBE", "PDBE", "Other 8(a)*", "Total Supplier Diversity")
  ];
  console.log(reportType, "reportType",dataList);
  // ['Report_1a_Large', 'Report_1a_Small', 'Report_1a_CCA', 'Report_2a_TotalSpend']
  React.useEffect(() => {
    switch (reportType) {
      case "Report_1a_Large":
        return setData({ currentHeaders: tableHeadReport1, currentRows: rows });
      case "Report_1a_Small":
        return setData({ currentHeaders: tableHeaders, currentRows: rows });
        // return setData({ currentHeaders: tableHeaders2A, currentRows: rows2A });
      case "Report_1a_CCA":
        return setData({ currentHeaders: tableHeaders, currentRows: rows });
        // return setData({ currentHeaders: tableHeaders3A, currentRows: rows3A });
      // case "table4":
      //   return setData({ currentHeaders: tableHeaders4A, currentRows: rows4A });
      // case "table5":
      //   return setData({ currentHeaders: tableHeaders5A, currentRows: rows5A });
      default:
        return setData({ currentHeaders: [], currentRows: [] });
    }
  }, [reportType]);
  return (
    <div style={{height:"calc(100vh - 225px)", overflow:'auto'}} id="modal_body"> 
    {dataList?.reportData?.map((itm,idx)=>{
       return(
        <TableContainer component={Paper} sx={{marginTop:idx > 0 ? "24px" : "0px"}}>
      {console.log(data, "data")}
      <Table aria-label='customized table'>
        <TableHead>
          <TableRow>
            {data?.currentHeaders.map((header, id) => (
              <TableCell
                key={id}
                align='center'
                style={{
                  background: id === 0 ? "#fff" : "#E9F8FF",
                }}>
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {data?.currentRows.map((row, id) => ( */}
            <TableRow>
             <TableCell>{itm?.CompanyName}</TableCell>
             <TableCell></TableCell>
             </TableRow>
              {/* {Object.entries(row).map(([key, value], index) => (
                <TableCell
                  key={index}
                  align='center'
                  style={{
                    fontWeight: key === "emptyCell" ? "700" : "400",
                  }}>
                  {value || ""}
                </TableCell>
              ))} */}
            
          {/* ))} */}
        </TableBody>
      </Table>
    </TableContainer>
       )
    })
    }
    </div>
  );
}
