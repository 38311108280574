import React from 'react'
import "./input.scss"
import InputTemplate from './InputTemplate'

const InputSub = () => {
  return (
<div className='inpContainer'>
      <div className='inpLeftContainer'>
        <InputTemplate/>
      </div>
    </div>
  )
}

export default InputSub