import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../../Components/Button";
import { Color_Styling } from "../../../utils/constants";
import { StyledLockIcon, StyledMailIcon } from "../../../utils/icons";
import { useToast } from "../../ToastProvider/index";
import AuthLayout from "../AuthLayout";
import "./_index.scss";
import { emailValidation, passwordValidation } from "../../../utils/validations";
import { useLoginMutation } from "../../../features/Authentication/loginSlice";
import { useDispatch } from "react-redux";
import { setAccessToken } from "../../../features/auth/authSlice";
import InputError from "../../../Components/InputError";
const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const { showSuccess, showError } = useToast();
  const [login, { isLoading, isError, isSuccess, data }] = useLoginMutation();
  const [validation, setValidation] = useState({
    email: "",
    password: "",
  });
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkMobileView = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => window.removeEventListener("resize", checkMobileView);
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    const emailData = emailValidation(email);
    const passwordData = passwordValidation(values.password);
    if (emailData.isValid && passwordData.isValid) {
      try {
        setValidation({
          email: "",
          password: "",
        });
        const result = await login({ email, password: values.password }).unwrap();
        console.log("Result", result);
        const logo = result.logo;
        const decodedToken = jwtDecode(result.access_token);

        const user = {
          email: decodedToken.email,
          role: decodedToken.role,
        };

        // Convert the object to a JSON string
        const userString = JSON.stringify(user);

        // Store it in localStorage
        localStorage.setItem("access_token", userString);
        localStorage.setItem("logo", logo);
        if (result.groupId) {
          localStorage.setItem("group", result.groupId);
        }
        if (result.companyId) {
          localStorage.setItem("id", result.companyId);
        }
        if (result.name) {
          localStorage.setItem("name", result.name);
        }
        if(result.id){
          localStorage.setItem('userid',result.id)
         }
        if (result.groupName) {
          localStorage.setItem("groupName", result.groupName);
        }

        console.log("userSt", user);
        if (user.role == "1") {
          showSuccess({
            body: "Welcome to Super Admin",
          });
          navigate("/super/dashboard");
        } else if (user.role == "2") {
          showSuccess({
            body: "Welcome to Sub Admin",
          });
          navigate("/sub/dashboard");
        } else {
          showSuccess({
            body: "Welcome to User",
          });
          navigate("/end/dashboard");
        }
      } catch (err) {
        console.error("Login failed:", err);
        showError({
          title: "Error!",
          body: err?.data?.message || err?.status || "something went wrong",
        });
      }
    } else {
      setValidation({
        email: emailData.message,
        password: passwordData.message,
      });
    }
  };

  const handleResetPassword = () => {
    navigate("/reset-password");
  };

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues((prevValues) => ({
      ...prevValues,
      showPassword: !prevValues.showPassword,
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };

  return (
    <AuthLayout>
      <div className='contentContainerLogin'>
        <div className='welcome_wrapper'>
          <div className='heading_text'>Log In to Your Account</div>
          <div className='welcome_text'>Hey, Enter your details to get sign in to your account.</div>
        </div>
        <form onSubmit={handleLogin}>
          <div className='login_fields_wrapper'>
            <div className='value_fields_name_01'>
              <label htmlFor='email'>Email Address</label>
            </div>
            <div className='value_fields'>
              <div style={{ position: "relative", width: "100%" }}>
                <input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  type='email'
                  className={`form_control_for_login_values ${validation.email.length ? "error" : ""}`}
                  id='email'
                  placeholder={isMobile ? "Email Address" : "|  Email Address"}
                  style={{
                    paddingLeft: email ? "0.521vw" : "1.667vw",
                    width: "100%",
                    boxSizing: "border-box",
                    color: email ? "#000000" : "#B3B3B3",
                  }}
                />
                <StyledMailIcon emailVisible={email} />
              </div>
              {validation.email.length ? <InputError text={validation.email} /> : <div></div>}
            </div>

            <div className='value_fields_name_02'>
              <label htmlFor='password'>Password</label>
            </div>
            <div className='value_fields'>
              <div style={{ position: "relative", width: "100%" }}>
                <input
                  type={values.showPassword ? "text" : "password"}
                  onChange={handlePasswordChange("password")}
                  value={values.password}
                  className={`form_control_for_login_values ${validation.password.length ? "error" : ""}`}
                  placeholder={isMobile ? "Password" : "|  Password"}
                  style={{
                    paddingLeft: values.password ? "0.521vw" : "1.667vw",
                    width: "100%",
                    boxSizing: "border-box",
                    color: values.password ? "#000000" : "#B3B3B3",
                  }}
                />
                {values.password.length > 0 && (
                  <div className='show_button' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {values.showPassword ? "Hide" : "Show"}
                  </div>
                )}
                <StyledLockIcon passwordVisible={values.password} />
              </div>
              {validation.password.length ? <InputError text={validation.password} /> : <div></div>}
            </div>
          </div>
          <div className='login_container'>
            <CommonButton
              text={"Log In"}
              color={Color_Styling.basic}
              customTextColor={Color_Styling.white}
              fullWidth
              inputProps={{ style: { padding: "0.833vw 0.521vw" } }}
              onClick={handleLogin}
              isLoading={isLoading}
              sx={{ padding: "0.833vw 0.521vw" }}
            />
          </div>
        </form>
        <div className='forgot_pass'>
          Forgot your password?&nbsp;{" "}
          <a className='reset_pass' onClick={handleResetPassword}>
            Reset Password
          </a>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;
