import ReportIcon from "../../screens/Sidebar/ReportIcon";
import { AnalyticsIcon, DeleteOutlineOutlinedIcon, EditOutlinedIcon, PeopleAltIcon, PieChartIcon, ToggleOffOutlinedIcon } from "../icons";

export const Color_Styling = {
  basic: "#003065",
  white: "#ffffff",
  dark: "#333333",
};

export const BASE_DOMAIN = "https://devapiecg.resourcifi.tech/"

export const SIDEBAR_DATA = [
  {
    id: 1,
    text: "Dashboard",
    icon: PieChartIcon,
    path: "/super/dashboard",
  },
  {
    id: 2,
    text: "Group Management",
    icon: PeopleAltIcon,
    path: "/super/group",
  },
  {
    id: 3,
    text: "Input Data Management",
    icon: AnalyticsIcon,
    path: "/super/input",
  },
  {
    id: 4,
    text: "Report Access",
    icon: ReportIcon,
    path: "/super/report",
  },
];
export const SIDEBAR_DATA_SUB = [
  {
    id: 1,
    text: "Dashboard",
    icon: PieChartIcon,
    path: "/sub/dashboard",
  },
  {
    id: 2,
    text: "Company Management",
    icon: PeopleAltIcon,
    path: "/sub/company",
  },
  {
    id: 3,
    text: "Input Data Management",
    icon: AnalyticsIcon,
    path: "/sub/input",
  },
  {
    id: 4,
    text: "Report Access",
    icon: ReportIcon,
    path: "/sub/report",
  },
];
export const SIDEBAR_DATA_END = [
  {
    id: 1,
    text: "Dashboard",
    icon: PieChartIcon,
    path: "/end/dashboard",
  },
  {
    id: 2,
    text: "Data Management",
    icon: PeopleAltIcon,
    path: "/end/data",
  },
];

export const columns = [
  { id: "companyId", label: "Company ID", minWidth: 150, sortable: true },
  { id: "name", label: "Company Name", minWidth: 200, sortable: true },
  { id: "category", label: "Category", minWidth: 80, sortable: true },
  { id: "utilityType", label: "Utility Type", minWidth: 200, sortable: true },
  { id: "companySize", label: "Company Size", minWidth: 200, sortable: true },
  { id: "status", label: "Status", minWidth: 100, sortable: false },
  { id: "actions", label: "Actions", minWidth: 100, sortable: false },
];
export const columnReport = [
  { id: "compId", label: "Company ID", minWidth: 150, sortable: true },
  { id: "company_name", label: "Company Name", minWidth: 200, sortable: true },
  { id: "category", label: "Category", minWidth: 80, sortable: true },
  { id: "type_of_utility", label: "Utility Type", minWidth: 200, sortable: true },
  { id: "company_size", label: "Company Size", minWidth: 200, sortable: true },
  { id: "year", label: "Year", minWidth: 200, sortable: true },
  { id: "status", label: "Status", minWidth: 100, sortable: false },
  { id: "actions", label: "Actions", minWidth: 100, sortable: false },
];

export const rows = [
  { id: 1, name: "Alice", age: 25, email: "alice@example.com", status: "Active" },
  { id: 2, name: "Bob", age: 30, email: "bob@example.com", status: "Inactive" },
  { id: 3, name: "Charlie", age: 35, email: "charlie@example.com", status: "Active" },
  { id: 4, name: "David", age: 40, email: "david@example.com", status: "Inactive" },
  { id: 5, name: "Eve", age: 22, email: "eve@example.com", status: "Active" },
  { id: 1, name: "Alice", age: 25, email: "alice@example.com", status: "Active" },
  { id: 2, name: "Bob", age: 30, email: "bob@example.com", status: "Inactive" },
  { id: 3, name: "Charlie", age: 35, email: "charlie@example.com", status: "Active" },
  { id: 4, name: "David", age: 40, email: "david@example.com", status: "Inactive" },
  { id: 5, name: "Eve", age: 22, email: "eve@example.com", status: "Active" },
  { id: 1, name: "Alice", age: 25, email: "alice@example.com", status: "Active" },
  { id: 2, name: "Bob", age: 30, email: "bob@example.com", status: "Inactive" },
  { id: 3, name: "Charlie", age: 35, email: "charlie@example.com", status: "Active" },
  { id: 4, name: "David", age: 40, email: "david@example.com", status: "Inactive" },
  { id: 5, name: "Eve", age: 22, email: "eve@example.com", status: "Active" },
];

export const CATEGORY = ["Utility","CCA","ESP"]
export const YEAR_LIST = [
  "2001", "2002", "2003", "2004", "2005", "2006", "2007", "2008", "2009", 
  "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", 
  "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", 
  "2028", "2029", "2030", "2031", "2032", "2033", "2034", "2035", "2036", 
  "2037", "2038", "2039", "2040", "2041", "2042", "2043", "2044", "2045", 
  "2046", "2047", "2048", "2049", "2050"
];
export const UTILITY = ["Electric","Telecom","Gas & electric","Water","Gas"]
export const categories = [
  {value:"Utility", label:"Utility"},
  {value:"CCA", label:"CCA"},
  {value:"ESP", label:"ESP"},
  {value:"other", label:"Other"},
];

export const years = [
  {value: "2001", label: "2001"},
  {value: "2002", label: "2002"},
  {value: "2003", label: "2003"},
  {value: "2004", label: "2004"},
  {value: "2005", label: "2005"},
  {value: "2006", label: "2006"},
  {value: "2007", label: "2007"},
  {value: "2008", label: "2008"},
  {value: "2009", label: "2009"},
  {value: "2010", label: "2010"},
  {value: "2011", label: "2011"},
  {value: "2012", label: "2012"},
  {value: "2013", label: "2013"},
  {value: "2014", label: "2014"},
  {value: "2015", label: "2015"},
  {value: "2016", label: "2016"},
  {value: "2017", label: "2017"},
  {value: "2018", label: "2018"},
  {value: "2019", label: "2019"},
  {value: "2020", label: "2020"},
  {value: "2021", label: "2021"},
  {value: "2022", label: "2022"},
  {value: "2023", label: "2023"},
  {value: "2024", label: "2024"},
  {value: "2025", label: "2025"},
  {value: "2026", label: "2026"},
  {value: "2027", label: "2027"},
  {value: "2028", label: "2028"},
  {value: "2029", label: "2029"},
  {value: "2030", label: "2030"},
  {value: "2031", label: "2031"},
  {value: "2032", label: "2032"},
  {value: "2033", label: "2033"},
  {value: "2034", label: "2034"},
  {value: "2035", label: "2035"},
  {value: "2036", label: "2036"},
  {value: "2037", label: "2037"},
  {value: "2038", label: "2038"},
  {value: "2039", label: "2039"},
  {value: "2040", label: "2040"},
  {value: "2041", label: "2041"},
  {value: "2042", label: "2042"},
  {value: "2043", label: "2043"},
  {value: "2044", label: "2044"},
  {value: "2045", label: "2045"},
  {value: "2046", label: "2046"},
  {value: "2047", label: "2047"},
  {value: "2048", label: "2048"},
  {value: "2049", label: "2049"},
  {value: "2050", label: "2050"}
];

export const CompanySize = [
  {value:"Large", label:"Large"},
  {value:"Small", label:"Small"},
  {value:"other", label:"Other"},

]
export const Utilities = [
  {value:"Gas",label:"Gas"},
  {value:"Electric",label:"Electric"},
  {value:"GasandElectric",label:"Gas & Electric"},
  {value:"Telecom",label:"Telecom"},
  {value:"Water",label:"Water"},
  {value:"other", label:"Other"},
]

export const Status = [
  {value:"Active", label:"Active"},
  {value:"Inactive", label:"Inactive"},
  {value:"other", label:"Other"},
  // {value:"Pending", label:"Pending"}
]

export const Country = [
  {value:"United States", label:"United States"},
  // {value:"cananda", label:"Cananda"},
  // {value:"uk", label:"United Kingdom"},
  // {value:"australia", label:"Australia"},
  // {value:"india", label:"India"},
  // {value:"china", label:"China"},
  // {value:"japan", label:"Japan"},
  // {value:"germany", label:"Germany"},
  // {value:"france", label:"France"},
  // {value:"brazil", label:"Brazil"},
  // {value:"south-africa", label:"South Africa"},
  // {value:"mexico", label:"Mexico"},
  // {value:"italy", label:"Italy"},
  // {value:"spain", label:"Spain"},
  // {value:"russia", label:"Russia"},
]

export const FIELD_TYPE = [
  {value:"alphabet", label:"Alphabet"},
  {value:"numeric", label:"Numeric"},
  {value:"date", label:"Date Picker"},
  {value:"dropdown", label:"Dropdown"},
  {value:"boolean", label:"Boolean"},
]

export const NON_REQ_FIELDS = [
  "mbe_african_american_spend","mbe_african_american_number",
  "mbe_african_american_percent","mbe_asian_pacific_american_spend",
  "mbe_asian_pacific_american_number","mbe_asian_pacific_american_percent",
  "mbe_native_american_spend","mbe_native_american_number","mbe_native_american_percent",
  "mbe_hispanic_american_spend","mbe_hispanic_american_number","mbe_hispanic_american_percent","wmbe_spend",
  "wmbe_number","wmbe_percent","wbe_spend","wbe_number","wbe_percent","pbdbe_spend","pbdbe_number","pbdbe_percent","lgbtbe_spend",
  "lgbtbe_number","lgbtbe_percent","dvbe_spend","dvbe_number","dvbe_percent","other_8a_spend","other_8a_percent","other_8a_number"
  ]

export const ARRAY_FIELDS = [
  "Year",
  "Company Name",
  "Category",
  "Company Size",
  "Type of Utility",
  "Total Spend",
  "Total DBE",
  "Total DBE %",
  "MBE Spend",
  "MBE %",
  "MBE Total Direct Spend",
  "MBE Total Sub Spend",
  "MBE (African American) Spend",
  "MBE (African American) #",
  "MBE (African American) %",
  "MBE (African American) Direct Spend",
  "MBE (African American) Sub Spend",
  "MBE(Asian Pacific American) Spend",
  "MBE (Asian Pacific American) #",
  "MBE (Asian Pacific American) %",
  "MBE (Asian Pacific American) Direct Spend",
  "MBE (Asian Pacific American) Sub Spend",
  "MBE (Native American) Spend",
  "MBE (Native American) #",
  "MBE (Native American) %",
  "MBE (Native American) Direct Spend",
  "MBE (Native American) Sub Spend",
  "MBE (Hispanic American) Spend",
  "MBE (Hispanic American) #",
  "MBE (Hispanic American) %",
  "MBE (Hispanic American) Direct Spend",
  "MBE (Hispanic American) Sub Spend",
  "WMBE Spend", 
  "WMBE #", 
  "WMBE %", 
  "Direct Spend (WMBE)", 
  "Sub Spend (WMBE)", 
  "WBE Spend", 
  "WBE #", 
  "WBE %", 
  "WBE Direct Spend", 
  "WBE Sub Spend", 
  "PBDBE Spend", 
  "PBDBE #", 
  "PBDBE %", 
  "PBDBE Direct Spend", 
  "PBDBE Sub Spend", 
  "LGBTBE Spend", 
  "LGBTBE #", 
  "LGBTBE %", 
  "LGBTBE Direct Spend", 
  "LGBTBE Sub Spend", 
  "DVBE Spend", 
  "DVBE #", 
  "DVBE %", 
  "DVBE Direct Spend", 
  "DVBE Sub Spend", 
  "Other 8 (a) Spend", 
  "Other 8 (a) %", 
  "Other 8 (a) #", 
  "Other 8 (a) Direct Spend", 
  "Other 8 (a) Sub Spend",
  "Total Direct Spend",
  "Total Direct %",
  "Total Direct # Suppliers",
  "Total Sub Spend",
  "Total Sub #",
  "Total Sub %",
  "New Diverse Suppliers $",
  "New Diverse Suppliers #",
  "Rev - Under $1M -#",
  "Rev - Under $1M - $",
  "Rev - Under $5M -#",
  "Rev - Under $5M - $",
  "Rev - Under $10M -#",
  "Rev - Under $10M - $",
  "Rev - Above $10M -#",
  "Rev - Above $10M - $",
  "Grand Total $",
  "Grand Total #",
  "9.1.2 Description of Diverse Suppliers with Majority Workforce in California (# OF DIVERSE SUPPLIERS IN CA)",
  "WORKFORCE AND BOARD DIVERSITY (# AND % OF WOMEN)",
  "WORKFORCE AND BOARD DIVERSITY (# AND % OF PPL OF COLOR)",
  "Total number of boards of Directors"
]

export const headerMappingData = {
  year: "Year",
  category: "Category",
  company_name: "Company Name",
  company_size: "Company Size",
  type_of_utility: "Type of Utility",
  total_spend: "Total Spend",
  total_dbe: "Total DBE",
  total_dbe_percent: "Total DBE %",
  mbe_spend: "MBE Spend",
  mbe_percent: "MBE %",
  mbe_total_direct_spend: "MBE Total Direct Spend",
  mbe_total_sub_spend: "MBE Total Sub Spend",
  mbe_african_american_spend: "MBE (African American) Spend",
  mbe_african_american_number: "MBE (African American) #",
  mbe_african_american_percent: "MBE (African American) %",
  mbe_african_american_direct_spend: "MBE (African American) Direct Spend",
  mbe_african_american_sub_spend: "MBE (African American) Sub Spend",
  mbe_asian_pacific_american_spend: "MBE(Asian Pacific American) Spend",
  mbe_asian_pacific_american_number: "MBE (Asian Pacific American) #",
  mbe_asian_pacific_american_percent: "MBE (Asian Pacific American) %",
  mbe_asian_pacific_american_direct_spend: "MBE (Asian Pacific American) Direct Spend",
  mbe_asian_pacific_american_sub_spend: "MBE (Asian Pacific American) Sub Spend",
  mbe_native_american_spend: "MBE (Native American) Spend",
  mbe_native_american_number: "MBE (Native American) #",
  mbe_native_american_percent: "MBE (Native American) %",
  mbe_native_american_direct_spend: "MBE (Native American) Direct Spend",
  mbe_native_american_sub_spend: "MBE (Native American) Sub Spend",
  mbe_hispanic_american_spend: "MBE (Hispanic American) Spend",
  mbe_hispanic_american_number: "MBE (Hispanic American) #",
  mbe_hispanic_american_percent: "MBE (Hispanic American) %",
  mbe_hispanic_american_direct_spend: "MBE (Hispanic American) Direct Spend",
  mbe_hispanic_american_sub_spend: "MBE (Hispanic American) Sub Spend",
  wmbe_spend: "WMBE Spend",
  wmbe_number: "WMBE #",
  wmbe_percent: "WMBE %",
  direct_spend_wmbe: "Direct Spend (WMBE)",
  sub_spend_wmbe: "Sub Spend (WMBE)",
  wbe_spend: "WBE Spend",
  wbe_number: "WBE #",
  wbe_percent: "WBE %",
  wbe_direct_spend: "WBE Direct Spend",
  wbe_sub_spend: "WBE Sub Spend",
  pbdbe_spend: "PBDBE Spend",
  pbdbe_number: "PBDBE #",
  pbdbe_percent: "PBDBE %",
  pbdbe_direct_spend: "PBDBE Direct Spend",
  pbdbe_sub_spend: "PBDBE Sub Spend",
  lgbtbe_spend: "LGBTBE Spend",
  lgbtbe_number: "LGBTBE #",
  lgbtbe_percent: "LGBTBE %",
  lgbtbe_direct_spend: "LGBTBE Direct Spend",
  lgbtbe_sub_spend: "LGBTBE Sub Spend",
  dvbe_spend: "DVBE Spend",
  dvbe_number: "DVBE #",
  dvbe_percent: "DVBE %",
  dvbe_direct_spend: "DVBE Direct Spend",
  dvbe_sub_direct_spend: "DVBE Sub Spend",
  other_8a_spend: "Other 8 (a) Spend",
  other_8a_percent: "Other 8 (a) %",
  other_8a_number: "Other 8 (a) #",
  other_8a_direct_spend: "Other 8 (a) Direct Spend",
  other_8a_sub_spend: "Other 8 (a) Sub Spend",
  total_direct_spend: "Total Direct Spend",
  total_direct_percent: "Total Direct %",
  total_direct_number_suppliers: "Total Direct # Suppliers",
  total_sub_spend: "Total Sub Spend",
  total_sub_number: "Total Sub #",
  total_sub_percent: "Total Sub %",
  new_diverse_suppliers_dollar: "New Diverse Suppliers $",
  new_diverse_suppliers_number: "New Diverse Suppliers #",
  rev_under_1m_number: "Rev - Under $1M -#",
  rev_under_1m_dollar: "Rev - Under $1M - $",
  rev_under_5m_number: "Rev - Under $5M -#",
  rev_under_5m_dollar: "Rev - Under $5M - $",
  rev_under_10m_number: "Rev - Under $10M -#",
  rev_under_10m_dollar: "Rev - Under $10M - $",
  rev_above_10m_number: "Rev - Above $10M -#",
  rev_above_10m_dollar: "Rev - Above $10M - $",
  grand_total_dollar: "Grand Total $",
  grand_total_number: "Grand Total #",
  diverse_suppliers_ca_number: "9.1.2 Description of Diverse Suppliers with Majority Workforce in California (# OF DIVERSE SUPPLIERS IN CA)",
  workforce_board_women: "WORKFORCE AND BOARD DIVERSITY (# AND % OF WOMEN)",
  workforce_board_ppl_of_color: "WORKFORCE AND BOARD DIVERSITY (# AND % OF PPL OF COLOR)",
  total_board_directors_number: "Total number of boards of Directors"

};

export const CATEGORY_INFORMATION = [
  "year","category","company_name","company_size","type_of_utility","total_spend","total_dbe","total_dbe_percent",
]

export const MBE_INFORMATION = [
  "mbe_spend","mbe_percent","mbe_total_direct_spend","mbe_total_sub_spend","mbe_african_american_spend","mbe_african_american_number","mbe_african_american_percent",
  "mbe_african_american_direct_spend","mbe_african_american_sub_spend","mbe_asian_pacific_american_spend","mbe_asian_pacific_american_number",
  "mbe_asian_pacific_american_percent","mbe_asian_pacific_american_direct_spend","mbe_asian_pacific_american_sub_spend","mbe_native_american_spend",
  "mbe_native_american_number","mbe_native_american_percent","mbe_native_american_direct_spend","mbe_native_american_sub_spend","mbe_hispanic_american_spend",
  "mbe_hispanic_american_number","mbe_hispanic_american_percent","mbe_hispanic_american_direct_spend","mbe_hispanic_american_sub_spend",
]

export const DIVERSE_GROUP_INFORMATION = [
  "wmbe_spend","wmbe_number","wmbe_percent","direct_spend_wmbe","sub_spend_wmbe","wbe_spend","wbe_number","wbe_percent","wbe_direct_spend",
  "wbe_sub_spend","pbdbe_spend","pbdbe_number","pbdbe_percent","pbdbe_direct_spend","pbdbe_sub_spend","lgbtbe_spend","lgbtbe_number","lgbtbe_percent",
  "lgbtbe_direct_spend","lgbtbe_sub_spend","dvbe_spend","dvbe_number","dvbe_percent","dvbe_direct_spend","dvbe_sub_direct_spend","other_8a_spend",
  "other_8a_percent","other_8a_number","other_8a_direct_spend","other_8a_sub_spend"
]

export const SUBCONTRACTING_INFORMATION = [
  "total_direct_spend","total_direct_percent","total_direct_number_suppliers","total_sub_spend","total_sub_number","total_sub_percent","new_diverse_suppliers_dollar",
  "new_diverse_suppliers_number",
]

export const REVENUE_REPORTING_INFORMATION = [
  "rev_under_1m_number","rev_under_1m_dollar","rev_under_5m_number","rev_under_5m_dollar","rev_under_10m_number","rev_under_10m_dollar",
  "rev_above_10m_number","rev_above_10m_dollar","grand_total_dollar","grand_total_number"
]

export const WORKFORCE_INFORMATION = [
  "diverse_suppliers_ca_number","workforce_board_women","workforce_board_ppl_of_color","total_board_directors_number"
]



export const dropdownForGroups = [
  {
    name: "Edit Company",
    icon: <EditOutlinedIcon />,
  },
  {
    name: "Delete Company",
    icon: <DeleteOutlineOutlinedIcon />,
  },
  {
    name: "Make Group Inactive",
    icon: <ToggleOffOutlinedIcon />,
  },
];

export const MESSAGE_INVALID_NAME_LENGTH = "This is a required field.";
export const MESSAGE_INVALID_NAME_LENGTH_SUFFIX = "is a required field.";
export const MESSAGE_INVALID_LENGTH =
  "Record name must be greater than 3 characters.";
export const MESSAGE_INVALID_LENGTH_SUFFIX =
  "must be greater than 3 characters.";
export const MESSAGE_SCHEDULE = "Schedule Date is required, Go Step 4";
export const MESSAGE_INVALID_PASSWORD =
  "Password length should be greater than or equal to 8 characters.";
export const MESSAGE_INVALID_EMAIL_PATTERN = "Please enter valid email.";
export const MESSAGE_INVALID_EMAIL_LENGTH = "Email is a required field.";
export const MESSAGE_INVALID_PASSWORD_LENGTH = "Password is required.";
export const MESSAGE_CONFIRMED_INVALID_PASSWORD_LENGTH =
  "Confirm Password is required.";
export const NEW_PASSWORD_LENGTH =
  "Please provide your new password to proceed.";
export const MESSAGE_INVALID_CONF_PASSWORD_LENGTH =
  "Confirm Password should be greater than or equal to 8.";
export const MESSAGE_INVALID_NEW_PASSWORD_LENGTH =
  "New Password should be greater than or equal to 8.";
export const MESSAGE_INVALID_DOMAIN = "Please enter valid domain.";
export const MESSAGE_PASSWORD_CONFIRMPASSWORD_MISMATCH =
  "New password and confirm password should be same.";
export const CONFIRM_PASSWORD_LENGTH =
  "Please provide your confirm password to proceed.";
export const MESSAGE_PASSWORD_NEW_PASSWORD_MISMATCH =
  "Old Password and New Password should not be same.";
export const MESSAGE_PAST_DATE_NOT_ALLOWED = "Past date not allowed.";
export const DYNAMIC_FIELD_FORMAT =
  "Only _,- and alphanumeric values are allowed.";

export const MESSAGE_INVALID_URL = "Please enter a valid URL";
export const MESSAGE_INVALID_NUMBER = "Please enter a valid Phone Number";

export const DEFAULT_ERROR_MESSAGE = "Something went wrong.";

export const UNAUTHORIZED_USER_MESSAGE = "Unauthorized User";

export const MESSAGE_ZIPCODE_ERROR_MESSAGE =
  "Postal/Zip should not be less than 4 digits.";
export const MESSAGE_REQUIRED_FIELD = "This is a required field.";

export const MESSAGE_HOURLY_LIMIT_EXCEED =
  "Hourly limit cannot exceed more than 50";

export const MESSAGE_DAILY_LIMIT_EXCEED =
  "Daily limit cannot exceed more than 500";

// Dashboard Errors
export const EMPTY_DASHBOARD_FIELD_ERROR = "Please enter the Dashboard name";
export const DASHBOARD_NAME_LONG_LENGTH_ERROR =
  "Dashboard name should be less than 50 characters.";
export const DASHBOARD_NAME_LENGTH_ERROR =
  "Dashboard name length must be at least 3 characters long.";
export const WIDGET_CREATION_SUCCESS_MESSAGE = "Widget Created Successfully";
export const DASHBOARD_DELETED_SUCCESSFULLY = "Dashboard Deleted";
export const EMPTY_WIDGET_FIELD_ERROR = "Please enter the Widget Name";
export const WIDGET_NAME_LONG_LENGTH_ERROR =
  "Dashboard name length must be less than or equal to 25 characters long.";
export const WIDGET_NAME_LENGTH_ERROR =
  "Dashboard name length must be at least 3 characters long.";
//Campaigns Validations

export const SENDER_FIRSTNAME_LENGTH =
  "Please enter sender’s first name to proceed.";
export const SENDER_LASTNAME_LENGTH =
  "Please enter sender’s last name to proceed.";
export const SENDER_JOBTITLE = "Please enter sender’s job title to proceed.";
export const SENDER_COMPANYNAME =
  "Please enter sender’s company name to proceed.";
export const SENDER_PHONENUMBER =
  "Please enter sender’s phone number to proceed.";
export const SENDER_ADDRESS =
  "Please enter sender’s complete address to proceed.";
export const SENDER_CITY = "Please enter sender’s city to proceed.";
export const SENDER_STATE = "Please enter sender’s state to proceed";
export const SENDER_POSTAL =
  "Please enter sender’s postal/zip code to proceed.";
export const SENDER_COUNTRY = "Please enter sender’s country to proceed.";
export const LABEL_MAX_LENGTH =
  "Label should not exceed 50 characters in length. ";
export const SELECT_LIST = "Please select any one list.";
export const SELECT_FOLDER = "Please select any  Folder";
export const SELECT_ATLEAST_LIST = "Please select at least one  List";
export const SELECT_TEMPLATE = "Please select at least one  Template";
export const SERVER_MESSAGE_ABSENCE = "Server message is not present";
export const SERVER_MESSAGE_MISPLACED = "Server error message misplaced.";
export const MESSAGE_ALL_FIELD_REQUIRED = "All fields are required.";
export const MESSAGE_FOLDER_DELETE = "Folders deleted successfully";
export const MESSAGE_FOLDERS_DELETE = "Folders deleted";
export const SEQUENCE_NAME_LENGTH_ERROR =
  "Sequence name should be atleast 3 characters.";
export const SEQUENCE_NAME_LONG_LENGTH_ERROR =
  "Sequence name should be less than or equal to 50";
export const FOLDER_NAME_LENGTH_ERROR =
  "Folder name should be atleast 3 characters.";
export const FOLDER_NAME_LONG_LENGTH_ERROR =
  "Folder name should be less than or equal to 50";
export const LIST_NAME_LENGTH_ERROR =
  "List name should be atleast 3 characters.";
export const LIST_NAME_LONG_LENGTH_ERROR =
  "List name should be less than or equal to 50";
export const TEMP_NAME_LENGTH_ERROR =
  "Template name should be atleast 3 characters.";
export const TEMP_NAME_LONG_LENGTH_ERROR =
  "Template name cannot be more than 150 characters";
export const MESSAGE_SUBJECT_REQUIRED = "Subject is required.";

export const MESSAGE_DELETED_SUCCESSFULLY = "Successfully Deleted";
export const MESSAGE_TEST_MAIL_SENT = "Test Mail sent";
export const IMPORT_FILE_UPLOAD_SUCCESS =
  "File Uploaded Successfully, Map the column Headers in your file.";
export const IMPORT_COMPANY_WEBSITE_MANDATORY =
  "Company website is mandatory, if company data is included.";
export const IMPORT_COMPANY_WEBSITE_EMAIL_MANDATORY =
  "Company website and email is mandatory, if company and people data is included.";
export const IMPORT_FILE_EMAIL =
  "Email is mandatory, if people data is included.";
export const ATLEAS_ONE_FIELD = "Map atleast one field.";
export const FILE_SIZE_ERROR = "File size should be less than 1gb";
export const INVALID_FILE_ERROR = "Select File Type is Invalid.";
export const INVALID_FORMAT_IMAGE =
  "Please choose the image format to upload in JPG or PNG";
export const CANT_EXCEED_100WORDS = "Cannot exceed more than 100 words";
export const CANT_EXCEED_50WORDS = "Cannot exceed more than 50 words";
export const HOURLY_DAILY_LIMIT =
  "Hourly limit should not be greater than daily limit";
export const DAILY_DAILY_LIMIT =
  "Daily limit should not be less than hourly limit";
export const INVALID_URL = "Invalid URL";
export const INVALID_DATE_TIME =
  "The date or time selected is in the past. Please select a future date or time.";
export const SPECIAL_CHARATER_CHECK =
  "The property name should not contain any special characters."
export const PHONE_NUMBER_INVALID = "Enter a valid Phone Number";
export const PHONE_NUMBER = "Phone number is required";
export const PHONE_NUMBER_LENGTH = "Phone number should not be more then 10 digits.";
export const PHONE_MIN_LENGTH = "Phone number length should have atleast 10 digits";
export const ZIP_LENGTH = "Zip code should have only 5 digits"

export const FORMS_FIELD_RESET_MESSAGE = "Forms Fields Reset Successfully"
export const BLOCKED_SCREEN_MESSAGE = "Regrettably, your account has been suspended for security purposes. Kindly reach out to us at support@reachiq.io for additional assistance.";
export const FLAGGED_SCREEN_MESSAGE = "You organization account is temporary suspended. Please contact to our support team.";
export const INACTIVE_SCREEN_MESSAGE = "We have received your request. It is under process. We will contact you soon.";
export const IMAGE_UPLOAD_SIZE_WARNING = "Image size too big, max 500KB Allowed.";
export const INVALID_IMAGE_TYPE = "Invalid image type.";
export const UPLOADING_IMAGE = "Uploading Image...";
export const IMAGE_UPLOADED_MESSAGE = "Image Uploaded Successfully.";
export const DUPLICATE_STAGE_NAME = "Duplicate Stage name is not allowed";
