import React from "react";
import MailIcon from "@mui/icons-material/Mail";
import { useLocation, useNavigate } from "react-router-dom";
import "./_index.scss";
import { useForgotPasswordMutation } from "../../../features/Authentication/loginSlice";
import { useToast } from "../../ToastProvider";
const EmailSent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showSuccess, showError } = useToast();
  const [forgotPassword, { isLoading, isError, isSuccess, data }] = useForgotPasswordMutation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const handleResendMail = async() => {
    try{
      const result = await forgotPassword(email).unwrap();
      showSuccess({
        body:"Email resent successfully"
      })
    }catch(err){
      showError({
        title: 'Error!',
        body: err?.data?.message || err?.status   || "something went wrong"
      });
    }
  };
  return (
    <div className='email_sent_container'>
      <div className='icon_for_mail'>
        <MailIcon />
      </div>
      <div className='welcome_emailsent'>
        <p className='heading_emailsent'>Email Sent</p>
        <p className='description_emailsent'>
          {`We have sent you and email at ${email}. Check your inbox and follow the instruction to reset your account password.`}
        </p>
      </div>
      <div className='forgot_pass_email_sent'>
        Did not receive the email?&nbsp;{" "}
        <a className='reset_pass_email_sent' onClick={handleResendMail}>
          Resend Email
        </a>
      </div>
    </div>
  );
};

export default EmailSent;
