import React, { useEffect, useState } from 'react'
import axios from 'axios'
import "./input-temp.scss"
import { ArrowDropDownIcon, ArrowDropUpIcon } from '../../../../utils/icons'
import CommonField from '../CommonField'
import AddInput from '../AddInput'
import { useSetsaveMutation } from '../../../../features/Input/inputSlice'
import { useToast } from '../../../ToastProvider'
import Header from '../../../../Components/Header'


const InputTemplate = () => {
  const { showSuccess, showError } = useToast();
  const [loading,setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [apiData, setApiData] = useState()
  const [isEdit, setIsEdit] = useState(false)
  const [customFields, setCustomFields] = useState([])
  const [setsave, { isLoading, isSuccess, isError, error: issErr }] = useSetsaveMutation();
  const [rowData, setRowData] = useState({
    val: "",
    id: ""
  })
  const [headList, setHeadList] = useState([
    {
      id: 1, label: "Company Information", isExpanded: true, data: [
        { label: "Year", back: "year", type: 1, place: "Select year (ex-2001)", isClicked: false, isDropdown: true, isRequired: true,fieldType:"dropdown" },
        { label: "Company name", back: "company_name", type: 1, place: "Enter company name", isClicked: false, isDropdown: false, isRequired: true,fieldType:"alphabet" },
        { label: "Category", back: "category", type: 1, place: "Select category", isClicked: false, isDropdown: true, isRequired: true,fieldType:"dropdown" },
        { label: "Company Size", back: "company_size", type: 1, place: "Select company size", isClicked: false, isDropdown: true, isRequired: true,fieldType:"dropdown" },
        { label: "Type of Utility", back: "type_of_utility", type: 1, place: "Select utility type", isClicked: false, isDropdown: true, isRequired: true,fieldType:"dropdown" },
        { label: "Total Spend", back: "total_spend", type: 1, place: "Enter total spend (ex-00.00)", isClicked: false, isDropdown: false, isRequired: true,fieldType:"numeric" },
        { label: "Total DBE", back: "total_dbe", type: 1, place: "Enter total DBE (ex-00.00)", isClicked: false, isDropdown: false, isRequired: true,fieldType:"numeric" },
        { label: "Total DBE %", back: "total_dbe_percent", type: 1, place: "Enter total DBE % (ex-0.0%)", isClicked: false, isDropdown: false, isRequired: true,fieldType:"numeric" }
      ]
    },
    {
      id: 2, label: "MBE Information", isExpanded: false,data: [
    { label: "MBE Spend", back: "mbe_spend", type: 2, place: "Spend for Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "MBE %", back: "mbe_percent", type: 2, place: "Percentage of spend for MBEs.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "MBE Total Direct Spend", back: "mbe_total_direct_spend", type: 2, place: "Total Direct Spend for Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "MBE Total Sub Spend", back: "mbe_total_sub_spend", type: 2, place: "Total Subcontracting Spend for Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "MBE (African American) Spend", back: "mbe_african_american_spend", type: 2, place: "Spend for (African American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "MBE (African American) #", back: "mbe_african_american_number", type: 2, place: "Number of spend for (African American) MBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "MBE (African American) %", back: "mbe_african_american_percent", type: 2, place: "Percentage of spend for (African American) MBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "MBE (African American) Direct Spend", back: "mbe_african_american_direct_spend", type: 2, place: "Direct Spend for (African American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "MBE (African American) Sub Spend", back: "mbe_african_american_sub_spend", type: 2, place: "Sub Spend for (African American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "MBE(Asian Pacific American) Spend", back: "mbe_asian_pacific_american_spend", type: 2, place: "Spend for (Asian Pacific American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "MBE (Asian Pacific American) #", back: "mbe_asian_pacific_american_number", type: 2, place: "Number of spend for (Asian Pacific American) MBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "MBE (Asian Pacific American) %", back: "mbe_asian_pacific_american_percent", type: 2, place: "Percentage of spend for (Asian Pacific American) MBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "MBE (Asian Pacific American) Direct Spend", back: "mbe_asian_pacific_american_direct_spend", type: 2, place: "Direct Spend for (Asian Pacific American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "MBE (Asian Pacific American) Sub Spend", back: "mbe_asian_pacific_american_sub_spend", type: 2, place: "Sub Spend for (Asian Pacific American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "MBE (Native American) Spend", back: "mbe_native_american_spend", type: 2, place: "Spend for (Native American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "MBE (Native American) #", back: "mbe_native_american_number", type: 2, place: "Number of spend for (Native American) MBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "MBE (Native American) %", back: "mbe_native_american_percent", type: 2, place: "Percentage of spend for (Native American) MBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "MBE (Native American) Direct Spend", back: "mbe_native_american_direct_spend", type: 2, place: "Direct Spend for (Native American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "MBE (Native American) Sub Spend", back: "mbe_native_american_sub_spend", type: 2, place: "Sub Spend for (Native American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "MBE (Hispanic American) Spend", back: "mbe_hispanic_american_spend", type: 2, place: "Spend for (Hispanic American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "MBE (Hispanic American)#", back: "mbe_hispanic_american_number", type: 2, place: "Number of spend for (Hispanic American) MBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "MBE (Hispanic American) %", back: "mbe_hispanic_american_percent", type: 2, place: "Percentage of spend for (Hispanic American) MBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "MBE (Hispanic American) Direct Spend", back: "mbe_hispanic_american_direct_spend", type: 2, place: "Direct Spend for (Hispanic American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "MBE (Hispanic American) Sub Spend", back: "mbe_hispanic_american_sub_spend", type: 2, place: "Sub Spend for (Hispanic American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" }
]

    },
    {
      id: 3, label: "Diverse Groups Information", isExpanded: false, data: [
    { label: "WMBE Spend", back: "wmbe_spend", type: 3, place: "Spend for Women Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "WMBE #", back: "wmbe_number", type: 3, place: "Number of spend for WMBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "WMBE %", back: "wmbe_percent", type: 3, place: "Percentage of spend for WMBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "Direct Spend (WMBE)", back: "direct_spend_wmbe", type: 3, place: "Direct Spend for  Minority Women Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "Sub Spend (WMBE)", back: "sub_spend_wmbe", type: 3, place: "Sub Spend for  Minority Women Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "WBE Spend", back: "wbe_spend", type: 3, place: "Spend for Women Business Enterprises.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "WBE #", type: 3,back:"wbe_number", place: "Number of spend for WBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "WBE %", type: 3,back:"wbe_percent", place: "Percentage of spend for WBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "WBE Direct Spend", back: "wbe_direct_spend", type: 3, place: "Direct Spend for Women Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "WBE Sub Spend", back: "wbe_sub_spend", type: 3, place: "Subcontracting Spend for Women Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "PBDBE Spend", back: "pbdbe_spend", type: 3, place: "Spend for Person with Disablities Business Enterprises.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "PBDBE #", back: "pbdbe_number", type: 3, place: "Number of spend for PBDBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "PBDBE %", back: "pbdbe_percent", type: 3, place: "Percentage of spend for PBDBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "PBDBE Direct Spend", back: "pbdbe_direct_spend", type: 3, place: "Direct Spend for Person with Disabilities Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "PBDBE Sub Spend", back: "pbdbe_sub_spend", type: 3, place: "Subcontracting Spend for Person with Disabilities Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "LGBTBE Spend", back: "lgbtbe_spend", type: 3, place: "Spend for LGBT Business Enterprises.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "LGBTBE #", back: "lgbtbe_number", type: 3, place: "Number of spend for LGBTBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "LGBTBE %", back: "lgbtbe_percent", type: 3, place: "Percentage of spend for LGBTBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "LGBTBE Direct Spend", back: "lgbtbe_direct_spend", type: 3, place: "Direct Spend for LGBTBE", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "LGBTBE Sub Spend", back: "lgbtbe_sub_spend", type: 3, place: "Sub Spend for LGBTBE", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "DVBE Spend", back: "dvbe_spend", type: 3, place: "Spend for Disabled Veteran Business Enterprises.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "DVBE #", back: 'dvbe_number', type: 3, place: "Number of spend for DVBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "DVBE %", back: "dvbe_percent", type: 3, place: "Percentage of spend for DVBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "DVBE Direct Spend", back: "dvbe_direct_spend", type: 3, place: "Direct Spend for Disabled Veteran Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "DVBE Sub Spend", back: "dvbe_sub_direct_spend", type: 3, place: "Sub Spend for Disabled Veteran Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "Other 8 (a) Spend", back: "other_8a_spend", type: 3, place: "Spend for other 8(a) small businesses.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "Other 8 (a) %", back: "other_8a_percent", type: 3, place: "Number of spend for other 8(a) small businesses.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "Other 8 (a) #", back: "other_8a_number", type: 3, place: "Percentage of spend for other 8(a) small businesses.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" },
    { label: "Other 8 (a) Direct Spend", back: "other_8a_direct_spend", type: 3, place: "Direct Spend for other 8(a) small businesses.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "Other 8 (a) Sub Spend", back: "other_8a_sub_spend", type: 3, place: "Sub Spend for other 8(a) small businesses.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" }
]

    },
    {
      id: 4, label: "Direct/Subcontracting Information", isExpanded: false, data: [
    { label: "Total Direct Spend", back: "total_direct_spend", type: 4, place: "Total direct spending by the company.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "Total Direct %", back: "total_direct_percent", type: 4, place: "Percentage of direct spending.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "Total Direct # Suppliers", back: "total_direct_number_suppliers", type: 4, place: "Number of direct suppliers.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "Total Sub Spend", back: "total_sub_spend", type: 4, place: "Total subcontractor spending.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "Total Sub #", back: "total_sub_number", type: 4, place: "Number of subcontractor spending.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "Total Sub %", back: "total_sub_percent", type: 4, place: "Percentage of subcontractor spending.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "New Diverse Suppliers $", back: "new_diverse_suppliers_dollar", type: 4, place: "Spend for new diverse suppliers.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "New Diverse Suppliers #", back: "new_diverse_suppliers_number", type: 4, place: "Number of new diverse suppliers.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" }
]

    },
    {
      id: 5, label: "Revenue Reporting Information", isExpanded: false, data: [
    { label: "Rev - Under $1M -#", back: "rev_under_1m_number", type: 5, place: "Number of suppliers with revenue under $1M.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "Rev - Under $1M - $", back: "rev_under_1m_dollar", type: 5, place: "Spend for suppliers with revenue under $1M.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "Rev - Under $5M -#", back: "rev_under_5m_number", type: 5, place: "Number of suppliers with revenue under $5M.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "Rev - Under $5M - $", back: "rev_under_5m_dollar", type: 5, place: "Spend for suppliers with revenue under $5M.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "Rev - Under $10M -#", back: "rev_under_10m_number", type: 5, place: "Number of suppliers with revenue under $10M.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "Rev - Under $10M - $", back: "rev_under_10m_dollar", type: 5, place: "Spend for suppliers with revenue under $10M.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "Rev - Above $10M -#", back: "rev_above_10m_number", type: 5, place: "Number of suppliers with revenue above $10M.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "Rev - Above $10M - $", back: "rev_above_10m_dollar", type: 5, place: "Spend for suppliers with revenue above $10M.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "Grand Total $", back: "grand_total_dollar", type: 5, place: "Total spend across all categories.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "Grand Total #", back: "grand_total_number", type: 5, place: "Total number of suppliers across all categories.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" }
]

    },
    {
      id: 6, label: "Workforce/Board Diversity Information", isExpanded: false,data: [
    { label: "9.1.2 Description of Diverse Suppliers with Majority Workforce in California (# OF DIVERSE SUPPLIERS IN CA)", back:"diverse_suppliers_ca_number", type: 6, place: "Number of diverse suppliers with a majority workforce in California.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "alphabet" },
    { label: "WORKFORCE AND BOARD DIVERSITY (# AND % OF WOMEN)", type: 6, place: "Number and percentage of women in the workforce and for the board.",back:"workforce_board_women", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "WORKFORCE AND BOARD DIVERSITY (# AND % OF PPL OF COLOR)",back:"workforce_board_ppl_of_color", type: 6, place: "Number and percentage of people of color in the workforce and for the board.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" },
    { label: "Total number of boards of Directors",back:"total_board_directors_number", type: 6, place: "Total number of boards of Directors.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric" }
]

    },
  ])

  const [isContentClicked, setIsContentClicked] = useState(false)
  const [clickId, setClickId] = useState()


  console.log("IS",isLoading)

  const handleAddClick = (roww, index) => {
  
    setHeadList((prevHeadList) =>
      prevHeadList.map((headItem) =>
        headItem.id === roww.type
          ? {
            ...headItem,
            data: headItem.data.map((dataItem, i) =>
              index === i
                ? { ...dataItem, isClicked: !dataItem.isClicked }
                : dataItem
            ),
          }
          : headItem
      )
    );
  }

  const textReturn = (id) => {
    if (id == 1) {
      return "Company Information"
    } else if (id == 2) {
      return "MBE Information"
    }
    else if (id == 3) {
      return "Diverse Groups Information"
    }
    else if (id == 4) {
      return "Direct/Subcontracting Information"
    }
    else if (id == 5) {
      return "Revenue Reporting Information"
    }
    else if (id == 6) {
      return "Workforce/Board Diversity Information"
    }
  }

  
  const handleCustom = (custom,updated)=>{
    // console.log("CT",custom,updated)
    custom.map((itm,index)=>{
       if(itm.category=== "Company Information"){
        const obj = { label: itm.fieldName, type: 1, place: itm.placeholder, isClicked: false, isDropdown: itm.fieldType == "dropdown" ? true : false, isRequired: false, visible: true,fieldType:itm.fieldType }
        updated[0].data.push(obj)
       }else if(itm.category === "MBE Information"){
        const obj = { label: itm.fieldName, type: 2, place: itm.placeholder, isClicked: false, isDropdown: itm.fieldType == "dropdown" ? true : false, isRequired: false, visible: true,fieldType:itm.fieldType }
        updated[1].data.push(obj)
       }else if(itm.category === "Diverse Groups Information"){
        const obj = { label: itm.fieldName, type: 3, place: itm.placeholder, isClicked: false, isDropdown: itm.fieldType == "dropdown" ? true : false, isRequired: false, visible: true,fieldType:itm.fieldType }
        updated[2].data.push(obj)
       }else if(itm.category === "Direct/Subcontracting Information"){
        const obj = { label: itm.fieldName, type: 4, place: itm.placeholder, isClicked: false, isDropdown: itm.fieldType == "dropdown" ? true : false, isRequired: false, visible: true,fieldType:itm.fieldType }
        updated[3].data.push(obj)
       }else if(itm.category === "Revenue Reporting Information"){
        const obj = { label: itm.fieldName, type: 5, place: itm.placeholder, isClicked: false, isDropdown: itm.fieldType == "dropdown" ? true : false, isRequired: false, visible: true,fieldType:itm.fieldType }
        updated[4].data.push(obj)
       }else if(itm.category === "Workforce/Board Diversity Information"){
        const obj = { label: itm.fieldName, type: 6, place: itm.placeholder, isClicked: false, isDropdown: itm.fieldType == "dropdown" ? true : false, isRequired: false, visible: true,fieldType:itm.fieldType }
        updated[5].data.push(obj)
       }
    })
    return updated
    // console.log(updated,"lkjlk")
 }
  const handleAddInput = (row, index) => {
    setIsContentClicked(true)
    setClickId(row.id)

  }
  const handleExpand = (index) => {
    setHeadList((prevRows) =>
      prevRows.map((row, i) =>
        i === index ? { ...row, isExpanded: !row.isExpanded } : row
      )
    );
  }

  const handleAdd = () => {

  }

  const handleValue = (type, labelText, placeText, inputs) => {
    console.log("TYPP",type)
    const obj = { label: labelText, type: clickId, place: placeText, isClicked: false, isDropdown: type == "dropdown" ? true : false, isRequired: false, visible: true,isCustom:true,fieldType:type }
    setHeadList((prevHeadList) =>
      prevHeadList.map((headItem) =>
        headItem.id === clickId
          ? {
            ...headItem,
            data: [...headItem.data, obj]

          }
          : headItem
      )
    );
    if (type == "dropdown") {
      setCustomFields(prevState => [
        ...prevState,  // Preserve previous state (if any)
        {
          category: textReturn(clickId),
          fieldName: labelText,
          fieldType: "dropdown",
          placeholder: placeText,
          options: inputs
        }  // Add new object by spreading the customFields object
      ]);
    } else {
      setCustomFields(prevState => [
        ...prevState,  // Preserve previous state (if any)
        {
          category: textReturn(clickId),
          fieldName: labelText,
          fieldType: type,
          placeholder: placeText,
        }  // Add new object by spreading the customFields object
      ]);
    }

  }

  const handleEditValue = (type, labelText, placeText) => {
    console.log(type, labelText, placeText, rowData, "CHH")
    setHeadList((prevHeadList) => {
      console.log("Previous headList:", prevHeadList);

      return prevHeadList.map((headItem) => {
        console.log("Checking headItem:", headItem);
        if (headItem.id === rowData.val.type) {
          return {
            ...headItem,
            data: headItem.data.map((dataItem, i) => {
              console.log("Checking dataItem:", dataItem);
              return i === rowData.id
                ? {
                  ...dataItem,
                  label: labelText,
                  place: placeText,
                  isDropdown: type === "dropdown" ? true : false,
                }
                : dataItem;
            }),
          };
        }
        return headItem;
      });
    });


  }

  const editHandle = (itm, id) => {
    setRowData({
      val: itm,
      id: id
    })
    setIsContentClicked(true)
    setIsEdit(true)
  }

  const modalHandle = (val, i) => {
    console.log(val, i, "VLL",apiData)
    setRowData({
      val: val,
      id: i
    })
    setModal(!modal)
  }

  const deleteHandle = () => {
    setHeadList((prevHeadList) =>
      prevHeadList.map((headItem) =>
        headItem.id === rowData.val.type
          ? {
            ...headItem,
            data: headItem.data.map((item, i) =>
            i === rowData.id ? { ...item, visible: false } : item
          ),
          }
          : headItem
      )
    );
    if(!rowData.val.back){
        const result = customFields.filter((itm,id)=>{
          return(
            itm.fieldName !== rowData.val.label
          )
        })

        setCustomFields(result)
        // console.log(result,"rety")
    }
    else{
      setApiData((prevData)=>({
        ...prevData,
        [rowData.val.back]:{visible:false}
      }))
    }
    setModal(false)
    setRowData({
      val: "",
      id: ""
    })
  }



  // JSON.parse(localStorage.getItem('access_token'))
  console.log("APP",apiData)
  // console.log("ROWS", rows)
  const fetchApi = async () => {
    try {
      const response = await axios.get(`https://devapiecg.resourcifi.tech/inputlayout/getlayout/99990`);
      // Simulating setting headlist with new data
      setApiData(response.data)
      setCustomFields(response.data.customFields || [])
      const updatedHeadlist = headList?.map((itm) => {
        const updatedData = itm.data?.map(field => {
          const matchingField = response.data[field.back];
          console.log("MT", matchingField);
          return {
            ...field,
            visible: matchingField ? matchingField.visible : undefined
          };
        });

        // Return the modified item with updated data
        return {
          ...itm,
          data: updatedData,
        };
      });

      // Update the headlist state with the modified data
      if (updatedHeadlist) {
        // handleText
        console.log(updatedHeadlist, "UPD")
        const finalView = handleCustom(response?.data?.customFields,updatedHeadlist)

        setHeadList(finalView);
      }
    } catch (error) {
      console.error("Error fetching layout data:", error);
      throw error;
    }
  };

  const handleCancelClick = ()=>{
    fetchApi()
  }

  const handleCreateGrpData = async () => {
    console.log("HED", headList)
    const mergedData = headList.map(item => item.data).flat();
    const result = mergedData.reduce((acc, field) => {
      const { back } = field;

      if (apiData[back]) {
        console.log("APL",apiData[back],apiData)
        acc[back] = {
          // type: apiData[back].type,
          visible: apiData[back]?.visible ? true : false
        };
      }
      return acc;
    }, {});
    console.log(result,"rdd")
    console.log("CUST", customFields)
    const finalResult = {
      ...result,
      // groupId:localStorage.getItem('group'),
      role:1,
      customFields: customFields
    }
    console.log(finalResult, "finl")
    try {
      setLoading(true)
      const resultData = await setsave(finalResult).unwrap(); // Call the mutation
      setLoading(false)    
      console.log("RES", resultData)
      fetchApi()
      showSuccess({
        body: "Saved Successfully"
      })

      // You can dispatch any other actions on success
    } catch (err) {
      showError({
        body: err?.data?.message || err?.status || "something went wrong"
      })
      console.error('Failed to save:', err);
    }finally{
       setLoading(false)
    }
    // console.log(mergedData, "MERGE", result)
  }
  useEffect(() => {
    fetchApi()
  }, [])
  // console.log("DATA", data, error, isLoading, headList)

  return (
    <div className='containerHead'>
      <div style={{ width: isContentClicked ? "65%" : "100%" }}>
        <Header
          title={"Input Data Management"}
          description='Here, you can manage all your input data.'
          isSearchField={false}
          isCancel={true}
          btnText={'Save'}
          btnLoading = {loading}
          isIcon={false}
          onAction1Click={handleCreateGrpData}
          handleCancel={handleCancelClick}
        />
        <div className={`inpContSection ${isContentClicked ? "disable" : ""}`} id="modal_body">
          {headList?.map((itm, index) => {
            return (
              <div style={{ marginTop: index !== 0 ? "32px" : "0px" }}>
                <div className={`inpCommonHeader ${itm.isExpanded ? "" : "none"}`} onClick={() => handleExpand(index)}>
                  <div className='inptCommLeft'>{itm.label}</div>
                  <div className='inptCommRight'>
                    <div className='inpTxtComm'>{itm.isExpanded ? "Collapse" : "Expand"}</div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                      {itm.isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </div>
                  </div>
                </div>
                {itm.isExpanded &&
                  <CommonField
                    rows={itm.data}
                    handleAddClick={handleAddClick}
                    handleAdd={handleAdd}
                    hanldeInputAddition={() => handleAddInput(itm, index)}
                    handleConfirmAction={deleteHandle}
                    modal={modal}
                    setModalData={setModal}
                    handleOpen={modalHandle}
                    contClick={editHandle}

                  />
                }
              </div>
            )
          })
          }
        </div>
      </div>
      {isContentClicked &&
        <div style={{ width: "35%" }}>
          <AddInput
            isContent={isContentClicked}
            setIsContent={setIsContentClicked}
            onAdd={handleValue}
            onEdit={handleEditValue}
            isEdit={isEdit}
            row={rowData}
          />
        </div>
      }
    </div>
  )
}

export default InputTemplate