import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import "./auth.scss"
import { MenuIcon } from '../utils/icons';
import HeaderProfile from '../screens/Profile/HeaderProfile';
import Sidebar from '../screens/Sidebar';
import SideMobile from '../screens/Sidebar/SideMobile';

const ProtectedRoute = ({ children }) => {
  const [isOpen,setIsOpen] = useState(false)
  const [roleData,setRoleData] = useState(1)
  const location = useLocation()
  const navigate = useNavigate();
   
     // Check if user exists and has a role
     // const role = user ? user.role : "2";
   
     useEffect(()=>{
       if(localStorage.getItem('access_token')){
       const user = JSON.parse(localStorage.getItem('access_token'))
       setRoleData(user.role)
       console.log("loc",location.pathname,user)
       if(user.role == 1){
        if(!location.pathname.includes("/super/")){
          navigate("/page")
        }
       }
       if(user.role == 2){
        if(!location.pathname.includes("/sub/")){
          navigate("/page")
        }
       }
       if(user.role == 3){
        if(!location.pathname.includes("/end/")){
          navigate("/page")
        }
       }
       }
     },[roleData])
  if(localStorage.getItem('access_token')){
    const {email,role} = JSON.parse(localStorage.getItem('access_token'))
    console.log("LOGO",typeof(localStorage.getItem("logo")))
    // setLogo(localStorage.getItem("logo"))

    console.log(JSON.parse(localStorage.getItem('access_token')))
    
  }

  return localStorage.getItem('access_token') ? 
  <div className='protectedContainer'>
      {/* Header - Spans Full Width */}
      <div className='header'>
        <div className='hamburger'>
         <div className='insideHam' onClick={()=>setIsOpen(!isOpen)}>
           <MenuIcon/>
          {
            isOpen && (
              <div className='menuLIst'>
              <SideMobile/>
              </div>
            )
          }
         </div>
        </div>
        <div></div>
        <div className='rightSection'>
         <HeaderProfile/>
        </div>
      </div>
      {JSON.parse(localStorage.getItem('access_token')).role !== 1 ?
      <div className="logo-container-role">
        <img src={localStorage.getItem("logo")} alt="Logo" className="logo-role" />
      </div> :
      <div className="logo-container">
        <img src="/assets/images/ecg_logo.svg" alt="Logo" className="logo" />
      </div>
      }

      {/* Main Content Area */}
      <div className='main-content'>
        {/* Sidebar */}
        <div className='sidebar'>
          <Sidebar/>
        </div>

        {/* Content Section */}
        <div className='content'>
          {children}
        </div>
      </div>
    </div> 
  : 
  <Navigate to="/login" replace />;
};

export default ProtectedRoute;
