import React, { useEffect, useState } from "react";
import Header from "../../../../Components/Header";
import DynamicTable from "../../../../Components/Table";
import CommonModal from "../../../../Components/Modals/index";
import { BASE_DOMAIN, columns, rows } from "../../../../utils/constants";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import { useDeleteCompanyMutation, useFetchcompanyQuery, useToggleCompanyMutation } from "../../../../features/Group/groupSlice";
import { DeleteOutlineOutlinedIcon, EditOutlinedIcon, ToggleOffOutlinedIcon, ToggleOnIcon, KeyboardArrowDownIcon } from "../../../../utils/icons";
import { Modal } from "@mui/material";
import "./CompantManagementTable.scss";
import ReportTableGeneration from "../../../../Components/ReportTable";

const CompantManagementTable = () => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [modalData, setModalData] = useState({
    title: "",
    description: "",
    icon: "",
  });
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [dropdown, setDropdown] = useState([]);
  const [filters, setFilters] = useState({}); // Example filter
  // const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [row, setRow] = useState({});
  const [searchValue, setSearchValue] = useState("");
  // const [limit, setLimit] = useState(10);
  const [modal, setModal] = useState(false);
  //   const dynamicUrl = `${BASE_DOMAIN}companies/${id}`; // Replace with your actual URL
  const dynamicUrl = `${BASE_DOMAIN}companies/${JSON.parse(localStorage.getItem("group"))}`; // Replace with your actual URL
  const {
    data = { data: [] },
    error,
    isLoading,
    refetch,
  } = useFetchcompanyQuery({
    url: dynamicUrl, // Replace with the base endpoint URL
    filters: { ...filters },
  });

  console.log(data, "this is the data I want to see");

  const navigate = useNavigate();
  const [deleteCompany, { isLoading: delLoading, isSuccess, error: anyError }] = useDeleteCompanyMutation();
  const [toggleCompany, { isLoading: toggleLoading, isSuccess: togSuccess, isError }] = useToggleCompanyMutation();

  const handlePageChange = (page) => {
    setFilters({
      ...filters,
      page: page,
    });
    setCurrentPage(page);
  };

  // const handleRowsPerPageChange = (rows) => {
  //   setRowsPerPage(rows);
  //   setCurrentPage(1); // Reset to the first page
  // };

  console.log("DATA", data, isLoading, queryParams.get("grp"), queryParams.get("grpname"), filters);

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setFilters({ ...filters, search: value });
  };

  const handleRowValue = (val) => {
    setRow(val);
    if (val.status === "Inactive") {
      setDropdown([
        {
          name: "Edit Company",
          icon: <EditOutlinedIcon />,
          id: 1,
        },
        {
          name: "Delete Company",
          icon: <DeleteOutlineOutlinedIcon />,
          id: 2,
        },
        {
          name: "Make Group Active",
          icon: <ToggleOffOutlinedIcon />,
          id: 3,
        },
      ]);
    } else {
      setDropdown([
        {
          name: "Edit Company",
          icon: <EditOutlinedIcon />,
          id: 1,
        },
        {
          name: "Delete Company",
          icon: <DeleteOutlineOutlinedIcon />,
          id: 2,
        },
        {
          name: "Make Group Inactive",
          icon: <ToggleOffOutlinedIcon />,
          id: 3,
        },
      ]);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("vv", parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    const newLimit = parseInt(event.target.value, 10);
    setFilters((prevFilters) => ({
      ...prevFilters,
      limit: newLimit,
      page: 1, // Reset to the first page whenever rows per page changes
    }));
    setCurrentPage(1); // Reset to the first page whenever rows per page changes
  };

  const handleMenuItem = (val) => {
    if (val.id === 1) {
      navigate({
        pathname: "/sub/company/add-company",
        search: `?${createSearchParams({ id: id, isupdate: true, compid: row.id })}`,
      });
    }
    if (val.id === 2) {
      setModalData({
        title: "Delete Company",
        description: "Are you sure you want to delete this company?",
        icon: true,
      });

      setModal(true);
    }
    if (val.id === 3) {
      setModalData({
        title: row.status === "Inactive" ? "Make Group Active" : "Make Group Inactive",
        description: row.status === "Inactive" ? "Are you sure you want to make group active?" : "Are you sure you want to make group inactive?",
        icon: false,
      });

      setModal(true);
    }
  };

  const handleReset = () => {
    setFilters({
      page: 1,
      limit: 10,
    });
  };

  const handleFilter = (itm1 = [], itm2 = []) => {
    function replaceGasAndElectricIfPresent(inputArray) {
      console.log("INP", inputArray);
      if (inputArray.includes("Gas & electric")) {
        return inputArray.map((item) => (item === "Gas & electric" ? "GasandElectric" : item));
      }
      return inputArray;
    }

    const updatedEnergyTypes = replaceGasAndElectricIfPresent(itm2);
    console.log(updatedEnergyTypes, "UPD");

    if (itm1.length > 0 && updatedEnergyTypes.length > 0) {
      setFilters({
        ...filters,
        category: itm1,
        utilityType: itm2,
      });
    } else if (itm1.length > 0) {
      setFilters({
        ...filters,
        category: itm1,
      });
    } else if (updatedEnergyTypes.length > 0) {
      setFilters({
        ...filters,
        utilityType: updatedEnergyTypes,
      });
    } else {
      setFilters({
        page: 1,
      });
    }
  };

  useEffect(() => {
    console.log("Filters updated:", filters);
    refetch();
  }, [location.pathname, filters]);
  const handleSort = () => {
    setFilters({
      ...filters,
    });
  };

  const handleBack = () => {
    navigate("/sub/report");
  };
  const handleConfirmAction = async () => {
    try {
      if (modalData.title === "Delete Company") {
        const result = await deleteCompany(row.id).unwrap();
        refetch();
      } else {
        const result = await toggleCompany(row.id).unwrap();
        refetch();
      }
    } catch (err) {}
    setModal(false);
  };

  const years = Array.from({ length: 21 }, (_, i) => 2025 - i);
  const [genModal, setGenModal] = useState(false);
  const [reportType, setReportType] = useState("");
  const [selectReportType, setSelectReportType] = useState("");
  // const [activeTable, setActiveTable] = useState("");

  const handleAction1Click = () => {
    navigate({
      pathname: "/sub/company/add-company",
      search: `?${createSearchParams({ id: localStorage.getItem("group") })}`,
    });
  };

  const handleClose = () => {
    setGenModal(false);
  };

  const handleGenerate = () => {
    setReportType(selectReportType);
    handleClose();
  };

  const handleReportTypeChange = (evt) => {
    setSelectReportType(evt.target.value);
  };

  return (
    <div>
      <Header
        title={`${localStorage.getItem("name")}`}
        description={"description not decided yet"}
        searchValue={searchValue}
        isSearchDisable={false}
        btnText='Add Company'
        searchText='Search Company'
        isIcon={true}
        isFilter={true}
        //   isback={true}
        onFilterClick={handleFilter}
        onReset={handleReset}
        onSearchChange={handleSearchChange}
        onAction1Click={handleAction1Click}
        //   handleBack={handleBack}
      />
      {
        <DynamicTable
          columns={columns}
          rows={data?.data}
          isLoading={isLoading}
          onClick={handleSort}
          total={Math.ceil(data?.total / 10)}
          dropMenuList={dropdown}
          currentPage={currentPage}
          handleAction={handleRowValue}
          handleMenu={handleMenuItem}
          // page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }

      {console.log(reportType, "reportType")}
      {modal && (
        <CommonModal
          setIsOpenPopUp={setModal}
          open={modal}
          isLoading={delLoading || toggleLoading}
          handleDelete={handleConfirmAction}
          item={{
            title: modalData.title,
            description: modalData.description,
            icon: modalData.icon,
          }}
        />
      )}
      {/* {genModal && (
        <Modal open={genModal} onClose={handleClose} aria-labelledby='modal-title' aria-describedby='modal-description'>
          <div className='modal'>
            <div className='modal_container'>
              <div className='heading_container'>
                <p className='heading_values'>Select the Report type</p>
                <p className='heading_values_container'>
                  Choose the type of report you wish to generate from the options below. Each report type provides detailed insights tailored to your
                  needs.
                </p>
              </div>

              <div className='year_label'>Year</div>
              <div className='custom_select'>
                <select className='year_selector'>
                  <option value='' disabled>
                    Select Year
                  </option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <KeyboardArrowDownIcon className='dropdown_icon' />
              </div>

              <div className='year_label'>Report Type</div>
              <div className='custom_select'>
                <select className='year_selector' value={selectReportType} onChange={handleReportTypeChange}>
                  <option value='' disabled>
                    Select report type
                  </option>
                  <option value='table1'>Table 1</option>
                  <option value='table2'>Table 2</option>
                  <option value='table3'>Table 3</option>
                  <option value='table4'>Table 4</option>
                  <option value='table5'>Table 5</option>
                </select>
                <KeyboardArrowDownIcon className='dropdown_icon' />
              </div>

              <div className='modal_buttons'>
                <button className='generate_btn' onClick={handleGenerate}>
                  Generate
                </button>
                <button className='cancel_btn' onClick={handleClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )} */}
    </div>
  );
};

export default CompantManagementTable;
