import NotificationsIcon from "@mui/icons-material/Notifications";
import EmailIcon from "@mui/icons-material/Email";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PieChartIcon from "@mui/icons-material/PieChart";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import MailIcon from "@mui/icons-material/Mail";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockIcon from "@mui/icons-material/Lock";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import HttpsIcon from "@mui/icons-material/Https";
import BackupIcon from '@mui/icons-material/Backup';
import LockOpenIcon from '@mui/icons-material/LockOpen';
// import BackupIcon from "@mui/icons-material/Backup";
import DownloadIcon from "@mui/icons-material/Download";
// Define the common styles
const iconStyles = {
  position: "absolute",
  left: "0.521vw", // Position inside the input near the start
  top: "50%",
  transform: "translateY(-50%)",
  width: "1.042vw",
  height: "1.042vw",
  color: "#B3B3B3",
  pointerEvents: "none",
  "@media (max-width: 767px)": {
    display: "none",
  },
};

// Styled MailIcon
const StyledMailIcon = ({ emailVisible }) => (
  <MailIcon
    sx={{
      ...iconStyles,
      display: emailVisible ? "none" : "block",
    }}
  />
);

const StyledLockIcon = ({ passwordVisible }) => (
  <LockIcon
    sx={{
      ...iconStyles,
      display: passwordVisible ? "none" : "block",
    }}
  />
);

export {
  NotificationsIcon,
  EmailIcon,
  KeyboardArrowDownIcon,
  PieChartIcon,
  PeopleAltIcon,
  AnalyticsIcon,
  ImportExportIcon,
  DoneIcon,
  CloseIcon,
  SearchIcon,
  FilterListIcon,
  AddIcon,
  StyledMailIcon,
  StyledLockIcon,
  MoreVertIcon,
  EditOutlinedIcon,
  DeleteOutlineOutlinedIcon,
  ToggleOffOutlinedIcon,
  MenuIcon,
  PersonIcon,
  LogoutIcon,
  PriorityHighIcon,
  ToggleOffIcon,
  ToggleOnIcon,
  ArrowDropDownIcon,
  ArrowDropUpIcon,
  KeyboardBackspaceIcon,
  HttpsIcon,
  VisibilityIcon,
  LockIcon,
  BackupIcon,
  LockOpenIcon,
  DownloadIcon,
};
