import React, { useCallback, useEffect, useState } from "react";
import axios from "axios"
import "./DataCard/index.scss";
import Header from "./../../../Components/Header/index";
// import DataCard from "../../../Components/DataCard";
import { CircularProgress, Modal, Skeleton } from "@mui/material";
import DataCard from "./DataCard";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import Papa from "papaparse";
import * as XLSX from 'xlsx';
import { filterOutValidKeys, mergeArrayToObject, transformData } from "../../../utils/helpers";
import { headerMappingData } from "../../../utils/constants";
import { useFetchreportQuery, useGetapiQuery, useSetrecordMutation } from "../../../features/Input/inputSlice";
import { useToast } from "../../ToastProvider";
import { isVisible } from "@testing-library/user-event/dist/cjs/utils/index.js";

const Report = () => {
  const [openPopUp, setOpenPopUp] = useState(false);
  const location  = useLocation()
  const [apiData, setApiData] = useState()
  const [csvData, setCsvData] = useState([]);
  const [data1, setData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [fileType, setFileType] = useState("");
  const { showSuccess, showError } = useToast();
  const [selectedFile, setSelectedFile] = useState(null);
  const [reportList,setReportList] = useState([])
  const [filter, setFilter] = useState(""); // Example state for filters
  const reportId = JSON.parse(localStorage.getItem('id')); // Replace with dynamic ID as needed
  const emptyArray = Array(6).fill(undefined);

  // Using the query hook
  const {  data={data:[]} , error:fetchError, isLoading,refetch } = useFetchreportQuery( filter && filter.length > 0 
    ? `${reportId}?year=${filter}`
    : `${reportId}`);
  // const [setrecord, { isLoading, isSuccess, isError }] = useSetrecordMutation();
  const [error, setError] = useState('');

  console.log("DATT",data)

  // useEffect(() => {
  //   console.log("Data: ", data);
  //   console.log("Is Loading:", isLoading);
  //   console.log("Error:", fetchError);
  // }, [data, isLoading, fetchError]);

  // console.log("hg", data)

    useEffect(() => {
      refetch();
    }, [location.pathname]);

  const navigate = useNavigate()

  const handleAdd = () => {
    setOpenPopUp(true);
  };

  const handleClose = () => {
    setOpenPopUp(false);
  };

  console.log("APPI", apiData)

  const downloadCSV = () => {
    // Define the header mapping
    const headerMapping = {
      year: "Year",
      category: "Category",
      company_name: "Company Name",
      company_size: "Company Size",
      type_of_utility: "Type of Utility",
      total_spend: "Total Spend",
      total_dbe: "Total DBE",
      total_dbe_percent: "Total DBE %",
      mbe_spend: "MBE Spend",
      mbe_percent: "MBE %",
      mbe_total_direct_spend: "MBE Total Direct Spend",
      mbe_total_sub_spend: "MBE Total Sub Spend",
      mbe_african_american_spend: "MBE (African American) Spend",
      mbe_african_american_number: "MBE (African American) #",
      mbe_african_american_percent: "MBE (African American) %",
      mbe_african_american_direct_spend: "MBE (African American) Direct Spend",
      mbe_african_american_sub_spend: "MBE (African American) Sub Spend",
      mbe_asian_pacific_american_spend: "MBE(Asian Pacific American) Spend",
      mbe_asian_pacific_american_number: "MBE (Asian Pacific American) #",
      mbe_asian_pacific_american_percent: "MBE (Asian Pacific American) %",
      mbe_asian_pacific_american_direct_spend: "MBE (Asian Pacific American) Direct Spend",
      mbe_asian_pacific_american_sub_spend: "MBE (Asian Pacific American) Sub Spend",
      mbe_native_american_spend: "MBE (Native American) Spend",
      mbe_native_american_number: "MBE (Native American) #",
      mbe_native_american_percent: "MBE (Native American) %",
      mbe_native_american_direct_spend: "MBE (Native American) Direct Spend",
      mbe_native_american_sub_spend: "MBE (Native American) Sub Spend",
      mbe_hispanic_american_spend: "MBE (Hispanic American) Spend",
      mbe_hispanic_american_number: "MBE (Hispanic American) #",
      mbe_hispanic_american_percent: "MBE (Hispanic American) %",
      mbe_hispanic_american_direct_spend: "MBE (Hispanic American) Direct Spend",
      mbe_hispanic_american_sub_spend: "MBE (Hispanic American) Sub Spend",
      wmbe_spend: "WMBE Spend",
      wmbe_number: "WMBE #",
      wmbe_percent: "WMBE %",
      direct_spend_wmbe: "Direct Spend (WMBE)",
      sub_spend_wmbe: "Sub Spend (WMBE)",
      wbe_spend: "WBE Spend",
      wbe_number: "WBE #",
      wbe_percent: "WBE %",
      wbe_direct_spend: "WBE Direct Spend",
      wbe_sub_spend: "WBE Sub Spend",
      pbdbe_spend: "PBDBE Spend",
      pbdbe_number: "PBDBE #",
      pbdbe_percent: "PBDBE %",
      pbdbe_direct_spend: "PBDBE Direct Spend",
      pbdbe_sub_spend: "PBDBE Sub Spend",
      lgbtbe_spend: "LGBTBE Spend",
      lgbtbe_number: "LGBTBE #",
      lgbtbe_percent: "LGBTBE %",
      lgbtbe_direct_spend: "LGBTBE Direct Spend",
      lgbtbe_sub_spend: "LGBTBE Sub Spend",
      dvbe_spend: "DVBE Spend",
      dvbe_number: "DVBE #",
      dvbe_percent: "DVBE %",
      dvbe_direct_spend: "DVBE Direct Spend",
      dvbe_sub_direct_spend: "DVBE Sub Spend",
      other_8a_spend: "Other 8 (a) Spend",
      other_8a_percent: "Other 8 (a) %",
      other_8a_number: "Other 8 (a) #",
      other_8a_direct_spend: "Other 8 (a) Direct Spend",
      other_8a_sub_spend: "Other 8 (a) Sub Spend",
      total_direct_spend: "Total Direct Spend",
      total_direct_percent: "Total Direct %",
      total_direct_number_suppliers: "Total Direct # Suppliers",
      total_sub_spend: "Total Sub Spend",
      total_sub_number: "Total Sub #",
      total_sub_percent: "Total Sub %",
      new_diverse_suppliers_dollar: "New Diverse Suppliers $",
      new_diverse_suppliers_number: "New Diverse Suppliers #",
      rev_under_1m_number: "Rev - Under $1M -#",
      rev_under_1m_dollar: "Rev - Under $1M - $",
      rev_under_5m_number: "Rev - Under $5M -#",
      rev_under_5m_dollar: "Rev - Under $5M - $",
      rev_under_10m_number: "Rev - Under $10M -#",
      rev_under_10m_dollar: "Rev - Under $10M - $",
      rev_above_10m_number: "Rev - Above $10M -#",
      rev_above_10m_dollar: "Rev - Above $10M - $",
      grand_total_dollar: "Grand Total $",
      grand_total_number: "Grand Total #",
      diverse_suppliers_ca_number: "9.1.2 Description of Diverse Suppliers with Majority Workforce in California (# OF DIVERSE SUPPLIERS IN CA)",
      workforce_board_women: "WORKFORCE AND BOARD DIVERSITY (# AND % OF WOMEN)",
      workforce_board_ppl_of_color: "WORKFORCE AND BOARD DIVERSITY (# AND % OF PPL OF COLOR)",
      total_board_directors_number: "Total number of boards of Directors"

    };

    // Filter and map visible fields
    const visibleFields = Object.entries(apiData)
      .filter(([key, value]) => value?.visible) // Only include fields where `visible` is true
      .map(([key]) => key);

    const customFields = apiData.customFields || [];
    const customHeaders = customFields.map((field) => field.fieldName);

    // Create CSV headers and rows
    const headers = [
      visibleFields.map((key) => headerMapping[key] || key),
      ...customHeaders,
    ]
    const fullyFlattenedOutput = headers.flat(Infinity);
    console.log(headers,">>>>>>>")

    // const csvContent = [headers].map((row) => row.join(",")).join("\n")


    const worksheet = XLSX.utils.json_to_sheet([], { header: fullyFlattenedOutput });
  
  // Create a new workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Export the workbook to a Blob and trigger download
  XLSX.writeFile(workbook, "data.xlsx");
    // const rows = [visibleFields.map((key) => key)];

    // Combine headers and rows
    // const csvContent = [headers].map((row) => row.join(",")).join("\n");

    // // Create a Blob and a downloadable link
    // const blob = new Blob([csvContent], { type: "text/csv" });
    // const url = URL.createObjectURL(blob);

    // // Trigger the download
    // const a = document.createElement("a");
    // a.href = url;
    // a.download = "data.csv";
    // a.click();

    // // Clean up
    // URL.revokeObjectURL(url);
  };

  const refreshData = useCallback(() => {
      refetch()
      //  setSearchValue("Ab")
    }, []);

  // Handle drag-and-drop
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];

    if (!file) return;

    if (
      ![
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ].includes(file.type)
    ) {
      setError("Only .csv and .xlsx files are allowed.");
      setSelectedFile(null);
      // showError({
      //   body:"Only .csv and .xlsx files are allowed."
      // })
    } else if (file.size > 10 * 1024 * 1024) {
      setSelectedFile(null);
      // showError({
      //   body:"File size should not exceed 10MB."
      // })
      setError("File size should not exceed 10MB.");
    } else {
      setSelectedFile(file);
      // processFile(file);
      const fileType = file.type;

      // Handle CSV files
      if (fileType === 'text/csv') {
        setFileType("CSV");
        Papa.parse(file, {
          header: true, // If the CSV has headers
          skipEmptyLines: true,
          complete: (result) => {
            console.log("RET", result.data)
            const newArray = filterOutValidKeys(result.data);
            console.log(newArray);
            // console.log(mergedArray, "Merged");
            const transformedData = transformData(mergeArrayToObject(result.data), headerMappingData);
            console.log(transformedData, "TRNASCSV");
            setData(result.data); // Parsed data
          },
        });
      }
      // Handle Excel (.xlsx) files
      else if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const reader = new FileReader(); reader.onload = (e) => {
          const data = new Uint8Array(e.target.result); const workbook = XLSX.read(data, { type: "array" }); // Read the first sheet
          const sheetName = workbook.SheetNames[0]; const worksheet = workbook.Sheets[sheetName]; // Convert the sheet to JSON
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          console.log("Excel Data:", jsonData); // Log the parsed data
          const mergedArray = jsonData[0].reduce((result, key, index) => {
            if (index < jsonData[1].length) {
              result.push({ [key]: jsonData[1][index] });
            } else {
              result.push({ [key]: null }); // Handle cases where values are missing
            }
            return result;
          }, []);



          const final = [Object.assign({}, ...mergedArray)];

          console.log(final);

          console.log(final, "Merged");
          setData(final)
          const transformedData = transformData(mergeArrayToObject(mergedArray), headerMappingData);
          console.log(transformedData, "TRNAS");
        };
        reader.readAsArrayBuffer(file); // Read file as an ArrayBuffer
      }

    }

    e.target.value = ''
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    if (!['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type)) {
      setError('Only .csv and .xlsx files are allowed.');
      // showError({
      //   body:"Only .csv and .xlsx files are allowed."
      // })
      setSelectedFile(null);
    } else if (file.size > 10 * 1024 * 1024) {
      // showError({
      //   body:"File size should not exceed 10MB."
      // })
      setError('File size should not exceed 10MB.');
      setSelectedFile(null);
    } else {
      setError('');
      // console.log("FILL", file)
      setSelectedFile(file);

      const fileType = file.type;

      // Handle CSV files
      if (fileType === 'text/csv') {
        setFileType("CSV");
        Papa.parse(file, {
          header: true, // If the CSV has headers
          skipEmptyLines: true,
          step: (row, parser) => {
            console.log("First Row Data:", row.data); // Log the first row
            parser.abort(); // Stop parsing after the first row
            setData([row.data])
          // complete: (result) => {
          //   console.log("RET", result.data)
          //   const newArray = filterOutValidKeys(result.data);
          //   console.log(newArray);
          //   // console.log(mergedArray, "Merged");
          //   const transformedData = transformData(mergeArrayToObject(result.data), headerMappingData);
          //   console.log(transformedData, "TRNASCSV");
          //   setData(result.data); // Parsed data
          },
          transform: (value, columnName) => {
            // Try converting to a number if the value looks numeric
            const parsedValue = parseFloat(value);
            return isNaN(parsedValue) ? value : parsedValue;
          },
        });
      }
      // Handle Excel (.xlsx) files
      else if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const reader = new FileReader(); reader.onload = (e) => {
          const data = new Uint8Array(e.target.result); const workbook = XLSX.read(data, { type: "array" }); // Read the first sheet
          const sheetName = workbook.SheetNames[0]; const worksheet = workbook.Sheets[sheetName]; // Convert the sheet to JSON
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          console.log("Excel Data:", jsonData); // Log the parsed data
          const mergedArray = jsonData[0].reduce((result, key, index) => {
            if (index < jsonData[1].length) {
              result.push({ [key]: jsonData[1][index] });
            } else {
              result.push({ [key]: null }); // Handle cases where values are missing
            }
            return result;
          }, []);



          const final = [Object.assign({}, ...mergedArray)];

          console.log(final);

          // console.log(final, "Merged");
          setData(final)
          const transformedData = transformData(mergeArrayToObject(mergedArray), headerMappingData);
          // console.log(transformedData, "TRNAS");
        };
        reader.readAsArrayBuffer(file); // Read file as an ArrayBuffer
      }

    }

    e.target.value = ''
  };

  const handleUploadApi = async () => {
      // const newArray = filterOutValidKeys(data1);
      // console.log("NEWARR",newArray)
      // const finalData = 
      // const mergedObj = Object.assign({}, data1, arr[0]);
      console.log("ldkjf",data1)
    navigate("/end/data/add-data", { state: { data1 } });
    // try {
    //   console.log("data", data1)
    //   const newArray = filterOutValidKeys(data1);
    //   const transformedData = transformData(mergeArrayToObject(data1), headerMappingData);

    //   console.log("kdfjsfk", newArray, transformedData)

    //   // Add customFields to the object
    //   const result = {
    //     groupId: localStorage.getItem('group'),
    //     companyId: localStorage.getItem('id'),
    //     ...transformedData,
    //     customFields: newArray,
    //   };
    //   setLoading(true)
    //   const resultData = await setrecord(result).unwrap(); // Call the mutation
    //   setLoading(false)
    //   showSuccess({
    //     body: "Record added successfully"
    //   })
    //   setSelectedFile(null)
    //   setData([])
    //   handleClose()
    // } catch (err) {
    //   showError({
    //     title: 'Error!',
    //     body: err?.data?.message || err?.status || "something went wrong"
    //   });
    // }
  }

  console.log(data1,"DT")


  const fetchApi = async () => {
    try {
      // const response = await axios.get(`https://devapiecg.resourcifi.tech/inputlayout/getlayout/99990`);
      const response = await axios.get(`https://devapiecg.resourcifi.tech/inputlayout/getlayout/${JSON.parse(localStorage.getItem('group'))}`);
      // Simulating setting headlist with new data
      setApiData(response?.data)
    } catch (error) {
      console.error("Error fetching layout data:", error);
      throw error;
    }
  };

  // const fetchRepotList = async ()=>{
  //   try{
  //     const response = await axios.get(`https://devapiecg.resourcifi.tech/inputRecord/getInputRecordsByCompanyId/${JSON.parse(localStorage.getItem('id'))}`);
  //     console.log("RESP",response)
  //     setReportList(response?.data)
  //   }catch(err){

  //   }
  // }

  console.log("ERR",error)

  const handleFilter = (data)=>{
    // console.log(data,"DTT")
    setFilter(JSON.stringify(data))
    
  }

  const handleReset =()=>{
    setFilter("")
  }

  useEffect(() => {
    fetchApi()
    // fetchRepotList()
  }, [])
  return (
    <div className='main'>
      <Header
        title={"Data Management"}
        description='Here, you can manage all the data of your company.'
        isSearchField={false}
        isCancel={false}
        isYear = {true}
        isFilter = {true}
        btnText={"Add Data"}
        onFilterClick={handleFilter}
        onReset={handleReset}
        // btnLoading = {id? uploadLoading : isLoading}
        isIcon={true}
        onAction1Click={handleAdd}
      // handleCancel={handleCancelClick}
      />
      <div className='card_container_div' id='modal_body'>
        {/* {
           
          reportList?.map((itm,ix)=>{
             return(<DataCard itm={itm}/>)
          }) 
        } */}
        {!isLoading && data?.length > 0 ? (
          data?.map((el, index) => {
            return <DataCard itm={el}  refreshData={refreshData}/>;
          })
        ) :
        (isLoading ? 
        <div className="grp-Content-skel">
        {
          emptyArray?.map((itm,ix)=>{
            return(<Skeleton variant="rectangular" width={"15.521vw"} height={"9.375vw"} sx={{ borderRadius: '8px' }}/>)
          })
        }
        </div> 
        : 
        (
          <div className="no-grp-fd"
          >No Group Found</div>
        )
        )
        }
        {/* {Array(5)
          .fill(0)
          .map((el) => {
            return <DataCard />;
          })} */}
      </div>
      {openPopUp && (
        <Modal open={openPopUp} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
          <div className='modal'>
            <div className='modal_container'>
              <div className='upper_div'>
                <div className='upper_one'>
                  <div className='upper_text'>
                    <h1>Upload Data</h1>
                    <p>You can choose to upload your data as a file or input it manually.</p>
                  </div>
                </div>
                <div
                  className='upper_two'
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <div className='img_icon'>
                    <img src='/assets/images/fileicondark.svg' alt='Vector.png' />
                  </div>
                  <h4>Drop files here or click to upload.</h4>
                  <p>Upload up to 10MB (Formats accepted are .csv and .xlsx)</p>
                  <input
                    type='file'
                    accept='.csv, .xlsx'
                    // multiple
                    onChange={handleFileChange}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      border: "1px solid red",
                      opacity: 0,
                      cursor: 'pointer',
                    }}
                  />

                  {/* {selectedFile && (
                    <div style={{ marginTop: '20px' }}>
                      <h5>Selected File:</h5>
                      <p>{selectedFile.name}</p>
                    </div>
                  )} */}
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {selectedFile && (
                  <div className='uploaded_file'>
                    <div className='file_details'>
                      <div className='uploaded_img_icon'>
                        <img src='/assets/images/fileicondark.svg' alt='Vector.png' />
                      </div>
                      <div className='details_desc'>
                        <p className='file_desc'>{selectedFile.name}</p>
                        <p className='file_size'>{`Size: ${selectedFile.size}`}</p>
                      </div>
                    </div>
                    <div className='delete_file' onClick={() => { setSelectedFile(null); setData([]) }}>
                      <div className='delete_img_icon'>
                        <img src='/assets/images/delRe.svg' alt='Vector.png' />
                      </div>
                    </div>
                  </div>)
                }
                <hr />
                <div className='template'>
                  <p style={{ lineHeight: "22px", letterSpacing: ".2px" }}>If you don't know the standard format, please use the template attached.</p>
                  <button className='download_template'>
                    <img src='/assets/images/fileicongreen.svg' alt='Vector.png' />
                    <p className='download_desc' onClick={downloadCSV}>Download Template</p>
                  </button>
                </div>
              </div>
              <hr />
              <div className='lower_div'>
                <div className='left_buttons'>
                  {!loading ?
                    <button className='upload_btn' onClick={handleUploadApi} disabled={!data1.length > 0}>Upload</button> :
                    <div className='headerButtonData'>
                      <CircularProgress size={14} sx={{ color: "#ffffff" }} />
                    </div>
                  }
                  <button className='cancel_btn' onClick={handleClose}>
                    Cancel
                  </button>
                </div>
                <div className='right_buttons'>
                  <button className='enter_data' onClick={() => navigate("/end/data/add-data",{ state: { isView:false } })}>Enter Data Manually</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Report;





















// import React from 'react'
// import "./index.scss"
// import Header from '../../../Components/Header'
// import { useNavigate } from 'react-router-dom'

// const DataManagement = () => {
//   const navigate = useNavigate()

//   const handleAction1Click = ()=>{
//     navigate("")
//   }
//   return (
//     <div className='grp_container'>
//     <Header
//       title='Data Management'
//       description="Here, you can manage all the data’s of your company."
//       // searchValue={searchValue}
//       // isSearchDisable={data?.data?.length < 1 && !searchValue}
//       btnText='Add Data'
//       searchText='Search Group'
//       isIcon={true}
//       // isFilter={true}
//       // onSearchChange={handleSearchChange}
//       onAction1Click={handleAction1Click}
//     />
//     </div>
//   )
// }

// export default DataManagement




