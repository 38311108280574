import React, { useEffect, useState } from 'react'
import "./addInput.scss"
import { CloseIcon } from '../../../../utils/icons'
import Dropdown from '../../../../Components/Dropdown'
import { FIELD_TYPE } from '../../../../utils/constants'
import { nameValidation } from '../../../../utils/validations'
import InputError from '../../../../Components/InputError'

const AddInput = ({ isContent, setIsContent, onAdd = () => { }, isEdit = false, row = {}, onEdit = () => { } }) => {
    console.log("ROW", row)
    const [input, setInput] = useState("alphabet");
    const [validation, setValidation] = useState({
        name: "",
        place: "",
    })
    const [inputs, setInputs] = useState([""]);
    const [field, setField] = useState({
        name: "",
        placeholder: ""
    })
    const handleChange = (e) => {
        setInput(e)
    }
    const handleAddInput = () => {
        setInputs([...inputs, ""]);
    };
    const handleRemoveInput = () => {
        setInputs(inputs.slice(0, -1));
    };

    const handleInputChange = (index, event) => {
        const newInputs = [...inputs];
        newInputs[index] = event.target.value;
        setInputs(newInputs);
    };

    const handleFieldChange = (e) => {
        setField({
            ...field,
            name: e.target.value
        })
    }
    const handlePlaceChange = (e) => {
        setField({
            ...field,
            placeholder: e.target.value
        })
    }

    const handleClk = () => {
        const fieldValidation = nameValidation(field.name, "Field Name")
        const placeValidation = nameValidation(field.placeholder, "Placeholder")
        if (input === "dropdown") {
            if (fieldValidation.isValid && placeValidation.isValid && inputs.length > 0) {
                setValidation({
                    name: "",
                    place: "",
                })
                onAdd(input, field.name, field.placeholder, inputs)
                setIsContent(false)
            }
            else {
                setValidation({
                    name: fieldValidation.message,
                    place: placeValidation.message
                })
            }
        } else {
            if (fieldValidation.isValid && placeValidation.isValid) {
                setValidation({
                    name: "",
                    place: "",
                })
                onAdd(input, field.name, field.placeholder)
                setIsContent(false)
            }
            else {
                setValidation({
                    name: fieldValidation.message,
                    place: placeValidation.message
                })
            }
        }
        // if (isEdit) {
        //     onEdit(input, field.name, field.placeholder)
        // } else {
        //     onAdd(input, field.name, field.placeholder,inputs)
        // }
    }
    useEffect(() => {
        if (isEdit) {
            setInput(row.val.isDropdown ? "dropdown" : "text")
            setField({
                name: row.val.label,
                placeholder: row.val.place
            })
        }
    }, [])


    return (
        <div className='inpContainerAdd'>
            <div className='headerAddCont'>
                <div className='titDesCont'>
                    <div className='title'>Add Input Field</div>
                    <div className='desc'>Add a new input field</div>
                </div>
                <div className='closeDiv' onClick={() => setIsContent(false)}>
                    <CloseIcon style={{ height: "14px", width: "14px" }} />
                </div>
            </div>
            <div className='addContentInp' id='modal_body'>
                <div class="custom-container">
                    Create a custom input field to enhance your data and collect specific data effectively. Simply follow the steps to configure the field type, name, and placeholder text.
                </div>
                {/* <div className='textSty'>Create a custom input field to enhance your data and collect specific</div>
                <div className='textSty'>data effectively. Simply follow the steps to configure the field type,</div>
                <div className='textSty'>name, and placeholder text.</div> */}

                <div style={{ marginTop: "1.667vw", width: "100%" }}>
                    <Dropdown
                        label={"Field Type"}
                        options={FIELD_TYPE}
                        value={input}
                        onChange={(e) => handleChange(e)}
                        placeholder="Select type"
                        isInput={true}
                    //  isError={validation.status.length}
                    //  errorMessage = {validation.status}
                    />
                    {input !== "dropdown" ?
                        <>
                            <div style={{ marginTop: "1.25vw" }}>Field Name</div>
                            <div>
                                <input
                                    type="text"
                                    placeholder={"Enter Field Name"}
                                    className={`input-styling ${validation.name.length ? "error" : ""}`}
                                    //   className='input-styling' 
                                    value={field.name}
                                    onChange={handleFieldChange} />
                                {validation.name.length ? <InputError text={validation.name} /> : <div></div>}
                                {/* <input type="text" placeholder={"Enter Field Name"} className='input-styling' value={field.name} onChange={handleFieldChange} /> */}
                            </div>
                            <div style={{ marginTop: "1.25vw" }}>Placeholder Text</div>
                            <div>
                                <input
                                    type="text"
                                    placeholder={"Enter placeholder name"}
                                    className={`input-styling ${validation.place.length ? "error" : ""}`}
                                    //   className='input-styling' 
                                    value={field.placeholder}
                                    onChange={handlePlaceChange} />
                                {validation.place.length ? <InputError text={validation.place} /> : <div></div>}
                                {/* <input type="text" placeholder={"Enter placeholder name"} className='input-styling' value={field.placeholder} onChange={handlePlaceChange} /> */}
                            </div>
                        </> :
                        <>
                            {/* <button onClick={handleAddInput}>Add Input Field</button> */}
                            <div style={{ marginTop: "1.25vw" }}>Field Name</div>
                            <div>
                                <input
                                    type="text"
                                    placeholder={"Enter Field Name"}
                                    className={`input-styling ${validation.name.length ? "error" : ""}`}
                                    //   className='input-styling' 
                                    value={field.name}
                                    onChange={handleFieldChange} />
                                {validation.name.length ? <InputError text={validation.name} /> : <div></div>}
                            </div>
                            <div style={{ marginTop: "1.25vw" }}>Placeholder Text</div>
                            <div>
                                <input
                                    type="text"
                                    placeholder={"Enter placeholder name"}
                                    className={`input-styling ${validation.place.length ? "error" : ""}`}
                                    //   className='input-styling' 
                                    value={field.placeholder}
                                    onChange={handlePlaceChange} />
                                {validation.place.length ? <InputError text={validation.place} /> : <div></div>}
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                <div style={{ marginTop: "24px", width: "60px", display: "flex", justifyContent: "center", alignItems: "center" }} className='add' onClick={handleAddInput}>Add field</div>
                                <div style={{ marginTop: "24px", width: "100px", display: "flex", justifyContent: "center", alignItems: "center" }} className='add' onClick={handleRemoveInput}>Remove Field</div>
                            </div>

                            {inputs.map((inputVal, index) => (
                                <div key={index}>
                                    <input
                                        type="text"
                                        value={inputVal}
                                        onChange={(e) => handleInputChange(index, e)}
                                        placeholder={`Input ${index + 1}`}
                                        className='input-styling'
                                    />
                                </div>
                            ))}
                        </>
                    }
                </div>
                <div style={{ marginTop: "1.667vw", display: "flex", alignItems: "center", gap: "8px" }}>
                    <div className='cncl' onClick={() => setIsContent(false)}>Cancel</div>
                    <div className='add' onClick={handleClk}>{isEdit ? "Edit" : "Add"}</div>
                </div>
            </div>

        </div>
    )
}

export default AddInput