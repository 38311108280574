import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, Skeleton, Tooltip } from "@mui/material";
import { ArrowDropDownIcon, ArrowDropUpIcon, KeyboardArrowDownIcon } from "../../utils/icons";
import "./table.scss";
import Pagination from "../Pagination";
import CommonMenu from "../Menu";
import { useNavigate } from "react-router-dom";

function DynamicTable({
  columns,
  rows,
  isLoading = false,
  limit = 10,
  onClick = () => {},
  handleMenu = () => {},
  handleAction = () => {},
  total = 0,
  dropMenuList = [],
  currentPage,
  onPageChange = () => {},
  onRowsPerPageChange = () => {},
  //  page=1,
  rowsPerPage = 10,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [sortClick, setSortClick] = useState(false);
  const [menuRow, setMenuRow] = useState({});
  const navigate = useNavigate();

  // const [currentPage, setCurrentPage] = useState(1);
  // let totalPages = total/10 + 1
  // Example total pages
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setMenuRow(row);
  };

  const handleActionClick = (row) => {
    handleAction(row);
    console.log("handleActionClick's parameter", row);

    // navigate("/super/dashboard");
    // console.log("Action button clicked!");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSort = (columnId) => {
    setSortClick(true);
    const isAsc = orderBy === columnId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(columnId);
  };

  const sortedRows = React.useMemo(() => {
    if (!orderBy) return rows;
    return rows.slice().sort((a, b) => {
      const valA = a[orderBy];
      const valB = b[orderBy];
      if (valA < valB) return order === "asc" ? -1 : 1;
      if (valA > valB) return order === "asc" ? 1 : -1;
      return 0;
    });
  }, [order, orderBy, rows]);

  const returnSubAdminDivForTable = (subAdminArray, row) => {
    const firstSubAdmin = subAdminArray?.[0]?.name || "N/A";
    const remainingSubAdmins = subAdminArray?.slice(1);

    return (
      <div>
        {firstSubAdmin}
        {remainingSubAdmins?.length > 0 && (
          <Tooltip title={remainingSubAdmins.map((subAdmin) => subAdmin.name).join(", ")} arrow>
            <span className='subAdmin_tooltip' style={{ marginLeft: "8px", color: "#003065", cursor: "pointer" }}>
              +{remainingSubAdmins.length}
            </span>
          </Tooltip>
        )}
      </div>
    );
  };

  console.log(sortedRows, "ROWS");

  return (
    <Paper>
      <TableContainer
        id='modal_body'
        className='tableContCommon'
        //  sx={{height:"calc(100vh - 275px)"}}
      >
        <Table sx={{ width: "100%" }} aria-label='customized table' stickyHeader>
          <TableHead>
            <TableRow className='tableRowStyling'>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  sx={{ width: column.minWidth, padding: 0, height: "auto", whiteSpace: "nowrap" }}
                  onClick={() => column.sortable && handleSort(column.id)}>
                  <div className='headerStyle' onClick={!column.sortable ? () => {} : onClick}>
                    <div className='txtLabel'>{column.label}</div>
                    {column.sortable && (
                      <div className='padStyle'>
                        {!sortClick || orderBy !== column.id ? (
                          <img
                            src='/assets/images/Sort.svg'
                            alt='Sort
                    '
                          />
                        ) : order === "asc" ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </div>
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              Array.from({ length: limit }).map((_, index) => (
                <TableRow key={index}>
                  {columns.map((column, colIndex) => (
                    <TableCell key={colIndex} sx={{ padding: 0 }}>
                      <Skeleton variant='text' width='80%' height={20} sx={{ margin: "10px 20px" }} />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align='center' sx={{ padding: "20px", border: "none", height: "calc(100vh - 350px)" }}>
                  <div style={{ color: "#4D4D4D" }}>No record found</div>
                </TableCell>
              </TableRow>
            ) : (
              sortedRows.map((row) => (
                <TableRow key={row.id} sx={{ borderBottom: "1px solid #F6F6F6" }}>
                  {columns.map((column) => (
                    <TableCell
                      key={`#${column.id}`}
                      sx={{
                        padding: 0, // Centering text in the table body
                      }}>
                      {column.id === "groupId" || column.id === "companyId" ? (
                        <div style={{ padding: "15px 24px" }}>
                          <div>#{row[column.id]}</div>
                        </div>
                      ) : column.id === "status" ? (
                        <div style={{ padding: "15px 24px" }}>
                          <div className={`${row[column.id] === "Active" ? "tableStatus" : "tableStatusInactive"}`}>{row[column.id]}</div>
                        </div>
                      ) : column.id === "isActive" ? (
                        <div style={{ padding: "15px 24px" }}>
                          <div className={`${row[column.id] ? "tableStatus" : "tableStatusInactive"}`}>{row[column.id] ? "Active" : "Locked"}</div>
                        </div>
                      ) : column.id === "actions" ? (
                        <div
                          style={{ padding: "0.625vw 1.25vw", cursor: "pointer" }}
                          onClick={(e) => {
                            handleClick(e, row);
                            handleActionClick(row); // Handle the action and navigate
                          }}>
                          <div className='actionStyling'>
                            <div>Actions</div>
                            <div>
                              <KeyboardArrowDownIcon style={{ height: "14px", width: "14px" }} />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div style={{ padding: "15px 24px", whiteSpace: "nowrap" }}>
                          {/* {console.log(row[column.id][0])} */}
                          {row[column.id]?.length > 20 ? (
                            <Tooltip title={row[column.id]?.length > 20 ? row[column.id] : ""} arrow>
                              <div>{row[column.id].slice(0, 20)}...</div>
                            </Tooltip>
                          ) : column.id === "subAdminEmails" ? (
                            // <Tooltip title={column.id === "subAdminEmails" && row[column.id]?.length > 1 ? row[column.id] : ""} arrow>
                            // {/* <div>{row[column.id][0]}</div> */}
                            <div>{returnSubAdminDivForTable(row[column.id], row)}</div>
                          ) : (
                            // </Tooltip>
                            <div>{row[column.id] === "GasandElectric" ? "Gas & Electric" : row[column.id]}</div>
                          )}
                          {/* {row[column.id]} */}
                        </div>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className='footer'>
        <Pagination currentPage={currentPage} totalPages={total} onPageChange={onPageChange} />
        <TablePagination
          component='div'
          className='rightPagination'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={currentPage - 1}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          labelRowsPerPage='Result per page:' // Custom "Rows per page" text
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`}
        />
      </div>
      <CommonMenu anchorEl={anchorEl} open={open} onClose={handleClose} onClick={handleMenu} dropdownItems={dropMenuList} />
    </Paper>
  );
}

export default DynamicTable;
