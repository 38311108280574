  import React, { useEffect, useState } from 'react'
  import axios from 'axios'
  import "./input-temp.scss"
  import { ArrowDropDownIcon, ArrowDropUpIcon } from '../../../../utils/icons'
  import CommonField from '../CommonField'
  import AddInput from '../AddInput'
  import { useSetrecordMutation, useSetsaveMutation, useUpdateRecordMutation } from '../../../../features/Input/inputSlice'
  import { useToast } from '../../../ToastProvider'
  import Header from '../../../../Components/Header'
  import { categories, CATEGORY_INFORMATION, CompanySize, DIVERSE_GROUP_INFORMATION, headerMappingData, MBE_INFORMATION, NON_REQ_FIELDS, REVENUE_REPORTING_INFORMATION, SUBCONTRACTING_INFORMATION, Utilities, WORKFORCE_INFORMATION, YEAR_LIST, years } from '../../../../utils/constants'
  import { checkMissingKeys, commonInputData, convertObjectFormat, filterNumbers, filterOutValidKeys, mergeArrayToObject, transformData } from '../../../../utils/helpers'
  import { useLocation, useNavigate } from 'react-router-dom'
  import { CircularProgress } from '@mui/material'


  const InputTemplate = () => {
    const { showSuccess, showError } = useToast();
    const location = useLocation();

    console.log(location.state,"kkk")
    // const { data } = location.state || {};

    const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState(false)
    const [modal, setModal] = useState(false)
    const [apiData, setApiData] = useState()
    const [isEdit, setIsEdit] = useState(false)
    const [customFields, setCustomFields] = useState([])
    const [fillCustom, setFillCustom] = useState([])
    const navigate= useNavigate()
    const [setrecord, { isLoading, isSuccess, isError, error }] = useSetrecordMutation();
    const [updateRecord, { isLoading:fetchLaod }] = useUpdateRecordMutation();
    // const [setsave, { isLoading, isSuccess, isError, error: issErr }] = useSetsaveMutation();
    const [rowData, setRowData] = useState({
      val: "",
      id: ""
    })
    const [headList, setHeadList] = useState([
      {
        id: 1, label: "Company Information", isExpanded: true, data: [
          { label: "Year", name: "year", back: "year", type: 1, place: "Select year (ex-2001)", isClicked: false, isDropdown: true, isRequired: true, fieldType: "dropdown", options: years,validation:false },
          { label: "Company name", name: "company_name", back: "company_name", type: 1, place: "Enter company name", isClicked: false, isDropdown: false, isRequired: true, fieldType: "alphabet",validation:false },
          { label: "Category", name: "category", back: "category", type: 1, place: "Select category", isClicked: false, isDropdown: true, isRequired: true, fieldType: "dropdown", options: categories,validation:false },
          { label: "Company Size", name: "company_size", back: "company_size", type: 1, place: "Select company size", isClicked: false, isDropdown: true, isRequired: true, fieldType: "dropdown", options: CompanySize,validation:false },
          { label: "Type of Utility", name: "type_of_utility", back: "type_of_utility", type: 1, place: "Select utility type", isClicked: false, isDropdown: true, isRequired: true, fieldType: "dropdown", options: Utilities,validation:false },
          { label: "Total Spend", name: "total_spend", back: "total_spend", type: 1, place: "Enter total spend (ex-00.00)", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Total DBE", name: "total_dbe", back: "total_dbe", type: 1, place: "Enter total DBE (ex-00.00)", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Total DBE %", name: "total_dbe_percent", back: "total_dbe_percent", type: 1, place: "Enter total DBE % (ex-0.0%)", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false }
        ]

      },
      {
        id: 2, label: "MBE Information", isExpanded: false, data: [
          { label: "MBE Spend", name: "mbe_spend", back: "mbe_spend", type: 2, place: "Spend for Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "MBE %", name: "mbe_percent", back: "mbe_percent", type: 2, place: "Percentage of spend for MBEs.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "MBE Total Direct Spend", name: "mbe_total_direct_spend", back: "mbe_total_direct_spend", type: 2, place: "Total Direct Spend for Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "MBE Total Sub Spend", name: "mbe_total_sub_spend", back: "mbe_total_sub_spend", type: 2, place: "Total Subcontracting Spend for Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "MBE (African American) Spend", name: "mbe_african_american_spend", back: "mbe_african_american_spend", type: 2, place: "Spend for (African American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "MBE (African American) #", name: "mbe_african_american_number", back: "mbe_african_american_number", type: 2, place: "Number of spend for (African American) MBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "MBE (African American) %", name: "mbe_african_american_percent", back: "mbe_african_american_percent", type: 2, place: "Percentage of spend for (African American) MBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "MBE (African American) Direct Spend", name: "mbe_african_american_direct_spend", back: "mbe_african_american_direct_spend", type: 2, place: "Direct Spend for (African American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "MBE (African American) Sub Spend", name: "mbe_african_american_sub_spend", back: "mbe_african_american_sub_spend", type: 2, place: "Sub Spend for (African American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "MBE(Asian Pacific American) Spend", name: "mbe_asian_pacific_american_spend", back: "mbe_asian_pacific_american_spend", type: 2, place: "Spend for (Asian Pacific American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "MBE (Asian Pacific American) #", name: "mbe_asian_pacific_american_number", back: "mbe_asian_pacific_american_number", type: 2, place: "Number of spend for (Asian Pacific American) MBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "MBE (Asian Pacific American) %", name: "mbe_asian_pacific_american_percent", back: "mbe_asian_pacific_american_percent", type: 2, place: "Percentage of spend for (Asian Pacific American) MBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "MBE (Asian Pacific American) Direct Spend", name: "mbe_asian_pacific_american_direct_spend", back: "mbe_asian_pacific_american_direct_spend", type: 2, place: "Direct Spend for (Asian Pacific American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "MBE (Asian Pacific American) Sub Spend", name: "mbe_asian_pacific_american_sub_spend", back: "mbe_asian_pacific_american_sub_spend", type: 2, place: "Sub Spend for (Asian Pacific American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "MBE (Native American) Spend", name: "mbe_native_american_spend", back: "mbe_native_american_spend", type: 2, place: "Spend for (Native American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric" ,validation:false},
          { label: "MBE (Native American) #", name: "mbe_native_american_number", back: "mbe_native_american_number", type: 2, place: "Number of spend for (Native American) MBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "MBE (Native American) %", name: "mbe_native_american_percent", back: "mbe_native_american_percent", type: 2, place: "Percentage of spend for (Native American) MBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "MBE (Native American) Direct Spend", name: "mbe_native_american_direct_spend", back: "mbe_native_american_direct_spend", type: 2, place: "Direct Spend for (Native American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "MBE (Native American) Sub Spend", name: "mbe_native_american_sub_spend", back: "mbe_native_american_sub_spend", type: 2, place: "Sub Spend for (Native American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "MBE (Hispanic American) Spend", name: "mbe_hispanic_american_spend", back: "mbe_hispanic_american_spend", type: 2, place: "Spend for (Hispanic American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "MBE (Hispanic American) #", name: "mbe_hispanic_american_number", back: "mbe_hispanic_american_number", type: 2, place: "Number of spend for (Hispanic American) MBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "MBE (Hispanic American) %", name: "mbe_hispanic_american_percent", back: "mbe_hispanic_american_percent", type: 2, place: "Percentage of spend for (Hispanic American) MBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "MBE (Hispanic American) Direct Spend", name: "mbe_hispanic_american_direct_spend", back: "mbe_hispanic_american_direct_spend", type: 2, place: "Direct Spend for (Hispanic American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "MBE (Hispanic American) Sub Spend", name: "mbe_hispanic_american_sub_spend", back: "mbe_hispanic_american_sub_spend", type: 2, place: "Sub Spend for (Hispanic American) Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false }
        ]

      },
      {
        id: 3, label: "Diverse Groups Information", isExpanded: false, data: [
          { label: "WMBE Spend", name: "wmbe_spend", back: "wmbe_spend", type: 3, place: "Spend for Women Minority Business Enterprises.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "WMBE #", name: "wmbe_number", back: "wmbe_number", type: 3, place: "Number of spend for WMBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "WMBE %", name: "wmbe_percent", back: "wmbe_percent", type: 3, place: "Percentage of spend for WMBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "Direct Spend (WMBE)", name: "direct_spend_wmbe", back: "direct_spend_wmbe", type: 3, place: "Direct Spend for Minority Women Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Sub Spend (WMBE)", name: "sub_spend_wmbe", back: "sub_spend_wmbe", type: 3, place: "Sub Spend for Minority Women Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "WBE Spend", name: "wbe_spend", back: "wbe_spend", type: 3, place: "Spend for Women Business Enterprises.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "WBE #", name: "wbe_number", back: "wbe_number", type: 3, place: "Number of spend for WBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "WBE %", name: "wbe_percent", back: "wbe_percent", type: 3, place: "Percentage of spend for WBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "WBE Direct Spend", name: "wbe_direct_spend", back: "wbe_direct_spend", type: 3, place: "Direct Spend for Women Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "WBE Sub Spend", name: "wbe_sub_spend", back: "wbe_sub_spend", type: 3, place: "Subcontracting Spend for Women Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "PBDBE Spend", name: "pbdbe_spend", back: "pbdbe_spend", type: 3, place: "Spend for Person with Disabilities Business Enterprises.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "PBDBE #", name: "pbdbe_number", back: "pbdbe_number", type: 3, place: "Number of spend for PBDBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "PBDBE %", name: "pbdbe_percent", back: "pbdbe_percent", type: 3, place: "Percentage of spend for PBDBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "PBDBE Direct Spend", name: "pbdbe_direct_spend", back: "pbdbe_direct_spend", type: 3, place: "Direct Spend for Person with Disabilities Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "PBDBE Sub Spend", name: "pbdbe_sub_spend", back: "pbdbe_sub_spend", type: 3, place: "Subcontracting Spend for Person with Disabilities Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "LGBTBE Spend", name: "lgbtbe_spend", back: "lgbtbe_spend", type: 3, place: "Spend for LGBT Business Enterprises.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "LGBTBE #", name: "lgbtbe_number", back: "lgbtbe_number", type: 3, place: "Number of spend for LGBTBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "LGBTBE %", name: "lgbtbe_percent", back: "lgbtbe_percent", type: 3, place: "Percentage of spend for LGBTBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "LGBTBE Direct Spend", name: "lgbtbe_direct_spend", back: "lgbtbe_direct_spend", type: 3, place: "Direct Spend for LGBTBE", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "LGBTBE Sub Spend", name: "lgbtbe_sub_spend", back: "lgbtbe_sub_spend", type: 3, place: "Sub Spend for LGBTBE", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "DVBE Spend", name: "dvbe_spend", back: "dvbe_spend", type: 3, place: "Spend for Disabled Veteran Business Enterprises.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "DVBE #", name: "dvbe_number", back: "dvbe_number", type: 3, place: "Number of spend for DVBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "DVBE %", name: "dvbe_percent", back: "dvbe_percent", type: 3, place: "Percentage of spend for DVBEs.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "DVBE Direct Spend", name: "dvbe_direct_spend", back: "dvbe_direct_spend", type: 3, place: "Direct Spend for Disabled Veteran Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "DVBE Sub Spend", name: "dvbe_sub_direct_spend", back: "dvbe_sub_direct_spend", type: 3, place: "Sub Spend for Disabled Veteran Business Enterprises.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Other 8 (a) Spend", name: "other_8a_spend", back: "other_8a_spend", type: 3, place: "Spend for other 8(a) small businesses.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "Other 8 (a) %", name: "other_8a_percent", back: "other_8a_percent", type: 3, place: "Number of spend for other 8(a) small businesses.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "Other 8 (a) #", name: "other_8a_number", back: "other_8a_number", type: 3, place: "Percentage of spend for other 8(a) small businesses.", isClicked: false, isDropdown: false, isRequired: false, fieldType: "numeric",validation:false },
          { label: "Other 8 (a) Direct Spend", name: "other_8a_direct_spend", back: "other_8a_direct_spend", type: 3, place: "Direct Spend for other 8(a) small businesses.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Other 8 (a) Sub Spend", name: "other_8a_sub_spend", back: "other_8a_sub_spend", type: 3, place: "Sub Spend for other 8(a) small businesses.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false }
        ]


      },
      {
        id: 4, label: "Direct/Subcontracting Information", isExpanded: false, data: [
          { label: "Total Direct Spend", name: "total_direct_spend", back: "total_direct_spend", type: 4, place: "Total direct spending by the company.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Total Direct %", name: "total_direct_percent", back: "total_direct_percent", type: 4, place: "Percentage of direct spending.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Total Direct # Suppliers", name: "total_direct_number_suppliers", back: "total_direct_number_suppliers", type: 4, place: "Number of direct suppliers.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Total Sub Spend", name: "total_sub_spend", back: "total_sub_spend", type: 4, place: "Total subcontractor spending.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Total Sub #", name: "total_sub_number", back: "total_sub_number", type: 4, place: "Number of subcontractor spending.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Total Sub %", name: "total_sub_percent", back: "total_sub_percent", type: 4, place: "Percentage of subcontractor spending.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "New Diverse Suppliers $", name: "new_diverse_suppliers_dollar", back: "new_diverse_suppliers_dollar", type: 4, place: "Spend for new diverse suppliers.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "New Diverse Suppliers #", name: "new_diverse_suppliers_number", back: "new_diverse_suppliers_number", type: 4, place: "Number of new diverse suppliers.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false }
        ]
      },
      {
        id: 5, label: "Revenue Reporting Information", isExpanded: false, data: [
          { label: "Rev - Under $1M -#", name: "rev_under_1m_number", back: "rev_under_1m_number", type: 5, place: "Number of suppliers with revenue under $1M.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Rev - Under $1M - $", name: "rev_under_1m_dollar", back: "rev_under_1m_dollar", type: 5, place: "Spend for suppliers with revenue under $1M.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Rev - Under $5M -#", name: "rev_under_5m_number", back: "rev_under_5m_number", type: 5, place: "Number of suppliers with revenue under $5M.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Rev - Under $5M - $", name: "rev_under_5m_dollar", back: "rev_under_5m_dollar", type: 5, place: "Spend for suppliers with revenue under $5M.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Rev - Under $10M -#", name: "rev_under_10m_number", back: "rev_under_10m_number", type: 5, place: "Number of suppliers with revenue under $10M.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Rev - Under $10M - $", name: "rev_under_10m_dollar", back: "rev_under_10m_dollar", type: 5, place: "Spend for suppliers with revenue under $10M.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Rev - Above $10M -#", name: "rev_above_10m_number", back: "rev_above_10m_number", type: 5, place: "Number of suppliers with revenue above $10M.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Rev - Above $10M - $", name: "rev_above_10m_dollar", back: "rev_above_10m_dollar", type: 5, place: "Spend for suppliers with revenue above $10M.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Grand Total $", name: "grand_total_dollar", back: "grand_total_dollar", type: 5, place: "Total spend across all categories.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Grand Total #", name: "grand_total_number", back: "grand_total_number", type: 5, place: "Total number of suppliers across all categories.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false }
        ]

      },
      {
        id: 6, label: "Workforce/Board Diversity Information", isExpanded: false, data: [
          { label: "9.1.2 Description of Diverse Suppliers with Majority Workforce in California (# OF DIVERSE SUPPLIERS IN CA)", name: "diverse_suppliers_ca_number", back: "diverse_suppliers_ca_number", type: 6, place: "Number of diverse suppliers with a majority workforce in California.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "alphabet",validation:false },
          { label: "WORKFORCE AND BOARD DIVERSITY (# AND % OF WOMEN)", name: "workforce_board_women", back: "workforce_board_women", type: 6, place: "Number and percentage of women in the workforce and for the board.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "WORKFORCE AND BOARD DIVERSITY (# AND % OF PPL OF COLOR)", name: "workforce_board_ppl_of_color", back: "workforce_board_ppl_of_color", type: 6, place: "Number and percentage of people of color in the workforce and for the board.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false },
          { label: "Total number of boards of Directors", name: "total_board_directors_number", back: "total_board_directors_number", type: 6, place: "Total number of boards of Directors.", isClicked: false, isDropdown: false, isRequired: true, fieldType: "numeric",validation:false }
        ]

      },
    ])
    const [isContentClicked, setIsContentClicked] = useState(false)
    const [clickId, setClickId] = useState()
    const [inputData, setInputData] = useState({
      companyInformation: {},
      mbeInformation: {},
      diverseInformation: {},
      subcontractInformation: {},
      revenueInformation: {},
      workforceInformation: {}
    });

    const handleInputChange = (e, id, rows, isDrop = false, dropname = "") => {
      console.log(e, id, rows, "IDD");

      if (isDrop) {
        // if(!rows.name.includes("custom")){
        switch (id) {
          case 1:
            setInputData({
              ...inputData,
              companyInformation: {
                ...inputData.companyInformation,
                [dropname]: e,
              },
            });
            break;
          case 2:
            setInputData({
              ...inputData,
              mbeInformation: {
                ...inputData.mbeInformation,
                [dropname]: e,
              },
            });
            break;
          case 3:
            setInputData({
              ...inputData,
              diverseInformation: {
                ...inputData.diverseInformation,
                [dropname]: e,
              },
            });
            break;
          case 4:
            setInputData({
              ...inputData,
              subcontractInformation: {
                ...inputData.subcontractInformation,
                [dropname]: e,
              },
            });
            break;
          case 5:
            setInputData({
              ...inputData,
              revenueInformation: {
                ...inputData.revenueInformation,
                [dropname]: e,
              },
            });
            break;
          case 6:
            setInputData({
              ...inputData,
              workforceInformation: {
                ...inputData.workforceInformation,
                [dropname]: e,
              },
            });
            break;
          default:
            break;
        }
        // }
      } else {
        // if(!rows.name.includes("custom")){
        const { name, value } = e.target;
        
        switch (id) {
          case 1:
            setInputData({
              ...inputData,
              companyInformation: {
                ...inputData.companyInformation,
                [name]: value,
              },
            });
            break;
          case 2:
            setInputData({
              ...inputData,
              mbeInformation: {
                ...inputData.mbeInformation,
                [name]: value,
              },
            });
            break;
          case 3:
            setInputData({
              ...inputData,
              diverseInformation: {
                ...inputData.diverseInformation,
                [name]: value,
              },
            });
            break;
          case 4:
            setInputData({
              ...inputData,
              subcontractInformation: {
                ...inputData.subcontractInformation,
                [name]: value,
              },
            });
            break;
          case 5:
            setInputData({
              ...inputData,
              revenueInformation: {
                ...inputData.revenueInformation,
                [name]: value,
              },
            });
            break;
          case 6:
            setInputData({
              ...inputData,
              workforceInformation: {
                ...inputData.workforceInformation,
                [name]: value,
              },
            });
            break;
          default:
            break;
        }

      }

    };

    console.log("CSTM", fillCustom, inputData,headList)


    console.log("IS", isLoading)

    const handleAddClick = (roww, index) => {

      setHeadList((prevHeadList) =>
        prevHeadList.map((headItem) =>
          headItem.id === roww.type
            ? {
              ...headItem,
              data: headItem.data.map((dataItem, i) =>
                index === i
                  ? { ...dataItem, isClicked: !dataItem.isClicked }
                  : dataItem
              ),
            }
            : headItem
        )
      );
    }

    const textReturn = (id) => {
      if (id == 1) {
        return "Company Information"
      } else if (id == 2) {
        return "MBE Information"
      }
      else if (id == 3) {
        return "Diverse Groups Information"
      }
      else if (id == 4) {
        return "Direct/Subcontracting Information"
      }
      else if (id == 5) {
        return "Revenue Reporting Information"
      }
      else if (id == 6) {
        return "Workforce/Board Diversity Information"
      }
    }

    function formatOptions(options) {
      if (Array.isArray(options)) {
        return options.map(option => ({
          value: option.toLowerCase(),
          label: option,
        }));
      }
    }


    const handleCustom = (custom, updated) => {
      console.log("CT", custom, updated)
      custom.map((itm, index) => {
        if (itm.category === "Company Information") {
          const obj = { label: itm.fieldName, name: `cs-${itm.fieldName}`, type: 1, place: itm.placeholder, isClicked: false, isDropdown: itm.fieldType == "dropdown" ? true : false, isRequired: false, visible: true, fieldType: itm.fieldType, options: formatOptions(itm.options),validation:false }
          updated[0].data.push(obj)
        } else if (itm.category === "MBE Information") {
          const obj = { label: itm.fieldName, name: `cs-${itm.fieldName}`, type: 2, place: itm.placeholder, isClicked: false, isDropdown: itm.fieldType == "dropdown" ? true : false, isRequired: false, visible: true, fieldType: itm.fieldType, options: formatOptions(itm.options),validation:false }
          updated[1].data.push(obj)
        } else if (itm.category === "Diverse Groups Information") {
          const obj = { label: itm.fieldName, name: `cs-${itm.fieldName}`, type: 3, place: itm.placeholder, isClicked: false, isDropdown: itm.fieldType == "dropdown" ? true : false, isRequired: false, visible: true, fieldType: itm.fieldType, options: formatOptions(itm.options),validation:false }
          updated[2].data.push(obj)
        } else if (itm.category === "Direct/Subcontracting Information") {
          const obj = { label: itm.fieldName, name: `cs-${itm.fieldName}`, type: 4, place: itm.placeholder, isClicked: false, isDropdown: itm.fieldType == "dropdown" ? true : false, isRequired: false, visible: true, fieldType: itm.fieldType, options: formatOptions(itm.options),validation:false }
          updated[3].data.push(obj)
        } else if (itm.category === "Revenue Reporting Information") {
          const obj = { label: itm.fieldName, name: `cs-${itm.fieldName}`, type: 5, place: itm.placeholder, isClicked: false, isDropdown: itm.fieldType == "dropdown" ? true : false, isRequired: false, visible: true, fieldType: itm.fieldType, options: formatOptions(itm.options),validation:false }
          updated[4].data.push(obj)
        } else if (itm.category === "MBE Information") {
          const obj = { label: itm.fieldName, name: `cs-${itm.fieldName}`, type: 6, place: itm.placeholder, isClicked: false, isDropdown: itm.fieldType == "dropdown" ? true : false, isRequired: false, visible: true, fieldType: itm.fieldType, options: formatOptions(itm.options),validation:false }
          updated[5].data.push(obj)
        }
      })
      return updated
      // console.log(updated,"lkjlk")
    }
    const handleAddInput = (row, index) => {
      setIsContentClicked(true)
      setClickId(row.id)

    }
    const handleExpand = (index) => {
      setHeadList((prevRows) =>
        prevRows.map((row, i) =>
          i === index ? { ...row, isExpanded: !row.isExpanded } : row
        )
      );
    }

    const handleAdd = () => {

    }

    const handleValue = (type, labelText, placeText, inputs) => {
      console.log("TYPP", type)
      const obj = { label: labelText, type: clickId, place: placeText, isClicked: false, isDropdown: type == "dropdown" ? true : false, isRequired: false, visible: true, isCustom: true, fieldType: type }
      setHeadList((prevHeadList) =>
        prevHeadList.map((headItem) =>
          headItem.id === clickId
            ? {
              ...headItem,
              data: [...headItem.data, obj]

            }
            : headItem
        )
      );
      if (type == "dropdown") {
        setCustomFields(prevState => [
          ...prevState,  // Preserve previous state (if any)
          {
            category: textReturn(clickId),
            fieldName: labelText,
            fieldType: "dropdown",
            placeholder: placeText,
            options: inputs
          }  // Add new object by spreading the customFields object
        ]);
      } else {
        setCustomFields(prevState => [
          ...prevState,  // Preserve previous state (if any)
          {
            category: textReturn(clickId),
            fieldName: labelText,
            fieldType: type,
            placeholder: placeText,
          }  // Add new object by spreading the customFields object
        ]);
      }

    }

    const handleEditValue = (type, labelText, placeText) => {
      console.log(type, labelText, placeText, rowData, "CHH")
      setHeadList((prevHeadList) => {
        console.log("Previous headList:", prevHeadList);

        return prevHeadList.map((headItem) => {
          console.log("Checking headItem:", headItem);
          if (headItem.id === rowData.val.type) {
            return {
              ...headItem,
              data: headItem.data.map((dataItem, i) => {
                console.log("Checking dataItem:", dataItem);
                return i === rowData.id
                  ? {
                    ...dataItem,
                    label: labelText,
                    place: placeText,
                    isDropdown: type === "dropdown" ? true : false,
                  }
                  : dataItem;
              }),
            };
          }
          return headItem;
        });
      });


    }

    const editHandle = (itm, id) => {
      setRowData({
        val: itm,
        id: id
      })
      setIsContentClicked(true)
      setIsEdit(true)
    }

    const modalHandle = (val, i) => {
      console.log(val, i, "VLL", apiData)
      setRowData({
        val: val,
        id: i
      })
      setModal(!modal)
    }

    const deleteHandle = () => {
      setHeadList((prevHeadList) =>
        prevHeadList.map((headItem) =>
          headItem.id === rowData.val.type
            ? {
              ...headItem,
              data: headItem.data.map((item, i) =>
                i === rowData.id ? { ...item, visible: false } : item
              ),
            }
            : headItem
        )
      );
      if (!rowData.val.back) {
        const result = customFields.filter((itm, id) => {
          return (
            itm.fieldName !== rowData.val.label
          )
        })

        setCustomFields(result)
        // console.log(result,"rety")
      }
      else {
        setApiData((prevData) => ({
          ...prevData,
          [rowData.val.back]: { visible: false }
        }))
      }
      setModal(false)
      setRowData({
        val: "",
        id: ""
      })
    }

    // if(CATEGORY_INFORMATION.)
    // console.log("TESTRSN",transformedData)
    // }
    



    // JSON.parse(localStorage.getItem('access_token'))
    console.log("APP", apiData)
    // console.log("ROWS", rows)
    const fetchApi = async () => {
      try {
        // const response = await axios.get(`https://devapiecg.resourcifi.tech/inputlayout/getlayout/99990`);
        const response = await axios.get(`https://devapiecg.resourcifi.tech/inputlayout/getlayout/${JSON.parse(localStorage.getItem('group'))}`);
        // Simulating setting headlist with new data
        setApiData(response.data)
        setCustomFields(response.data.customFields || [])
        const updatedHeadlist = headList?.map((itm) => {
          const updatedData = itm.data?.map(field => {
            const matchingField = response.data[field.back];
            console.log("MT", matchingField);
            return {
              ...field,
              visible: matchingField ? matchingField.visible : undefined
            };
          });

          // Return the modified item with updated data
          return {
            ...itm,
            data: updatedData,
          };
        });

        // Update the headlist state with the modified data
        if (updatedHeadlist) {
          // handleText
          console.log(updatedHeadlist, "UPD")
          const finalView = handleCustom(response?.data?.customFields, updatedHeadlist)

          setHeadList(finalView);
        }
      } catch (error) {
        console.error("Error fetching layout data:", error);
        throw error;
      }
    };

    const handleCancelClick = () => {
      fetchApi()
    }


    const handleSubmit = async () => {
      if(location?.state?.data2){
        try {
          const dataValue = convertObjectFormat(inputData)
          const customFields = [];
  
          // Iterate over object keys
          for (const [key, value] of Object.entries(dataValue)) {
            if (key.startsWith('cs-')) {
              // Push the fieldName (without 'cs-' prefix) and value to customFields array
              customFields.push({
                fieldName: key.slice(3), // Remove 'cs-' prefix
                value: value,
              });
              delete dataValue[key];
            }
          }
  
          // Add customFields to the object
          const result = {
            groupId:JSON.parse(localStorage.getItem('group')),
            companyId:JSON.parse(localStorage.getItem('id')),
            ...dataValue,
            status:"Active",
            customFields: customFields,
          };
          const visibleKeys = Object.keys(apiData).filter(key => apiData[key]?.visible === true);
          const requiredList = visibleKeys.filter(item => !NON_REQ_FIELDS.includes(item));
          const missingKeys = checkMissingKeys(requiredList, result);
          if(missingKeys.length > 0){
            const updatedHeadList = headList.map(section => ({
              ...section,
              data: section.data.map(item => ({
                ...item,
                validation: missingKeys.includes(item.name) ? `${item.label} is a required field.` : false // Add validation key based on keys array
              }))
            }));
      
            setHeadList(updatedHeadList)
          }
          else{

            setLoading(true)
            const resultData = await updateRecord({id:location.state.data2.id, payload: result}) // Call the mutation
            setLoading(false)
            // fetchApi()
            navigate("/end/data")
            // navigate(location.pathname, { replace: true, state: {isView:false} });
            setInputData({
              companyInformation: {},
              mbeInformation: {},
              diverseInformation: {},
              subcontractInformation: {},
              revenueInformation: {},
              workforceInformation: {}
            })
            showSuccess({
              body:"Record added successfully"
            })
            console.log("RESUL",resultData)
          }
  
          
          // setHeadList(updatedHeadList);
  
          // const resultData = await setrecord(finalResult).unwrap();
        } catch (err) {
          showError({
            title: "Error!",
            body: err?.data?.message || err?.status || "something went wrong",
          });
        }
        finally{
          setLoading(false)
        }
      }
      else{
        try {
          const dataValue = convertObjectFormat(inputData)
          const customFields = [];
  
          // Iterate over object keys
          for (const [key, value] of Object.entries(dataValue)) {
            if (key.startsWith('cs-')) {
              // Push the fieldName (without 'cs-' prefix) and value to customFields array
              customFields.push({
                fieldName: key.slice(3), // Remove 'cs-' prefix
                value: value,
              });
              delete dataValue[key];
            }
          }
  
          // Add customFields to the object
          const result = {
            groupId:JSON.parse(localStorage.getItem('group')),
            companyId:JSON.parse(localStorage.getItem('id')),
            ...dataValue,
            status:"Active",
            customFields: customFields,
          };
          const visibleKeys = Object.keys(apiData).filter(key => apiData[key]?.visible === true);
          const requiredList = visibleKeys.filter(item => !NON_REQ_FIELDS.includes(item));
          const missingKeys = checkMissingKeys(requiredList, result);
          if(missingKeys.length > 0){
            const updatedHeadList = headList.map(section => ({
              ...section,
              data: section.data.map(item => ({
                ...item,
                validation: missingKeys.includes(item.name) ? `${item.label} is a required field.` : false // Add validation key based on keys array
              }))
            }));
      
            setHeadList(updatedHeadList)
          }
          else{
            setLoading(true)
            const resultData = await setrecord(result).unwrap(); // Call the mutation
            setLoading(false)
            navigate("/end/data")
            // fetchApi()
            // navigate(location.pathname, { replace: true, state: {isView:false} });
            setInputData({
              companyInformation: {},
              mbeInformation: {},
              diverseInformation: {},
              subcontractInformation: {},
              revenueInformation: {},
              workforceInformation: {}
            })
            showSuccess({
              body:"Record added successfully"
            })
            console.log("RESUL",resultData)
          }
  
          
          // setHeadList(updatedHeadList);
  
          // const resultData = await setrecord(finalResult).unwrap();
        } catch (err) {
          showError({
            title: "Error!",
            body: err?.data?.message || err?.status || "something went wrong",
          });
        }
        finally{
          setLoading(false)
        }
      }
      
    }

    const hanldeDraft = async ()=>{
      if(location?.state?.data2){

        try{
          const dataValue = convertObjectFormat(inputData)
          const customFields = [];
  
          // Iterate over object keys
          for (const [key, value] of Object.entries(dataValue)) {
            if (key.startsWith('cs-')) {
              // Push the fieldName (without 'cs-' prefix) and value to customFields array
              customFields.push({
                fieldName: key.slice(3), // Remove 'cs-' prefix
                value: value,
              });
              delete dataValue[key];
            }
          }
          // Add customFields to the object
          const result = {
            groupId:localStorage.getItem('group'),
            status:"Draft",
            companyId:localStorage.getItem('id'),
            ...dataValue,
            customFields: customFields,
          };
            setLoading1(true)
            const resultData = await updateRecord({id:location.state.data2.id, payload: result}) // Call the mutation
            setLoading1(false)
            navigate("/end/data")
            // navigate(location.pathname, { replace: true, state: null });
            // fetchApi()
            setInputData({
              companyInformation: {},
              mbeInformation: {},
              diverseInformation: {},
              subcontractInformation: {},
              revenueInformation: {},
              workforceInformation: {}
            })
            showSuccess({
              body:"Record drafted"
            })
            console.log("RESUL",resultData)
        }catch(err){
          showError({
            title: "Error!",
            body: err?.data?.message || err?.status || "something went wrong",
          });
        }
        finally{
          setLoading1(false)
        }
      }
      else{
        try{
          const dataValue = convertObjectFormat(inputData)
          const customFields = [];
  
          // Iterate over object keys
          for (const [key, value] of Object.entries(dataValue)) {
            if (key.startsWith('cs-')) {
              // Push the fieldName (without 'cs-' prefix) and value to customFields array
              customFields.push({
                fieldName: key.slice(3), // Remove 'cs-' prefix
                value: value,
              });
              delete dataValue[key];
            }
          }
          // Add customFields to the object
          const result = {
            groupId:localStorage.getItem('group'),
            status:"Draft",
            companyId:localStorage.getItem('id'),
            ...dataValue,
            customFields: customFields,
          };
            setLoading1(true)
            const resultData = await setrecord(result).unwrap(); 
            navigate("/end/data")// Call the mutation
            setLoading1(false)
            // navigate(location.pathname, { replace: true, state: null });
            // fetchApi()
            setInputData({
              companyInformation: {},
              mbeInformation: {},
              diverseInformation: {},
              subcontractInformation: {},
              revenueInformation: {},
              workforceInformation: {}
            })
            showSuccess({
              body:"Record drafted"
            })
            console.log("RESUL",resultData)
        }catch(err){
          showError({
            title: "Error!",
            body: err?.data?.message || err?.status || "something went wrong",
          });
        }
        finally{
          setLoading1(false)
        }
      }

    }
    useEffect(() => {
      fetchApi()
    }, [])

    useEffect(()=>{
      if(location?.state?.data1){

        const newArray = filterOutValidKeys(location?.state?.data1);
        const obj = { ...newArray[0] };
        const transformedData = transformData(mergeArrayToObject(location?.state?.data1), headerMappingData);
        console.log("NEWARRR",newArray,obj,transformedData,location?.state?.data1)
        const updatedMbInformation = commonInputData(MBE_INFORMATION,transformedData)
        const finalMbInformation = filterNumbers(updatedMbInformation)
        console.log("FINL",finalMbInformation)
        console.log(updatedMbInformation,"MBN")
        setInputData((prevState) => ({
          ...prevState,
          mbeInformation: {
            ...prevState.mbeInformation,
            ...finalMbInformation, // Add all matched keys at once
          },
      }))
        const updatedCompanyInformation =commonInputData(CATEGORY_INFORMATION,transformedData)

        console.log(updatedCompanyInformation,"CPM")
        let objCompany ={}
        Object.entries(updatedCompanyInformation).forEach(([key, value]) => {
          console.log(key,value,">>>>>>>>")
          if(key === "total_dbe" || key === "total_dbe_percent" || key ==="total_spend"){
            if(typeof value === "number"){
              objCompany[key] = value;
            }
          }
          if(key === "category"){
            if(value === "Utility" || value === "ESP" || value === "CCA" ){
              objCompany[key] = value;
            }
          }
          if(key === "year"){
            if(YEAR_LIST.includes(value)){
              objCompany[key] = value;
            }
          }
          if(key === "company_name"){
            if(typeof value === "string"){
              objCompany[key] = value;
            }
          }
          if(key === "company_size"){
            if(value === "Large" || value === "Small"){
              objCompany[key] = value;
            }
          }
          if(key === "type_of_utility"){
            if(value === "Gas" || value === "Electric" || value === "GasandElectric" || value === "Telecom" || value === "Water"){
              objCompany[key] = value;
            }
          }
        });
        console.log(objCompany,"OBBJ")
      //  handleCompanyData()
        setInputData((prevState) => ({
          ...prevState,
          companyInformation: {
            ...prevState.companyInformation,
            ...objCompany, // Add all matched keys at once
          },
      }))
      const updatedDiverseInformation =commonInputData(DIVERSE_GROUP_INFORMATION,transformedData)
      const finalDiverseInformation = filterNumbers(updatedDiverseInformation)
        setInputData((prevState) => ({
          ...prevState,
          diverseInformation: {
            ...prevState.diverseInformation,
            ...finalDiverseInformation, // Add all matched keys at once
          },
      }))
      const updatedSubcontractInformation =commonInputData(SUBCONTRACTING_INFORMATION,transformedData)
      const finalSubcontractInformation = filterNumbers(updatedSubcontractInformation)
      setInputData((prevState) => ({
        ...prevState,
        subcontractInformation: {
          ...prevState.subcontractInformation,
          ...finalSubcontractInformation, // Add all matched keys at once
        },
    }))
      const updatedRevenueInformation =commonInputData(REVENUE_REPORTING_INFORMATION,transformedData)
      const finalRevenueInformation = filterNumbers(updatedRevenueInformation)
      setInputData((prevState) => ({
        ...prevState,
        revenueInformation: {
          ...prevState.revenueInformation,
          ...finalRevenueInformation, // Add all matched keys at once
        },
    }))
      const updatedWorkInformation =commonInputData(WORKFORCE_INFORMATION,transformedData)
      let objWork ={}
        Object.entries(updatedWorkInformation).forEach(([key, value]) => {
          console.log(key,value,">>>>>>>>")
          if(key === "total_board_directors_number" || key === "workforce_board_ppl_of_color" || key === "workforce_board_women"){
            if(typeof value === "number"){
              objWork[key] = value;
            }
          }
          if(key === "diverse_suppliers_ca_number"){
            if(typeof value === "string"){
              objWork[key] = value;
            }
          }
        });
      setInputData((prevState) => ({
        ...prevState,
        workforceInformation: {
          ...prevState.workforceInformation,
          ...objWork, // Add all matched keys at once
        },
    }))

    console.log("TR",transformedData)

    customFields?.forEach((itm) => {
      console.log(itm,"ITMCUST")
      if (itm.category === "Company Information"  && obj[itm.fieldName]) {
        if(
          (itm.fieldType === "alphabet" && typeof obj[itm.fieldName] === "string")
        || (itm.fieldType === "numeric" && typeof obj[itm.fieldName] === "number")
        || (itm.fieldType === "dropdown" && itm.options.includes(obj[itm.fieldName]))
        || (itm.fieldType === "boolean" && (obj[itm.fieldName] === "yes" || obj[itm.fieldName] === "no"))
        || (itm.fieldType === "date" && typeof obj[itm.fieldName] === "string")
      ){
        setInputData((prevState) => ({
          ...prevState,
          companyInformation: {
            ...prevState.companyInformation,
            [`cs-${itm.fieldName}`]: obj[itm.fieldName], // Add matched keys
          },
        }));
      }
      }
      if (itm.category === "MBE Information"  && obj[itm.fieldName]) {
        if(
          (itm.fieldType === "alphabet" && typeof obj[itm.fieldName] === "string")
           || (itm.fieldType === "numeric" && typeof obj[itm.fieldName] === "number")      
             || (itm.fieldType === "dropdown" && itm.options.includes(obj[itm.fieldName]))
             || (itm.fieldType === "boolean" && (obj[itm.fieldName] === "yes" || obj[itm.fieldName] === "no"))
             || (itm.fieldType === "date" && typeof obj[itm.fieldName] === "string")
            ){
        setInputData((prevState) => ({
          ...prevState,
          mbeInformation: {
            ...prevState.mbeInformation,
            [`cs-${itm.fieldName}`]: obj[itm.fieldName], // Add matched keys
          },
        }));
      }
      }
      if (itm.category === "Diverse Groups Information"  && obj[itm.fieldName]) {
        if((itm.fieldType === "alphabet" && typeof obj[itm.fieldName] === "string")
          || (itm.fieldType === "numeric" && typeof obj[itm.fieldName] === "number")      
            || (itm.fieldType === "dropdown" && itm.options.includes(obj[itm.fieldName]))
            || (itm.fieldType === "boolean" && (obj[itm.fieldName] === "yes" || obj[itm.fieldName] === "no"))
            || (itm.fieldType === "date" && typeof obj[itm.fieldName] === "string")
          ){
        setInputData((prevState) => ({
          ...prevState,
          diverseInformation: {
            ...prevState.diverseInformation,
            [`cs-${itm.fieldName}`]: obj[itm.fieldName], // Add matched keys
          },
        }));
      }
      }
      if (itm.category === "Direct/Subcontracting Information"  && obj[itm.fieldName]) {
        if((itm.fieldType === "alphabet" && typeof obj[itm.fieldName] === "string")
          || (itm.fieldType === "numeric" && typeof obj[itm.fieldName] === "number")      
            || (itm.fieldType === "dropdown" && itm.options.includes(obj[itm.fieldName]))
            || (itm.fieldType === "boolean" && (obj[itm.fieldName] === "yes" || obj[itm.fieldName] === "no"))
            || (itm.fieldType === "date" && typeof obj[itm.fieldName] === "string")
          ){
        setInputData((prevState) => ({
          ...prevState,
          subcontractInformation: {
            ...prevState.subcontractInformation,
            [`cs-${itm.fieldName}`]: obj[itm.fieldName], // Add matched keys
          },
        }));
      }
      }
      if (itm.category === "Revenue Reporting Information"  && obj[itm.fieldName]) {
        if((itm.fieldType === "alphabet" && typeof obj[itm.fieldName] === "string")
          || (itm.fieldType === "numeric" && typeof obj[itm.fieldName] === "number")      
            || (itm.fieldType === "dropdown" && itm.options.includes(obj[itm.fieldName]))
            || (itm.fieldType === "boolean" && (obj[itm.fieldName] === "yes" || obj[itm.fieldName] === "no"))
            || (itm.fieldType === "date" && typeof obj[itm.fieldName] === "string")
          ){
        setInputData((prevState) => ({
          ...prevState,
          revenueInformation: {
            ...prevState.revenueInformation,
            [`cs-${itm.fieldName}`]: obj[itm.fieldName], // Add matched keys
          },
        }));
      }
      }
      if (itm.category === "Workforce/Board Diversity Information"  && obj[itm.fieldName]) {
        if((itm.fieldType === "alphabet" && typeof obj[itm.fieldName] === "string")
          || (itm.fieldType === "numeric" && typeof obj[itm.fieldName] === "number")      
            || (itm.fieldType === "dropdown" && itm.options.includes(obj[itm.fieldName]))
            || (itm.fieldType === "boolean" && (obj[itm.fieldName] === "yes" || obj[itm.fieldName] === "no"))
            || (itm.fieldType === "date" && typeof obj[itm.fieldName] === "string")
          ){
        setInputData((prevState) => ({
          ...prevState,
          workforceInformation: {
            ...prevState.workforceInformation,
            [`cs-${itm.fieldName}`]: obj[itm.fieldName], // Add matched keys
          },
        }));
      }
      }
    });
    
    
    
    }
      if(location?.state?.data2){

        // const newArray = filterOutValidKeys(location?.state?.data1);
        // const obj = { ...newArray[0] };
        // const transformedData = transformData(mergeArrayToObject(location?.state?.data1), headerMappingData);
        // console.log("NEWARRR",newArray,obj,transformedData)
        console.log(location?.state?.data2,"....")
        const output = location?.state?.data2?.customFields.reduce((acc, item) => {
          acc[item.fieldName] = item.value.charAt(0).toUpperCase() + item.value.slice(1);
          return acc;
        }, {});
        // console.log(output,"???????????")
        const convertedData = Object.fromEntries(
          Object.entries(location?.state?.data2).map(([key, value]) => {
            // if(key !== "customFields"){
              const numericValue = !isNaN(value) && value !== "" ? parseFloat(value) : value;
               return [key, numericValue];
            // }
            // Convert values that can be parsed into numbers
            
          })
        );
        const updatedMbInformation = commonInputData(MBE_INFORMATION,convertedData)
        const finalMbInformation = filterNumbers(updatedMbInformation)
        console.log("FINL",finalMbInformation)
        console.log(updatedMbInformation,"MBN")
        setInputData((prevState) => ({
          ...prevState,
          mbeInformation: {
            ...prevState.mbeInformation,
            ...finalMbInformation, // Add all matched keys at once
          },
      }))
        const updatedCompanyInformation =commonInputData(CATEGORY_INFORMATION,convertedData)

        console.log(updatedCompanyInformation,"CPM")
        let objCompany ={}
        Object.entries(updatedCompanyInformation).forEach(([key, value]) => {
          console.log(key,value,">>>>>>>>")
          if(key === "total_dbe" || key === "total_dbe_percent" || key ==="total_spend"){
            if(typeof value === "number"){
              objCompany[key] = value;
            }
          }
          if(key === "year"){
            if(YEAR_LIST.includes(value)){
              objCompany[key] = value;
            }
          }
          if(key === "category"){
            if(value === "Utility" || value === "ESP" || value === "CCA" ){
              objCompany[key] = value;
            }
          }
          if(key === "company_name"){
            if(typeof value === "string"){
              objCompany[key] = value;
            }
          }
          if(key === "company_size"){
            if(value === "Large" || value === "Small"){
              objCompany[key] = value;
            }
          }
          if(key === "type_of_utility"){
            if(value === "Gas" || value === "Electric" || value === "GasandElectric" || value === "Telecom" || value === "Water"){
              objCompany[key] = value;
            }
          }
        });
        console.log(objCompany,"OBBJ")
      //  handleCompanyData()
        setInputData((prevState) => ({
          ...prevState,
          companyInformation: {
            ...prevState.companyInformation,
            ...objCompany, // Add all matched keys at once
          },
      }))
      const updatedDiverseInformation =commonInputData(DIVERSE_GROUP_INFORMATION,convertedData)
      const finalDiverseInformation = filterNumbers(updatedDiverseInformation)
        setInputData((prevState) => ({
          ...prevState,
          diverseInformation: {
            ...prevState.diverseInformation,
            ...finalDiverseInformation, // Add all matched keys at once
          },
      }))
      const updatedSubcontractInformation =commonInputData(SUBCONTRACTING_INFORMATION,convertedData)
      const finalSubcontractInformation = filterNumbers(updatedSubcontractInformation)
      setInputData((prevState) => ({
        ...prevState,
        subcontractInformation: {
          ...prevState.subcontractInformation,
          ...finalSubcontractInformation, // Add all matched keys at once
        },
    }))
      const updatedRevenueInformation =commonInputData(REVENUE_REPORTING_INFORMATION,convertedData)
      const finalRevenueInformation = filterNumbers(updatedRevenueInformation)
      setInputData((prevState) => ({
        ...prevState,
        revenueInformation: {
          ...prevState.revenueInformation,
          ...finalRevenueInformation, // Add all matched keys at once
        },
    }))
      const updatedWorkInformation =commonInputData(WORKFORCE_INFORMATION,convertedData)
      let objWork ={}
        Object.entries(updatedWorkInformation).forEach(([key, value]) => {
          console.log(key,value,">>>>>>>>")
          if(key === "total_board_directors_number" || key === "workforce_board_ppl_of_color" || key === "workforce_board_women"){
            if(typeof value === "number"){
              objWork[key] = value;
            }
          }
          if(key === "diverse_suppliers_ca_number"){
            if(typeof value === "string"){
              objWork[key] = value;
            }
          }
        });
      setInputData((prevState) => ({
        ...prevState,
        workforceInformation: {
          ...prevState.workforceInformation,
          ...objWork, // Add all matched keys at once
        },
    }))


    customFields?.forEach((itm) => {
      console.log("ITMM",itm,output)
      if (itm.category === "Company Information"  && output[itm.fieldName]) {
        if(
          (itm.fieldType === "alphabet" && typeof output[itm.fieldName] === "string")
          || (itm.fieldType === "numeric" && typeof output[itm.fieldName] === "number")      
            || (itm.fieldType === "dropdown" && itm.options.includes(output[itm.fieldName]))
             || (itm.fieldType === "boolean" && (output[itm.fieldName] === "yes" || output[itm.fieldName] === "no"))
             ||   (itm.fieldType === "date" && typeof output[itm.fieldName] === "string")
          ){
        setInputData((prevState) => ({
          ...prevState,
          companyInformation: {
            ...prevState.companyInformation,
            [`cs-${itm.fieldName}`]: output[itm.fieldName], // Add matched keys
          },
        }));
      }
      }
      if (itm.category === "MBE Information"  && output[itm.fieldName]) {
        if((itm.fieldType === "alphabet" && typeof output[itm.fieldName] === "string")
          || (itm.fieldType === "numeric" && typeof output[itm.fieldName] === "number")      
            || (itm.fieldType === "dropdown" && itm.options.includes(output[itm.fieldName]))
            || (itm.fieldType === "boolean" && (output[itm.fieldName] === "yes" || output[itm.fieldName] === "no"))
            ||   (itm.fieldType === "date" && typeof output[itm.fieldName] === "string")
          ){
        setInputData((prevState) => ({
          ...prevState,
          mbeInformation: {
            ...prevState.mbeInformation,
            [`cs-${itm.fieldName}`]: output[itm.fieldName], // Add matched keys
          },
        }));
      }
      }
      if (itm.category === "Diverse Groups Information"  && output[itm.fieldName]) {
        if((itm.fieldType === "alphabet" && typeof output[itm.fieldName] === "string")
          || (itm.fieldType === "numeric" && typeof output[itm.fieldName] === "number")      
            || (itm.fieldType === "dropdown" && itm.options.includes(output[itm.fieldName]))
            || (itm.fieldType === "boolean" && (output[itm.fieldName] === "yes" || output[itm.fieldName] === "no"))
            ||   (itm.fieldType === "date" && typeof output[itm.fieldName] === "string")
          ){
        setInputData((prevState) => ({
          ...prevState,
          diverseInformation: {
            ...prevState.diverseInformation,
            [`cs-${itm.fieldName}`]: output[itm.fieldName], // Add matched keys
          },
        }));
      }
      }
      if (itm.category === "Direct/Subcontracting Information"  && output[itm.fieldName]) {
        if((itm.fieldType === "alphabet" && typeof output[itm.fieldName] === "string")
          || (itm.fieldType === "numeric" && typeof output[itm.fieldName] === "number")      
            || (itm.fieldType === "dropdown" && itm.options.includes(output[itm.fieldName]))
            || (itm.fieldType === "boolean" && (output[itm.fieldName] === "yes" || output[itm.fieldName] === "no"))
            ||   (itm.fieldType === "date" && typeof output[itm.fieldName] === "string")
          ){
        setInputData((prevState) => ({
          ...prevState,
          subcontractInformation: {
            ...prevState.subcontractInformation,
            [`cs-${itm.fieldName}`]: output[itm.fieldName], // Add matched keys
          },
        }));
      }
      }
      if (itm.category === "Revenue Reporting Information"  && output[itm.fieldName]) {
        if((itm.fieldType === "alphabet" && typeof output[itm.fieldName] === "string")
          || (itm.fieldType === "numeric" && typeof output[itm.fieldName] === "number")      
            || (itm.fieldType === "dropdown" && itm.options.includes(output[itm.fieldName]))
            || (itm.fieldType === "boolean" && (output[itm.fieldName] === "yes" || output[itm.fieldName] === "no"))
            ||   (itm.fieldType === "date" && typeof output[itm.fieldName] === "string")
          ){
        setInputData((prevState) => ({
          ...prevState,
          revenueInformation: {
            ...prevState.revenueInformation,
            [`cs-${itm.fieldName}`]: output[itm.fieldName], // Add matched keys
          },
        }));
      }
      }
      if (itm.category === "Workforce/Board Diversity Information"  && output[itm.fieldName]) {
        if((itm.fieldType === "alphabet" && typeof output[itm.fieldName] === "string")
          || (itm.fieldType === "numeric" && typeof output[itm.fieldName] === "number")      
            || (itm.fieldType === "dropdown" && itm.options.includes(output[itm.fieldName]))
            || (itm.fieldType === "boolean" && (output[itm.fieldName] === "yes" || output[itm.fieldName] === "no"))
            ||   (itm.fieldType === "date" && typeof output[itm.fieldName] === "string")
          ){
        setInputData((prevState) => ({
          ...prevState,
          workforceInformation: {
            ...prevState.workforceInformation,
            [`cs-${itm.fieldName}`]: output[itm.fieldName], // Add matched keys
          },
        }));
      }
      }
    });

    // console.log("TR",transformedData)

    // customFields?.forEach((itm) => {
    //   if (itm.category === "Company Information"  && obj[itm.fieldName]) {
    //     setInputData((prevState) => ({
    //       ...prevState,
    //       companyInformation: {
    //         ...prevState.companyInformation,
    //         [`cs-${itm.fieldName}`]: obj[itm.fieldName], // Add matched keys
    //       },
    //     }));
    //   }
    //   if (itm.category === "MBE Information"  && obj[itm.fieldName]) {
    //     setInputData((prevState) => ({
    //       ...prevState,
    //       mbeInformation: {
    //         ...prevState.mbeInformation,
    //         [`cs-${itm.fieldName}`]: obj[itm.fieldName], // Add matched keys
    //       },
    //     }));
    //   }
    //   if (itm.category === "Diverse Groups Information"  && obj[itm.fieldName]) {
    //     setInputData((prevState) => ({
    //       ...prevState,
    //       diverseInformation: {
    //         ...prevState.diverseInformation,
    //         [`cs-${itm.fieldName}`]: obj[itm.fieldName], // Add matched keys
    //       },
    //     }));
    //   }
    //   if (itm.category === "Direct/Subcontracting Information"  && obj[itm.fieldName]) {
    //     setInputData((prevState) => ({
    //       ...prevState,
    //       subcontractInformation: {
    //         ...prevState.subcontractInformation,
    //         [`cs-${itm.fieldName}`]: obj[itm.fieldName], // Add matched keys
    //       },
    //     }));
    //   }
    //   if (itm.category === "Revenue Reporting Information"  && obj[itm.fieldName]) {
    //     setInputData((prevState) => ({
    //       ...prevState,
    //       revenueInformation: {
    //         ...prevState.revenueInformation,
    //         [`cs-${itm.fieldName}`]: obj[itm.fieldName], // Add matched keys
    //       },
    //     }));
    //   }
    //   if (itm.category === "Workforce/Board Diversity Information"  && obj[itm.fieldName]) {
    //     setInputData((prevState) => ({
    //       ...prevState,
    //       workforceInformation: {
    //         ...prevState.workforceInformation,
    //         [`cs-${itm.fieldName}`]: obj[itm.fieldName], // Add matched keys
    //       },
    //     }));
    //   }
    // });
    
    
    
    }

    },[customFields])
    // console.log("DATA", data, error, isLoading, headList)

    return (
      <>
        <div className='headerAddData'>
          <div className='headerLeftData'>Add Data</div>
          <div className='headerRightData'>
            <div className='cncl' onClick={()=>navigate("/end/data")}>Cancel</div>
            {!location.state.isView && 
            <div className='savedraft' onClick={hanldeDraft}>
            {loading1  ? 

              <div style={{width:"80px",display:"flex",justifyContent:"center",alignItems:"center",height:"22px"}}>
              <CircularProgress size={14} sx={{ color: "#333333" }} />
              </div> : 
              "Save as Draft"
            }
            </div>
            }

            {!loading  ? 
             !location.state.isView && <div className='sbmt' onClick={handleSubmit}>{location?.state?.data2 ? "Update" : "Submit"}</div>:
            <div className='headerButtonData'>
                <CircularProgress size={14} sx={{ color: "#ffffff" }} />
              </div>
            }
          </div>
        </div>
        <div className='containerHead'>
          <div style={{ width: "100%" }}>
            <div className={`inpContSection`} id="modal_body">
              {headList?.map((itm, index) => {
                return (
                  <div style={{ marginTop: index !== 0 ? "32px" : "0px" }}>
                    <div className={`inpCommonHeader ${itm.isExpanded ? "" : "none"}`} onClick={() => handleExpand(index)}>
                      <div className='inptCommLeft'>{itm.label}</div>
                      <div className='inptCommRight'>
                        <div className='inpTxtComm'>{itm.isExpanded ? "Collapse" : "Expand"}</div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                          {itm.isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </div>
                      </div>
                    </div>
                    {itm.isExpanded &&
                      <CommonField
                        rows={itm.data}
                        handleAddClick={handleAddClick}
                        handleAdd={handleAdd}
                        hanldeInputAddition={() => handleAddInput(itm, index)}
                        handleConfirmAction={deleteHandle}
                        modal={modal}
                        isDisable={location.state.isView || false}
                        setModalData={setModal}
                        handleOpen={modalHandle}
                        contClick={editHandle}
                        inputData={inputData}
                        setInputData={setInputData}
                        handleInputChange={handleInputChange}

                      />
                    }
                  </div>
                )
              })
              }
            </div>
          </div>
          {isContentClicked &&
            <div style={{ width: "35%" }}>
              <AddInput
                isContent={isContentClicked}
                setIsContent={setIsContentClicked}
                onAdd={handleValue}
                onEdit={handleEditValue}
                isEdit={isEdit}
                row={rowData}
              />
            </div>
          }
        </div>
      </>
    )
  }

  export default InputTemplate