import React, { useCallback, useEffect, useState } from "react";
import "./_index.scss";
import GroupCard from "./GroupCard/GroupCard";
import Header from "../../../Components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useFetchQuery } from "../../../features/Group/groupSlice";
import { Skeleton } from "@mui/material";
import Pagination from "../../../Components/Pagination";

const val = [
  {
    group_id: "#11542",
    group_name: "CPUC Group",
    group_status: "Active",
    group_num: 20,
    group_icon: "/assets/images/group.png",
  },
  {
    group_id: "#11542",
    group_name: "GROUP 02",
    group_status: "In-Active",
    group_num: 20,
    group_icon: "/assets/images/group.png",
  },
  {
    group_id: "#11542",
    group_name: "Group 03",
    group_status: "Active",
    group_num: 20,
    group_icon: "/assets/images/group.png",
  },
];

// const val = []
const GroupManagement = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const [currentPage,setCurrentPage] = useState(1)
  const [searchValue, setSearchValue] = useState("");
  const filter = {  search: searchValue,page : currentPage,limit:10 };
  const { data = {data:[]}, error, isLoading,refetch } = useFetchQuery(filter);
  const emptyArray = Array(6).fill(undefined);
  console.log("data",data)

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  const handleSearchChange = (value) => {
    
    setSearchValue(value);
    console.log("Search Value:", value);
  };

  const onPageChange = (val)=>{
    console.log(val)
    setCurrentPage(val)
  }

  const refreshData = useCallback(() => {
    refetch()
    //  setSearchValue("Ab")
  }, []);

  const handleAction1Click = () => {
    navigate("/super/group/add-group");
    console.log("Action 1 Clicked");
  };
  return (
    <div className='grp_container'>
      <Header
        title='Group Management'
        description='Here,you can manage the groups'
        searchValue={searchValue}
        isSearchDisable={data?.data?.length < 1 && !searchValue}
        btnText='Add Group'
        searchText='Search Group'
        isIcon={true}
        // isFilter={true}
        onSearchChange={handleSearchChange}
        onAction1Click={handleAction1Click}
      />
      <div className={`grp-Content`} id="modal_body">
        {!isLoading && data?.data?.length > 0 ? (
          data?.data?.map((el, index) => {
            return <GroupCard el={el} refreshData={refreshData}  />;
          })
        ) :
        (isLoading ? 
        <div className="grp-Content-skel">
        {
          emptyArray?.map((itm,ix)=>{
            return(<Skeleton variant="rectangular" width={"15.521vw"} height={"9.375vw"} sx={{ borderRadius: '8px' }}/>)
          })
        }
        </div> 
        : 
        (
          <div className="no-grp-fd"
          >No Group Found</div>
        )
        )
        }
      </div>
     {data?.total> 0 &&  
      <div className="footerPage">
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(data?.total/10)}
        onPageChange={onPageChange}
      />
      </div>
     }
    </div>
  );
};

export default GroupManagement;
