import React, { useState } from "react";
import "./index.scss";
// import { MoreVertIcon, ToggleOffIcon } from "../../utils/icons/index";
import { Menu, MenuItem } from "@mui/material";
import CommonModal from "../../../../Components/Modals/index";
import { DeleteOutlineOutlinedIcon, MoreVertIcon, ToggleOffIcon, VisibilityIcon } from "../../../../utils/icons";
import { useDeletereportMutation } from "../../../../features/Input/inputSlice";
import { useToast } from "../../../ToastProvider";
import { useNavigate } from "react-router-dom";

const DataCard = ({itm={},refreshData =()=>{}}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { showSuccess, showError } = useToast();
  const [modal, setModal] = useState(false);
  const navigate = useNavigate()
  const [deletereport, { isLoading, isSuccess, isError }] = useDeletereportMutation();
//   const [dataDrop,setDataDrop] = useState([
//     {
//         id: 1,
//         name: "Edit Data",
//         icon: "/assets/images/edit_group.svg",
//       },
//       {
//         id: 2,
//         name: "View Data",
//         icon: <VisibilityIcon/>,
//       },
//   ])
  const open = Boolean(anchorEl);

  console.log(itm,"ITT")

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  let dropdownForReport = []
  if(itm?.status === "Active"){
    dropdownForReport = [
        {
          id: 1,
          name: "Edit Data",
          icon: "/assets/images/edit_group.svg",
        },
        {
          id: 2,
          name: "View Data",
          icon: <ToggleOffIcon />,
        },
        {
            id:3,
            name:"Delete Data",
            icon: <DeleteOutlineOutlinedIcon style={{height:"25px",width:"25px"}}/>,
        }
      ];
  }
  else if(itm?.status === "Draft"){
    dropdownForReport = [
        {
          id: 1,
          name: "Edit Data",
          icon: "/assets/images/edit_group.svg",
        },
        {
          id: 2,
          name: "View Data",
          icon: <ToggleOffIcon />,
        },
        {
            id:3,
            name:"Delete Data",
            icon: <DeleteOutlineOutlinedIcon style={{height:"25px",width:"25px"}}/>,
        }
      ];
  }
  else{
    dropdownForReport = [
        {
          id: 2,
          name: "View Data",
          icon: <ToggleOffIcon/>,
        },
        {
          id: 3,
          name: "Delete Data",
          icon: <DeleteOutlineOutlinedIcon style={{height:"25px",width:"25px"}}/>,
        },
      ];
  }

  const handleReportList = (item)=>{
     if(item.id === 3){
        setModal(true)
     }
     if(item.id === 1){
        navigate("/end/data/add-data", { state: { data2: itm } });
     }  
     if(item.id === 2){
        navigate("/end/data/add-data", { state: { data2: itm, isView: true } });
     }  

  }

  const handleConfirmAction = async()=>{
    try{
        const result = await deletereport(itm.id).unwrap();
        console.log("RESSS",result)
        showSuccess({
          body:result?.message || "Data deleted successfully"
        })
        refreshData()
        setModal(false);
    }catch(err){

    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div className='cardLid'>
        {/* <div className='inner_card'> */}
        <div className='frame_one'>
          <div className='img_icon'>
            <img src='/assets/images/fileiconlite.svg' alt='Vector.png' />
          </div>
          <div className={itm?.status === "Active" ? "active_bar" : (itm?.status === "Draft" ? "draft_bar" : "locked_bar" )}>
            <button>{itm?.status}</button>
          </div>
        </div>
        <div className='frame_two'>
          <div className='date_text'>
          {/* <div></div> */}
            {/* <div className='company'>20 Company</div> */}
            <div className='year'>{itm?.year}</div>
          </div>
          <div className='dropdown_wrapper' onClick={(e) => e.stopPropagation()}>
            <MoreVertIcon
              style={{ color: "#4D4D4D", cursor: "pointer" }}
              onClick={(event) => {
                event.stopPropagation();
                handleClick(event);
              }}
            />
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              autoFocus={false}
              sx={{
                "& .MuiPaper-root": {
                  minWidth: "11.042vw",
                  borderRadius: "0.417vw",
                  border: "1px solid #CCCCCC",
                  marginTop: "10px",
                  marginLeft: "-29px",
                },
                "& .MuiList-root": {
                  padding: "0px",
                },
              }}>
              {dropdownForReport.map((item, index) => (
                <MenuItem
                  key={index}
                  style={{
                    border: "1px solid #E6E6E6",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    cursor: "pointer",
                  }}
                  onClick={()=>handleReportList(item)}
                  sx={{
                    padding: "16px",
                  }}>
                  {item.id === 1 ? <img src={item.icon} alt={`${item.name} Icon`} /> : item.icon}
                  {item.name}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
        
                {modal && (
                  <CommonModal
                    setIsOpenPopUp={setModal}
                    open={modal}
                    isLoading={isLoading}
                    handleDelete={handleConfirmAction}
                    item={{
                      title:"Delete Report",
                      description:"Are you sure you want to delete this Report?",
                      icon:true
                    }}
                  />
                )}
        {/* </div> */}
      </div>
    </div>
  );
};

export default DataCard;