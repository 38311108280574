import React from 'react';
import { useToast } from '../../ToastProvider';

const Dashboard = () => {
  const { showSuccess, showError } = useToast();

  const handleToast =()=>{
    showError({
      title: 'Success!',
      body: 'Error',
    });
  }
  return (
   <div>
      <h3>Dashboard</h3>
      {/* <button onClick={handleToast}>Click me</button> */}
   </div>
  )
};

export default Dashboard;
