import React, { useState } from "react";
import "./GroupCard.scss";
import { DeleteOutlineOutlinedIcon, MoreVertIcon, ToggleOffIcon, ToggleOnIcon } from "../../../../utils/icons";
import { Menu, MenuItem } from "@mui/material";
import { useNavigate,createSearchParams } from "react-router-dom";
import CommonModal from "../../../../Components/Modals/index";
import { useDeleteGroupMutation, useUpdateMutation } from "../../../../features/Group/groupSlice";
import { BASE_DOMAIN } from "../../../../utils/constants";
import { useToast } from "../../../ToastProvider";



const GroupCard = (props) => {
  const { el } = props;
  const navigate = useNavigate();
    const { showSuccess, showError } = useToast();
  const [update, { isLoading, isError, error }] = useUpdateMutation();
    const [deleteGroup, { isLoading: delLoading, isSuccess, error: anyError }] = useDeleteGroupMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [modal, setModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);


  console.log("EL",el)
  const dropdownForGroups = [
    {
      id:1,
      name: "Edit Group",
      icon: "/assets/images/edit_group.svg",
    },
    {
      id:2,
      name: el.isActive ?  "Make Group Inactive" : "Make Group Active",
      icon: <ToggleOffIcon style={{color: el.isActive ?  "#0A8803" : "#333333" , height:"25px",width:"25px"}}/>,
    },
    {
      id:3,
      name: "Delete Group",
      icon: <DeleteOutlineOutlinedIcon style={{height:"25px",width:"25px"}}/>,
    }
  ];

  if (!el) {
    console.error("Group data is undefined");
    return null;
  }

  const handleNavigate = () => {
    navigate({
      pathname:'/super/group/list',
      search: `?${createSearchParams({ id: el?.id,grp:el?.groupId,grpname:el?.name })}`
    }) 
    // navigate("/super/group/list");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGroupDropdowns = (item) => {
    if (item.id === 2) {
      setSelectedAction(item);
      setModal(true);
      // console.log("Action for Delete Group triggered");
    }
    else if(item.id === 3){
      setSelectedAction(item);
      setModal(true);
    }
    else{
     navigate({
        pathname:'/super/group/add-group',
        search: `?${createSearchParams({ id: el?.id })}`
      }) 
    }
    handleClose();
  };

  const handleConfirmAction = async() => {
    try{
      if(selectedAction.id === 3){
        const result = await deleteGroup(el.id).unwrap();
        showSuccess({
          body:"Status Updated"
        })
        props.refreshData()
      }else{
        const url = `${BASE_DOMAIN}groups/${el.id}/toggle-status`
        const response = await update({url}).unwrap()
        showSuccess({
          body:"Status Updated"
        })
        props.refreshData()
      }

      
    }
    catch(err){
       showError({
        body: err?.data?.message || err?.status || "something went wrong"
       })
    }
    // if (selectedAction?.name === "Delete Group") {
    //   console.log("Group Deleted!");
    // }
    // else if (selectedAction?.name === "Make Group Inactive") {
    //   console.log("Group Marked as Inactive!");
    // }
    setModal(false);
  };

  return (
    <div className='card_container' onClick={handleNavigate}>
      <div>
        <div className='logo_and_status_container'>
          <div className='grp_logo_container'>
            <img src={el?.logo} className='image_value' alt='Group Icon' />
          </div>
          <div className='grp_status_container'>
            {/* <p className={`${el.group_status === "Active" ? "tableStatus" : "tableStatusInactive"}`}>{el.group_status}</p> */}
            <p className={`${el?.isActive  ? "tableStatus" : "tableStatusInactive"}`}>{el?.isActive ? "Active" : "Inactive"}</p>
          </div>
        </div>

        <div className='grp_name_id'>
          <p className='grp_id'>{`#${el?.groupId}`}</p>
          <p className='grp_name'>{el?.name}</p>
        </div>
      </div>

      <div className='company_count_dropdowns'>
        <div className='no_of_company'>{`${el?.numberOfCompanies} ${el?.numberOfCompanies > 1 ? 'Companies' : 'Company'}`}</div>
        <div className='dropdown_wrapper' onClick={(e) => e.stopPropagation()}>
          <MoreVertIcon
            style={{ color: "#4D4D4D", cursor: "pointer" }}
            onClick={(event) => {
              event.stopPropagation();
              handleClick(event);
            }}
          />
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            autoFocus={false}
            sx={{
              "& .MuiPaper-root": {
                minWidth: "11.042vw",
                borderRadius: "0.417vw",
                border: "1px solid #CCCCCC",
                marginTop: "10px",
                marginLeft: "-29px",
              },
              "& .MuiList-root": {
                padding: "0px",
              },
            }}>
            {dropdownForGroups.map((item, index) => (
              <MenuItem
                key={index}
                style={{
                  border: "1px solid #E6E6E6",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  cursor: "pointer",
                }}
                sx={{
                  padding: "16px",
                }}
                onClick={() => handleGroupDropdowns(item)}>
                {item.id === 1 ?
                <img src={item.icon} alt={`${item.name} Icon` } /> : 
                item.icon
                }
                {item.name}
              </MenuItem>
            ))}
          </Menu>
        </div>

        {modal && (
          <CommonModal
            setIsOpenPopUp={setModal}
            open={modal}
            isLoading={isLoading || delLoading}
            handleDelete={handleConfirmAction}
            item={{
              title: selectedAction.id === 3 ? "Delete group" :  "Group Status",
              description:selectedAction.id === 3 ? "Are you sure you want to delete this group?" :  el?.isActive ? "Are you sure you want to make this group inactive? You can reactivate it later if needed." : "Are you sure you want to make this group active?",
              icon:selectedAction.id === 3 ? true : false
            }}
          />
        )}
      </div>
    </div>
  );
};

export default GroupCard;
