import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_DOMAIN } from '../../utils/constants';
import { report_backup, report_company, report_generate, report_status } from './reportConstant';


export const reportSlice = createApi({
    reducerPath: 'reportApi', // Unique name for the slice
    baseQuery: fetchBaseQuery({ baseUrl: BASE_DOMAIN }),
    //   baseQuery: fetchBaseQuery({ baseUrl: BASE_DOMAIN }), // Replace with your API base URL
    endpoints: (builder) => ({
        reportcompany: builder.query({
            query: (id) => {
                return {
                    url: `${report_company}/${id}`, // Append query params to the endpoint
                    method: 'GET',
                };
            },
        }),
        updateStatus: builder.mutation({
            query: ({ id, payload }) => ({
                url: `${report_status}/${id}`, // Replace with your login endpoint
                method: 'PUT',
                body: payload,
            }),
        }),
        generateReport: builder.mutation({
            query: ( {year, reportType, groupId} ) => ({
              url: `${report_generate}?year=${year}&reportType=${reportType}&groupId=${groupId}`, // Build the endpoint URL
              method: 'GET', // Use GET for this API
            }),
          }),
          
        reportbackup: builder.mutation({
            query: ( {id} ) => ({
              url: `${report_backup}/${id}`, // Build the endpoint URL
              method: 'GET', // Use GET for this API
            }),
          }),
    })

})

export const { useReportcompanyQuery, useUpdateStatusMutation,useGenerateReportMutation,useReportbackupMutation } = reportSlice