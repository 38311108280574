import React, { useState } from 'react'
import "./filter.scss"
import { Checkbox } from '@mui/material'
import { CATEGORY, UTILITY, YEAR_LIST } from '../../utils/constants'

const Filter = ({
  onClick= ()=>{},
  onClose = ()=>{},
  onReset = ()=>{},
  isYear = false
}) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [selectedUtilities, setSelectedUtilities] = useState([]);

  // Handler for category checkbox change
  const handleCategoryChange = (item) => {
    setSelectedCategories((prev) =>
      prev.includes(item)
        ? prev.filter((i) => i !== item) // Uncheck: Remove the item
        : [...prev, item] // Check: Add the item
    );
  };
  const handleYearChange = (item) => {
    setYearList((prev) =>
      prev.includes(item)
        ? prev.filter((i) => i !== item) // Uncheck: Remove the item
        : [...prev, item] // Check: Add the item
    );
  };

  // Handler for utility checkbox change
  const handleUtilityChange = (item) => {
    setSelectedUtilities((prev) =>
      prev.includes(item)
        ? prev.filter((i) => i !== item) // Uncheck: Remove the item
        : [...prev, item] // Check: Add the item
    );
  };
  
  return (
    <div className='filterContainerSection'>
      <div className='filterHeaderSection'>
        Filter Option
      </div>
      {!isYear ?
      <div className='contentSection' id="modal_body">
          <div className='contentInside'>
             <div className='contentTitle'>Category</div>
             {CATEGORY.map((itm,ix)=>{
              return(
                <div key={ix} className='contentTextSection'>
                 <div>
                  <input 
                   type='checkbox' 
                   className='contentCheck'
                   checked={selectedCategories.includes(itm)}
                   onChange={() => handleCategoryChange(itm)}
                   />
                 </div>
                 <div className='contentCheckLabel'>{itm}</div>
             </div>
              )
             })
             }
          </div>
          <div className='contentInside'>
          <div className='contentTitle'>Utility</div>
          {UTILITY.map((itm,ix)=>{
              return(
                <div key={ix} className='contentTextSection'>
                 <div>
                  <input 
                  type='checkbox'
                  className='contentCheck'
                  checked={selectedUtilities.includes(itm)}
                  onChange={() => handleUtilityChange(itm)}

                  />
                 </div>
                 <div className='contentCheckLabel'>{itm}</div>
             </div>
              )
             })
             }
          </div>
      </div> : 
      <div className='contentSection' id="modal_body">
          <div className='contentInside'>
             <div className='contentTitle'>Year</div>
             {YEAR_LIST.map((itm,ix)=>{
              return(
                <div key={ix} className='contentTextSection'>
                 <div>
                  <input 
                   type='checkbox' 
                   className='contentCheck'
                   checked={yearList.includes(itm)}
                   onChange={() => handleYearChange(itm)}
                   />
                 </div>
                 <div className='contentCheckLabel'>{itm}</div>
             </div>
              )
             })
             }
          </div>
      </div>
      }
      {!isYear ?
      <div className='filterFooter'>
         <div></div>
         <div className='filterFlex'>
           <div className='resetSection'
           onClick={() => {
              setSelectedCategories([]); // Clear all categories
              setSelectedUtilities([]);
              onReset()
              // onClose() // Clear all utilities
            }}
           >Reset</div>
           <div className='applySection'  onClick={() => {
              onClick(selectedCategories, selectedUtilities);
              // (selectedCategories.length > 0 || selectedUtilities.length > 0) && onClose()
               // Pass the selected values
            }}>Apply</div>
         </div>
      </div> : 
      <div className='filterFooter'>
         <div></div>
         <div className='filterFlex'>
           <div className='resetSection'
           onClick={() => {
              setYearList([])
              onReset()
              // onClose() // Clear all utilities
            }}
           >Reset</div>
           <div className='applySection'  onClick={() => {
              onClick(yearList);
              // (selectedCategories.length > 0 || selectedUtilities.length > 0) && onClose()
               // Pass the selected values
            }}>Apply</div>
         </div>
      </div>
      }
    </div>
  )
}

export default Filter